import {
    GET_DAFTAR_PIUTANG,
    GET_DAFTAR_PIUTANG_SUCCESS,
    GET_DAFTAR_PIUTANG_FAIL,
    GET_PIUTANG,
    GET_PIUTANG_SUCCESS,
    GET_PIUTANG_FAIL,
    ADD_PIUTANG,
    ADD_PIUTANG_FAIL,
    UPDATE_PIUTANG,
    UPDATE_PIUTANG_FAIL,
    DELETE_PIUTANG,
    DELETE_PIUTANG_FAIL,
    ADD_PIUTANG_PAYMENT,
    ADD_PIUTANG_PAYMENT_SUCCESS,
    ADD_PIUTANG_PAYMENT_FAIL,
    DELETE_PIUTANG_PAYMENT_SUCCESS,
    DELETE_PIUTANG_PAYMENT,
    DELETE_PIUTANG_PAYMENT_FAIL,
    ADD_PIUTANG_SUCCESS
  } from "./actionTypes";
  
  export const getDaftarPiutang = filter => ({
    type: GET_DAFTAR_PIUTANG,
    payload: filter
  });
  export const getDaftarPiutangSuccess = daftarPiutang => ({
    type: GET_DAFTAR_PIUTANG_SUCCESS,
    payload: daftarPiutang,
  });
  export const getDaftarPiutangFail = error => ({
    type: GET_DAFTAR_PIUTANG_FAIL,
    payload: error,
  });

  export const getPiutangById = (id, companyId) => ({
    type: GET_PIUTANG,
    payload: { id, companyId }
  });
  export const getPiutangSuccess = piutang => ({
    type: GET_PIUTANG_SUCCESS,
    payload: piutang,
  });
  export const getPiutangFail = error => ({
    type: GET_PIUTANG_FAIL,
    payload: error,
  });
  
  export const addPiutang = (piutang, history) => ({
    type: ADD_PIUTANG,
    payload: { piutang, history },
  });
  export const addPiutangSuccess = () => ({
    type: ADD_PIUTANG_SUCCESS,
  });
  export const addPiutangFail = error => ({
    type: ADD_PIUTANG_FAIL,
    payload: error,
  });
  
  export const updatePiutang = (piutang, history) => ({
    type: UPDATE_PIUTANG,
    payload: { piutang, history },
  });
  export const updatePiutangFail = error => ({
    type: UPDATE_PIUTANG_FAIL,
    payload: error,
  });
  
  export const deletePiutang = (piutang, filter) => ({
    type: DELETE_PIUTANG,
    payload: { piutang, filter },
  });
  export const deletePiutangFail = error => ({
    type: DELETE_PIUTANG_FAIL,
    payload: error,
  });

  export const addPiutangPayment = (piutangPayment, history) => ({
    type: ADD_PIUTANG_PAYMENT,
    payload: { piutangPayment, history },
  });
  export const addPiutangPaymentSuccess = () => ({
    type: ADD_PIUTANG_PAYMENT_SUCCESS,
  });
  export const addPiutangPaymentFail = error => ({
    type: ADD_PIUTANG_PAYMENT_FAIL,
    payload: error,
  });

  export const deletePiutangPayment = (param) => ({
    type: DELETE_PIUTANG_PAYMENT,
    payload: { param },
  });
  export const deletePiutangPaymentSuccess = () => ({
    type: DELETE_PIUTANG_PAYMENT_SUCCESS,
  });
  export const deletePiutangPaymentFail = error => ({
    type: DELETE_PIUTANG_PAYMENT_FAIL,
    payload: error,
  });