export const GET_DAFTAR_PIUTANG = "GET_DAFTAR_PIUTANG";
export const GET_DAFTAR_PIUTANG_SUCCESS = "GET_DAFTAR_PIUTANG_SUCCESS";
export const GET_DAFTAR_PIUTANG_FAIL = "GET_DAFTAR_PIUTANG_FAIL";

export const GET_PIUTANG = "GET_PIUTANG";
export const GET_PIUTANG_SUCCESS = "GET_PIUTANG_SUCCESS";
export const GET_PIUTANG_FAIL = "GET_PIUTANG_FAIL";

export const ADD_PIUTANG = "ADD_PIUTANG";
export const ADD_PIUTANG_SUCCESS = "ADD_PIUTANG_SUCCESS";
export const ADD_PIUTANG_FAIL = "ADD_PIUTANG_FAIL";

export const UPDATE_PIUTANG = "UPDATE_PIUTANG";
export const UPDATE_PIUTANG_FAIL = "UPDATE_PIUTANG_FAIL";

export const DELETE_PIUTANG = "DELETE_PIUTANG";
export const DELETE_PIUTANG_FAIL = "DELETE_PIUTANG_FAIL";

export const ADD_PIUTANG_PAYMENT = "ADD_PIUTANG_PAYMENT";
export const ADD_PIUTANG_PAYMENT_SUCCESS = "ADD_PIUTANG_PAYMENT_SUCCESS";
export const ADD_PIUTANG_PAYMENT_FAIL = "ADD_PIUTANG_PAYMENT_FAIL";

export const DELETE_PIUTANG_PAYMENT = "DELETE_PIUTANG_PAYMENT";
export const DELETE_PIUTANG_PAYMENT_SUCCESS = "DELETE_PIUTANG_PAYMENT_SUCCESS";
export const DELETE_PIUTANG_PAYMENT_FAIL = "DELETE_PIUTANG_PAYMENT_FAIL";