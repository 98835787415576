import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_LIST_USER,
    GET_USER,
    ADD_USER,
    UPDATE_USER,
    DELETE_USER,
    RESET_PASSWORD_USER,
    CHANGE_PASSWORD_USER
} from "./actionTypes"
import {
    getListUserSuccess,
    getListUserFail,
    getUserSuccess,
    getUserFail,
    addUserSuccess,
    addUserFail,
    updateUserSuccess,
    updateUserFail,
    deleteUserSuccess,
    deleteUserFail,
    resetPasswordUserSuccess,
    resetPasswordUserFail,
    changePasswordUserSuccess,
    changePasswordUserFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getListUser,
    getUser,
    addUser,
    updateUser,
    deleteUser,
    resetPasswordUser,
    changePasswordUser
} from "../../helpers/backend_helper"

function* fetchListUser({ payload: filter }) {
    try {
        const response = yield call(getListUser, filter)
        if (response.status) {
            yield put(getListUserSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getListUserFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getListUserFail(error))
    }
}

function* onGetUser({ payload: { id } }) {
    try {
        const response = yield call(getUser, { id })
        if (response.status) {
            yield put(getUserSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getUserFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getUserFail(error))
    }
}

function* onAddUser({ payload: { user, history } }) {
    try {
        const response = yield call(addUser, user)
        if (response.status) {
            NotifHelper.success(msg.USER_ADD_SUCCESS)
            yield put(addUserSuccess())
            history.push("/usermanagement/user")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addUserFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addUserFail(error))
    }
}

function* onUpdateUser({ payload: { user, history } }) {
    try {
        const response = yield call(updateUser, user)
        if (response.status) {
            NotifHelper.success(msg.USER_UPDATE_SUCCESS)
            yield put(updateUserSuccess())
            history.push("/usermanagement/user")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updateUserFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updateUserFail(error))
    }
}

function* onDeleteUser({ payload: { user, filter } }) {
    try {
        const response = yield call(deleteUser, user)
        if (response.status) {
            yield put(deleteUserSuccess())
            NotifHelper.success(msg.USER_DELETE_SUCCESS)
            const responseNewData = yield call(getListUser, filter)
            if (responseNewData.status) {
                yield put(getListUserSuccess(responseNewData.data))
            } 
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getListUserFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deleteUserFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deleteUserFail(error))
    }
}

function* onResetPasswordUser({ payload: { param } }) {
    try {
        const response = yield call(resetPasswordUser, param)
        if (response.status) {
            yield put(resetPasswordUserSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(resetPasswordUserFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(resetPasswordUserFail(error))
    }
}

function* onChangePasswordUser({ payload: { param } }) {
    try {
        const response = yield call(changePasswordUser, param)
        if (response.status) {
            NotifHelper.success(msg.CHANGE_PASSWORD_USER_SUCCESS)
            yield put(changePasswordUserSuccess())
        }
        else {
            NotifHelper.error(response.message)
            yield put(changePasswordUserFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(changePasswordUserFail(error))
    }
}

function* userSaga() {
    yield takeEvery(GET_LIST_USER, fetchListUser)
    yield takeEvery(GET_USER, onGetUser)
    yield takeEvery(ADD_USER, onAddUser)
    yield takeEvery(UPDATE_USER, onUpdateUser)
    yield takeEvery(DELETE_USER, onDeleteUser)
    yield takeEvery(RESET_PASSWORD_USER, onResetPasswordUser)
    yield takeEvery(CHANGE_PASSWORD_USER, onChangePasswordUser)
}

export default userSaga