import { call, put, takeEvery } from "redux-saga/effects";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, GET_ACCESS } from "./actionTypes";
import { apiError, loginSuccess, getAccessSuccess } from "./actions";

import {
  postLogin, getAccessData
} from "../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      username: user.username,
      password: user.password,
    });
    if (response.status) {
      localStorage.setItem("Name", response.data.name);
      localStorage.setItem("AccessToken", response.data.token);
      yield put(loginSuccess(response.data.access));
      history.push("/dashboard");
    }
    else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getAccess() {
  try {
    const response = yield call(getAccessData, {});
    if (response.status) {
      yield put(getAccessSuccess(response.access));
    }
    else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("AccessToken");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_ACCESS, getAccess);
}

export default authSaga;
