import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"


// Dashboard
import Dashboard from "../pages/Dashboard/index"


import Company from "../pages/MasterData-Company"
import CompanyCreateUpdate from "../pages/MasterData-Company/masterCompanyCreateUpdate"

import Period from "../pages/MasterData-Period"
import PeriodCreateUpdate from "../pages/MasterData-Period/masterPeriodCreateUpdate"

import Account from "../pages/MasterData-Account"
import AccountCreateUpdate from "../pages/MasterData-Account/masterAccountCreateUpdate"

import AccountNumber from "../pages/MasterData-Account-Number"
import AccountNumberCreateUpdate from "../pages/MasterData-Account-Number/masterAccountNumberCreateUpdate"

import AssetCategory from "../pages/MasterData-Asset-Category"
import AssetCategoryCreateUpdate from "../pages/MasterData-Asset-Category/masterAssetCategoryCreateUpdate"

import AssetType from "../pages/MasterData-Asset-Type"
import AssetTypeCreateUpdate from "../pages/MasterData-Asset-Type/masterAssetTypeCreateUpdate"

import Asset from "../pages/Asset"
import AssetCreateUpdate from "../pages/Asset/assetCreateUpdate"
import AssetImportExcel from "../pages/Asset/assetImportExcel"

import Piutang from "../pages/Piutang"
import PiutangCreateUpdate from "../pages/Piutang/piutangCreateUpdate"
import PiutangPayment from "../pages/Piutang/piutangPayment"

import AccountTransaction from "../pages/AccountTransaction"
import AccountTransactionCreate from "../pages/AccountTransaction/accountTransactionCreate"
import AccountTransactionCreateExcel from "../pages/AccountTransaction/accountTransactionCreateExcel"

import Worksheet from "../pages/Worksheet"

import ReportNeraca from "../pages/ReportNeraca"
import ReportProfitLoss from "../pages/ReportProfitLoss"

import Role from "../pages/Role"
import RoleCreateUpdate from "../pages/Role/roleCreateUpdate"

import User from "../pages/User"
import UserCreateUpdate from "../pages/User/userCreateUpdate"

import ChangePassword from "pages/User/changePassword"

import ImportBeginningBalance from "pages/ImportBeginningBalance"


const authProtectedRoutes = [
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },

  { path: "/masterdata/company", component: Company },
  { path: "/masterdata/company-create-update", component: CompanyCreateUpdate },
  { path: "/masterdata/company-create-update/:id", component: CompanyCreateUpdate },

  { path: "/masterdata/period", component: Period },
  { path: "/masterdata/period-create-update", component: PeriodCreateUpdate },
  { path: "/masterdata/period-create-update/:companyId/:id", component: PeriodCreateUpdate },

  { path: "/masterdata/account", component: Account },
  { path: "/masterdata/account-create-update", component: AccountCreateUpdate },
  { path: "/masterdata/account-create-update/:companyId/:id", component: AccountCreateUpdate },

  { path: "/masterdata/account-number", component: AccountNumber },
  { path: "/masterdata/account-number-create-update", component: AccountNumberCreateUpdate },
  { path: "/masterdata/account-number-create-update/:companyId/:id", component: AccountNumberCreateUpdate },

  { path: "/masterdata/asset-category", component: AssetCategory },
  { path: "/masterdata/asset-category-create-update", component: AssetCategoryCreateUpdate },
  { path: "/masterdata/asset-category-create-update/:companyId/:id", component: AssetCategoryCreateUpdate },

  { path: "/masterdata/asset-type", component: AssetType },
  { path: "/masterdata/asset-type-create-update", component: AssetTypeCreateUpdate },
  { path: "/masterdata/asset-type-create-update/:companyId/:id", component: AssetTypeCreateUpdate },
  
  { path: "/asset", component: Asset },
  { path: "/asset-create-update", component: AssetCreateUpdate },
  { path: "/asset-create-update/:companyId/:id", component: AssetCreateUpdate },
  { path: "/asset-import-excel", component: AssetImportExcel },

  { path: "/piutang", component: Piutang },
  { path: "/piutang-create-update", component: PiutangCreateUpdate },
  { path: "/piutang-create-update/:companyId/:id", component: PiutangCreateUpdate },
  { path: "/piutang/payment/:companyId/:id", component: PiutangPayment },

  { path: "/account-transaction", component: AccountTransaction },
  { path: "/account-transaction/create", component: AccountTransactionCreate },
  { path: "/account-transaction/create/:id/:companyId/:periodId/:accountId/:accountNumberId", component: AccountTransactionCreate },
  { path: "/account-transaction/create-by-excel", component: AccountTransactionCreateExcel },

  { path: "/worksheet", component: Worksheet },
  
  { path: "/report-neraca", component: ReportNeraca },
  { path: "/report-profitloss", component: ReportProfitLoss },
  
  { path: "/usermanagement/role", component: Role },
  { path: "/usermanagement/role-create-update", component: RoleCreateUpdate },
  { path: "/usermanagement/role-create-update/:id", component: RoleCreateUpdate },

  { path: "/usermanagement/user", component: User },
  { path: "/usermanagement/user-create-update", component: UserCreateUpdate },
  { path: "/usermanagement/user-create-update/:id", component: UserCreateUpdate },
  
  { path: "/auth/change-password", component: ChangePassword },

  { path: "/import-beginning-balance", component: ImportBeginningBalance },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
