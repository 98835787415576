import React, { useState, useMemo, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Label,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TableContainer from '../../components/Common/TableContainer';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getDaftarPiutang as onGetDaftarPiutang,
    getCompanies as onGetCompanies,
    deletePiutang as onDeletePiutang,
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import DeleteModal from "../../components/Common/ModalDelete";
import { upperCase } from "lodash";
import CurrencyFormat from 'react-currency-format';

const Piutang = () => {
    //meta title
    document.title = "Piutang | Des Counts";
    const dispatch = useDispatch();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [piutang, setPiutang] = useState(false);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }

    const filterCompany = useRef("");

    const { companies, loadingCompany } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
    }));

    const { daftarPiutang, loading } = useSelector(state => ({
        daftarPiutang: state.Piutang.daftarPiutang,
        loading: state.Piutang.loading,
    }));

    const columns = useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'dateLong',
                disableFilters: true,
                textAlign: 'text-center',
                headerAlign: 'text-center',
                headerCustomStyle: ' width: 100px',
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Type',
                accessor: 'type',
                disableFilters: true,
                textAlign: 'text-center',
                headerAlign: 'text-center',
                Cell: (cellProps) => {
                    return upperCase(cellProps.value);
                }
            },
            {
                Header: 'Remarks',
                accessor: 'remarks',
                textAlign: 'text-left',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Nominal',
                accessor: 'nominal',
                disableFilters: true,
                textAlign: 'text-end',
                headerAlign: 'text-center',
                Cell: (cellProps) => {
                    return <CurrencyFormat value={cellProps.value} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                        displayType={'text'} />;
                }
            },
            {
                Header: 'Remain',
                accessor: 'remain',
                disableFilters: true,
                textAlign: 'text-end',
                headerAlign: 'text-center',
                Cell: (cellProps) => {
                    return <CurrencyFormat value={cellProps.value} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                        displayType={'text'} />;
                }
            },
            {
                Header: 'Reminder Date',
                accessor: 'reminderDateLong',
                disableFilters: true,
                textAlign: 'text-center',
                headerAlign: 'text-center',
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Payment',
                disableFilters: true,
                textAlign: 'text-center',
                headerAlign: 'text-center',
                Cell: (cellProps) => {
                    return <Link to={"/piutang/payment/"+ cellProps.row.original.companyId + '/' + cellProps.row.original.id} >
                        <Button type="button" onClick={checkPayment} className={'btn btn-info waves-effect waves-light btn-sm'}>Check</Button>
                    </Link>;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                headerCellStyle: 'text-center',
                style: {
                    textAlign: "center",
                },
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    onOpenDeleteCommand(data);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    const onRefreshCommand = () => {
        if (selectedCompany) {
            dispatch(onGetDaftarPiutang({
                companyId: selectedCompany ? selectedCompany.value : null
            }));
        }
    }

    const onResetFilterCommand = () => {
        filterCompany.current.clearValue();
    }

    const onOpenDeleteCommand = (data) => {
        setPiutang(data)
        setIsOpenDelete(true);
    }

    const onDeleteCommand = () => {
        setIsOpenDelete(false)
        dispatch(onDeletePiutang({ id: piutang.id, companyId: piutang.companyId }))
    }

    useEffect(() => {
        onRefreshCommand();
    }, [dispatch]);

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])

    useEffect(() => {
        dispatch(onGetCompanies({}));
    }, [])

    function checkPayment() {

    }

    return (
        <React.Fragment>
            <DeleteModal show={isOpenDelete} onDeleteClick={onDeleteCommand} onCloseClick={() => setIsOpenDelete(false)} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Piutang" breadcrumbItem="Piutang" />
                    <Row className="mb-3">
                        <Col xs="12">
                            <Card>
                                <CardBody className="pt-0">
                                    <CardTitle className="my-3">Filter</CardTitle>
                                    <Row>
                                        <Col className="col-12 col-sm-12">
                                            <div>
                                                <Label>Company</Label>
                                                <Select
                                                    value={selectedCompany}
                                                    onChange={handleSelectCompany}
                                                    options={optionCompany}
                                                    isLoading={loadingCompany}
                                                    isClearable={true}
                                                    ref={filterCompany}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="justify-content-end">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light float-end" style={{ minWidth: '200px' }}
                                                disabled={loading}
                                                onClick={onRefreshCommand}>
                                                <i className="bx bx-filter-alt"></i>
                                            </Button>
                                            <Button color="info" className="btn btn-info waves-effect waves-light me-2 float-end" style={{ minWidth: '100px' }}
                                                disabled={loading}
                                                onClick={onResetFilterCommand}>
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Link to="/piutang-create-update">
                                            <Button color="success" className="btn btn-success waves-effect waves-light">
                                                Add New
                                            </Button>
                                        </Link>
                                    </div>
                                    {
                                        !loading && selectedCompany &&
                                        <>
                                            <TableContainer
                                                columns={columns}
                                                data={daftarPiutang}
                                                isGlobalFilter={false}
                                                isAddOptions={true}
                                                customPageSize={10}
                                            />
                                        </>
                                    }
                                    {
                                        !loading && !selectedCompany &&
                                        <div className="d-flex justify-content-center my-5">
                                            <p>Please select company.</p>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

Piutang.propTypes = {
    daftarPiutang: PropTypes.array,
    className: PropTypes.string,
    onGetDaftarPiutang: PropTypes.func,
    onGetCompanies: PropTypes.func,
    onDeletePiutang: PropTypes.func,
    preGlobalFilteredRows: PropTypes.any,
};

export default Piutang
