import {
  GET_LIST_ACCOUNT_TRANSACTION,
  GET_LIST_ACCOUNT_TRANSACTION_SUCCESS,
  GET_LIST_ACCOUNT_TRANSACTION_FAIL,
  GET_ACCOUNT_TRANSACTION,
  GET_ACCOUNT_TRANSACTION_SUCCESS,
  GET_ACCOUNT_TRANSACTION_FAIL,
  ADD_ACCOUNT_TRANSACTION,
  ADD_ACCOUNT_TRANSACTION_SUCCESS,
  ADD_ACCOUNT_TRANSACTION_FAIL,
  DELETE_ACCOUNT_TRANSACTION,
  DELETE_ACCOUNT_TRANSACTION_SUCCESS,
  DELETE_ACCOUNT_TRANSACTION_FAIL,
  CLEAR_LIST_ACCOUNT_TRANSACTION,
  ADD_BY_EXCEL_ACCOUNT_TRANSACTION,
  ADD_BY_EXCEL_ACCOUNT_TRANSACTION_SUCCESS,
  ADD_BY_EXCEL_ACCOUNT_TRANSACTION_FAIL,
  EXPORT_EXCEL_ACCOUNT_TRANSACTION,
  EXPORT_EXCEL_ACCOUNT_TRANSACTION_SUCCESS,
  EXPORT_EXCEL_ACCOUNT_TRANSACTION_FAIL,
  UPDATE_ACCOUNT_TRANSACTION,
  UPDATE_ACCOUNT_TRANSACTION_SUCCESS,
  UPDATE_ACCOUNT_TRANSACTION_FAIL,
  } from "./actionTypes";
  
  export const getListAccountTransaction = filter => ({
    type: GET_LIST_ACCOUNT_TRANSACTION,
    payload: filter
  });
  export const getListAccountTransactionSuccess = accountTransactions => ({
    type: GET_LIST_ACCOUNT_TRANSACTION_SUCCESS,
    payload: accountTransactions,
  });
  export const getListAccountTransactionFail = error => ({
    type: GET_LIST_ACCOUNT_TRANSACTION_FAIL,
    payload: error,
  });

  export const getAccountTransaction = filter => ({
    type: GET_ACCOUNT_TRANSACTION,
    payload: filter
  });
  export const getAccountTransactionSuccess = data => ({
    type: GET_ACCOUNT_TRANSACTION_SUCCESS,
    payload: data,
  });
  export const getAccountTransactionFail = error => ({
    type: GET_ACCOUNT_TRANSACTION_FAIL,
    payload: error,
  });
  
  export const addAccountTransaction = (accountTransaction, history) => ({
    type: ADD_ACCOUNT_TRANSACTION,
    payload: { accountTransaction, history },
  });
  export const addAccountTransactionSuccess = () => ({
    type: ADD_ACCOUNT_TRANSACTION_SUCCESS,
  });
  export const addAccountTransactionFail = error => ({
    type: ADD_ACCOUNT_TRANSACTION_FAIL,
    payload: error,
  });

  export const updateAccountTransaction = (accountTransaction, history) => ({
    type: UPDATE_ACCOUNT_TRANSACTION,
    payload: { accountTransaction, history },
  });
  export const updateAccountTransactionSuccess = () => ({
    type: UPDATE_ACCOUNT_TRANSACTION_SUCCESS,
  });
  export const updateAccountTransactionFail = error => ({
    type: UPDATE_ACCOUNT_TRANSACTION_FAIL,
    payload: error,
  });
  
  export const deleteAccountTransaction = (accountTransaction, filter) => ({
    type: DELETE_ACCOUNT_TRANSACTION,
    payload: { accountTransaction, filter },
  });
  export const deleteAccountTransactionSuccess = () => ({
    type: DELETE_ACCOUNT_TRANSACTION_SUCCESS,
  });
  export const deleteAccountTransactionFail = error => ({
    type: DELETE_ACCOUNT_TRANSACTION_FAIL,
    payload: error,
  });

  export const clearListAccountTransaction = () => ({
    type: CLEAR_LIST_ACCOUNT_TRANSACTION,
  });

  export const addAccountTransactionByExcel = (uploadFile, companyId, history) => ({
    type: ADD_BY_EXCEL_ACCOUNT_TRANSACTION,
    payload: { uploadFile, companyId, history },
  });
  export const addAccountTransactionByExcelSuccess = () => ({
    type: ADD_BY_EXCEL_ACCOUNT_TRANSACTION_SUCCESS,
  });
  export const addAccountTransactionByExcelFail = error => ({
    type: ADD_BY_EXCEL_ACCOUNT_TRANSACTION_FAIL,
    payload: error,
  });

  export const exportExcelAccountTransaction = filter => ({
    type: EXPORT_EXCEL_ACCOUNT_TRANSACTION,
    payload: { filter },
  });
  export const exportExcelAccountTransactionSuccess = () => ({
    type: EXPORT_EXCEL_ACCOUNT_TRANSACTION_SUCCESS,
  });
  export const exportExcelAccountTransactionFail = error => ({
    type: EXPORT_EXCEL_ACCOUNT_TRANSACTION_FAIL,
    payload: error,
  });