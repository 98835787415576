import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { CardTitle, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import Select from "react-select";

const ModalImportAccount = ({ show, onSubmit, onCancel, company }) => {
    const [selectedType, setSelectedType] = useState(null)
    const onChange = (e) => {
        setSelectedType(e)
    }

    useEffect(() => {
        setSelectedType(null)
    }, [show])

    return (
        <Modal isOpen={show} toggle={onCancel} centered={true}>
            <ModalHeader>
                <CardTitle>Import Account - {company?.name}</CardTitle>
            </ModalHeader>
            <ModalBody className="p-3">
                <Row>
                    <Col lg={12}>
                        <div className="text-start">
                            <Label>Select Company Type</Label>
                            <Select
                                value={selectedType}
                                onChange={onChange}
                                options={[
                                    { label: "Store", value: "Store" },
                                    { label: "Resto", value: "Resto" },
                                    { label: "Konstruksi", value: "Konstruksi" },
                                    { label: "Hotel", value: "Hotel" },
                                    { label: "Hotel dan Resto", value: "HotelAndResto" },
                                    { label: "Hotel dan Surf", value: "HotelAndSurf" },
                                    { label: "Hotel, Surf dan Store", value: "HotelSurfAndStore" },
                                ]}
                                className="select2-selection"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end mt-3">
                            <button
                                type="button"
                                className="btn btn-success ms-2"
                                onClick={() => {
                                    onSubmit(selectedType.value)
                                }}
                                disabled={!selectedType}
                            >
                                Import
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger ms-2"
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

ModalImportAccount.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    show: PropTypes.any,
    company: PropTypes.any,
}

export default ModalImportAccount
