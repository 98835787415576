import toastr from "toastr";
toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 3000,
  extendedTimeOut: 2000,
  closeButton: true,
  debug: false,
  progressBar: true,
  preventDuplicates: false,
  newestOnTop: true,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "slideDown",
  hideMethod: "slideUp",
  showDuration: 300,
  hideDuration: 300
};

export { toastr as NotifHelper };