import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_LIST_ASSET_CATEGORY,
    GET_ASSET_CATEGORY,
    ADD_ASSET_CATEGORY,
    UPDATE_ASSET_CATEGORY,
    DELETE_ASSET_CATEGORY,
} from "./actionTypes"
import {
    getListAssetCategorySuccess,
    getListAssetCategoryFail,
    getAssetCategoryByIdSuccess,
    getAssetCategoryByIdFail,
    addAssetCategorySuccess,
    addAssetCategoryFail,
    updateAssetCategorySuccess,
    updateAssetCategoryFail,
    deleteAssetCategorySuccess,
    deleteAssetCategoryFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
    getListAssetCategory,
    getAssetCategory,
    addAssetCategory,
    updateAssetCategory,
    deleteAssetCategory
} from "../../helpers/backend_helper"

function* onFetchListAssetCategory({ payload: filter }) {
    try {
        const response = yield call(getListAssetCategory, filter)
        if (response.status) {
            yield put(getListAssetCategorySuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getListAssetCategoryFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getListAssetCategoryFail(error))
    }
}

function* onGetAssetCategoryById({ payload: { id, companyId } }) {
    try {
        const response = yield call(getAssetCategory, { id: id, companyId: companyId })
        if (response.status) {
            yield put(getAssetCategoryByIdSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getAssetCategoryByIdFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getAssetCategoryByIdFail(error))
    }
}

function* onAddAssetCategory({ payload: { assetCategory, history } }) {
    try {
        const response = yield call(addAssetCategory, assetCategory)
        if (response.status) {
            NotifHelper.success(msg.ASSET_CATEGORY_ADD_SUCCESS)
            yield put(addAssetCategorySuccess())
            history.push("/masterdata/asset-category")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addAssetCategoryFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addAssetCategoryFail(error))
    }
}

function* onUpdateAssetCategory({ payload: { assetCategory, history } }) {
    try {
        const response = yield call(updateAssetCategory, assetCategory)
        if (response.status) {
            NotifHelper.success(msg.ASSET_CATEGORY_UPDATE_SUCCESS)
            yield put(updateAssetCategorySuccess())
            history.push("/masterdata/account")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updateAssetCategoryFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updateAssetCategoryFail(error))
    }
}

function* onDeleteAssetCategory({ payload: { assetCategory, filter } }) {
    try {
        const response = yield call(deleteAssetCategory, assetCategory)
        if (response.status) {
            NotifHelper.success(msg.ASSET_CATEGORY_DELETE_SUCCESS)
            yield put(deleteAssetCategorySuccess())
            const responseNewData = yield call(getListAssetCategory, filter)
            if (responseNewData.status) {
                yield put(getListAssetCategorySuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getListAssetCategoryFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deleteAssetCategoryFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deleteAssetCategoryFail(error))
    }
}

function* assetCategorySaga() {
    yield takeEvery(GET_LIST_ASSET_CATEGORY, onFetchListAssetCategory)
    yield takeEvery(GET_ASSET_CATEGORY, onGetAssetCategoryById)
    yield takeEvery(ADD_ASSET_CATEGORY, onAddAssetCategory)
    yield takeEvery(UPDATE_ASSET_CATEGORY, onUpdateAssetCategory)
    yield takeEvery(DELETE_ASSET_CATEGORY, onDeleteAssetCategory)
}

export default assetCategorySaga