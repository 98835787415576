import {
  GET_LIST_ASSET_TYPE,
  GET_LIST_ASSET_TYPE_SUCCESS,
  GET_LIST_ASSET_TYPE_FAIL,
  GET_ASSET_TYPE,
  GET_ASSET_TYPE_SUCCESS,
  GET_ASSET_TYPE_FAIL,
  ADD_ASSET_TYPE,
  ADD_ASSET_TYPE_SUCCESS,
  ADD_ASSET_TYPE_FAIL,
  UPDATE_ASSET_TYPE,
  UPDATE_ASSET_TYPE_SUCCESS,
  UPDATE_ASSET_TYPE_FAIL,
  DELETE_ASSET_TYPE,
  DELETE_ASSET_TYPE_SUCCESS,
  DELETE_ASSET_TYPE_FAIL,
  CLEAR_GET_LIST_ASSET_TYPE,
} from "./actionTypes";

export const getListAssetType = filter => ({
  type: GET_LIST_ASSET_TYPE,
  payload: filter
});
export const getListAssetTypeSuccess = assetTypes => ({
  type: GET_LIST_ASSET_TYPE_SUCCESS,
  payload: assetTypes,
});
export const getListAssetTypeFail = error => ({
  type: GET_LIST_ASSET_TYPE_FAIL,
  payload: error,
});

export const getAssetTypeById = (id, companyId) => ({
  type: GET_ASSET_TYPE,
  payload: { id : id, companyId : companyId}
});
export const getAssetTypeByIdSuccess = assetType => ({
  type: GET_ASSET_TYPE_SUCCESS,
  payload: assetType,
});
export const getAssetTypeByIdFail = error => ({
  type: GET_ASSET_TYPE_FAIL,
  payload: error,
});

export const addAssetType = (assetType, history) => ({
  type: ADD_ASSET_TYPE,
  payload: { assetType, history },
});
export const addAssetTypeSuccess = () => ({
  type: ADD_ASSET_TYPE_SUCCESS,
});
export const addAssetTypeFail = error => ({
  type: ADD_ASSET_TYPE_FAIL,
  payload: error,
});

export const updateAssetType = (assetType, history) => ({
  type: UPDATE_ASSET_TYPE,
  payload: { assetType, history },
});
export const updateAssetTypeSuccess = () => ({
  type: UPDATE_ASSET_TYPE_SUCCESS,
});
export const updateAssetTypeFail = error => ({
  type: UPDATE_ASSET_TYPE_FAIL,
  payload: error,
});

export const deleteAssetType = (assetType, filter) => ({
  type: DELETE_ASSET_TYPE,
  payload: { assetType, filter },
});
export const deleteAssetTypeSuccess = () => ({
  type: DELETE_ASSET_TYPE_SUCCESS,
});
export const deleteAssetTypeFail = error => ({
  type: DELETE_ASSET_TYPE_FAIL,
  payload: error,
});

export const clearListAssetType = () => ({
  type: CLEAR_GET_LIST_ASSET_TYPE,
});