/* PERIOD */
export const GET_LIST_ASSET_TYPE = "GET_LIST_ASSET_TYPE";
export const GET_LIST_ASSET_TYPE_SUCCESS = "GET_LIST_ASSET_TYPE_SUCCESS";
export const GET_LIST_ASSET_TYPE_FAIL = "GET_LIST_ASSET_TYPE_FAIL";

export const GET_ASSET_TYPE = "GET_ASSET_TYPE";
export const GET_ASSET_TYPE_SUCCESS = "GET_ASSET_TYPE_SUCCESS";
export const GET_ASSET_TYPE_FAIL = "GET_ASSET_TYPE_FAIL";

export const ADD_ASSET_TYPE = "ADD_ASSET_TYPE";
export const ADD_ASSET_TYPE_SUCCESS = "ADD_ASSET_TYPE_SUCCESS";
export const ADD_ASSET_TYPE_FAIL = "ADD_ASSET_TYPE_FAIL";

export const UPDATE_ASSET_TYPE = "UPDATE_ASSET_TYPE";
export const UPDATE_ASSET_TYPE_SUCCESS = "UPDATE_ASSET_TYPE_SUCCESS";
export const UPDATE_ASSET_TYPE_FAIL = "UPDATE_ASSET_TYPE_FAIL";

export const DELETE_ASSET_TYPE = "DELETE_ASSET_TYPE";
export const DELETE_ASSET_TYPE_SUCCESS = "DELETE_ASSET_TYPE_SUCCESS";
export const DELETE_ASSET_TYPE_FAIL = "DELETE_ASSET_TYPE_FAIL";

export const CLEAR_GET_LIST_ASSET_TYPE = "CLEAR_GET_LIST_ASSET_TYPE";