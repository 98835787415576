import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_LIST_ACCOUNT_NUMBER,
    GET_ACCOUNT_NUMBER,
    ADD_ACCOUNT_NUMBER,
    UPDATE_ACCOUNT_NUMBER,
    DELETE_ACCOUNT_NUMBER,
} from "./actionTypes"
import {
    getListAccountNumberSuccess,
    getListAccountNumberFail,
    getAccountNumberByIdSuccess,
    getAccountNumberByIdFail,
    addAccountNumberSuccess,
    addAccountNumberFail,
    updateAccountNumberFail,
    updateAccountNumberSuccess,
    deleteAccountNumberFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
    getListAccountNumber,
    getAccountNumber,
    addAccountNumber,
    updateAccountNumber,
    deleteAccountNumber
} from "../../helpers/backend_helper"

function* onFetchListAccountNumber({ payload: filter }) {
    try {
        const response = yield call(getListAccountNumber, filter)
        if (response.status) {
            yield put(getListAccountNumberSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getListAccountNumberFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getListAccountNumberFail(error))
    }
}

function* onGetAccountNumberById({ payload: param }) {
    try {
        const response = yield call(getAccountNumber, param)
        if (response.status) {
            yield put(getAccountNumberByIdSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getAccountNumberByIdFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getAccountNumberByIdFail(error))
    }
}

function* onAddAccountNumber({ payload: { account_number, history } }) {
    try {
        const response = yield call(addAccountNumber, account_number)
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_NUMBER_ADD_SUCCESS)
            yield put(addAccountNumberSuccess(response.data))
            history.push("/masterdata/account-number")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addAccountNumberFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addAccountNumberFail(error))
    }
}

function* onUpdateAccountNumber({ payload: { account_number, history } }) {
    try {
        const response = yield call(updateAccountNumber, account_number)
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_NUMBER_UPDATE_SUCCESS)
            yield put(updateAccountNumberSuccess())
            history.push("/masterdata/account-number")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updateAccountNumberFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updateAccountNumberFail(error))
    }
}

function* onDeleteAccountNumber({ payload: { account_number, filter } }) {
    try {
        const response = yield call(deleteAccountNumber, account_number)
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_NUMBER_DELETE_SUCCESS)
            const responseNewData = yield call(getListAccountNumber, filter)
            if (responseNewData.status) {
                yield put(getListAccountNumberSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getListAccountNumberFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deleteAccountNumberFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deleteAccountNumberFail(error))
    }
}

function* accountNumberSaga() {
    yield takeEvery(GET_LIST_ACCOUNT_NUMBER, onFetchListAccountNumber)
    yield takeEvery(GET_ACCOUNT_NUMBER, onGetAccountNumberById)
    yield takeEvery(ADD_ACCOUNT_NUMBER, onAddAccountNumber)
    yield takeEvery(UPDATE_ACCOUNT_NUMBER, onUpdateAccountNumber)
    yield takeEvery(DELETE_ACCOUNT_NUMBER, onDeleteAccountNumber)
}

export default accountNumberSaga