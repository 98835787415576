import React, { useState, useMemo, useEffect, useRef, createRef } from "react";
import PropTypes, { func } from "prop-types";
import { withRouter, Link } from 'react-router-dom';
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Alert
} from "reactstrap";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
    getRoleById as onGetRole,
    updateRole as onUpdateRole,
    addRole as onAddRole
} from "../../store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const RoleCreateUpdate = (props) => {

    //meta title
    document.title = "Role | User Management | Des Counts";
    const id = props?.match?.params?.id;
    const isEdit = id != undefined;

    var access = [
        {
            groupId: 1,
            groupName: 'Master Data',
            groupAccess: [
                {
                    id: 2,
                    name: 'Account',
                    check: false
                },
                {
                    id: 3,
                    name: 'Account Number',
                    check: false
                },
                {
                    id: 4,
                    name: 'Period',
                    check: false
                },
                {
                    id: 9,
                    name: 'Asset Category',
                    check: false
                },
                {
                    id: 10,
                    name: 'Asset Type',
                    check: false
                }
            ]
        },
        {
            groupId: 2,
            groupName: 'User Management',
            groupAccess: [
                {
                    id: 5,
                    name: 'Role',
                    check: false
                },
                {
                    id: 6,
                    name: 'User',
                    check: false
                },
            ]
        },
        {
            groupId: 3,
            groupName: 'Transaction',
            groupAccess: [
                {
                    id: 7,
                    name: 'Transaction',
                    check: false
                },
            ]
        },
        {
            groupId: 4,
            groupName: 'Worksheet',
            groupAccess: [
                {
                    id: 8,
                    name: 'Worksheet',
                    check: false
                },
            ]
        },
        {
            groupId: 5,
            groupName: 'Asset',
            groupAccess: [
                {
                    id: 11,
                    name: 'Asset',
                    check: false
                },
            ]
        },
        {
            groupId: 6,
            groupName: 'Piutang',
            groupAccess: [
                {
                    id: 12,
                    name: 'Piutang',
                    check: false
                },
            ]
        },
        {
            groupId: 7,
            groupName: 'Report',
            groupAccess: [
                {
                    id: 13,
                    name: 'Neraca',
                    check: false
                },
                {
                    id: 14,
                    name: 'Profit & Loss',
                    check: false
                },
            ]
        }
    ];

    var accessData = []
    const setRef = (ref, id) => {
        accessData.push({ id: id, ref: ref })
    }

    const { role, loading } = useSelector(state => ({
        role: state.Role.role,
        loading: state.Role.loading,
    }));

    const getAccess = async function () {
        let res = []
        accessData.map((item) => {
            if (item.ref.checked) {
                res.push(item.id)
            }
        })
        return res
    }

    const setAcessData = async function (data) {
        const res = access
        data.forEach(n => {
            res.forEach(grp => {
                grp.groupAccess.forEach(acc => {
                    if (acc.id == n) {
                        acc.check = true
                    }
                })
            })
        })
        return res
    }


    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: isEdit && role ? role.name : "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Role Name"),
        }),
        onSubmit: (values) => {
            getAccess().then(s => {
                if (isEdit) {
                    dispatch(onUpdateRole({ id: role.id, name: values.name, accessId: s }, props.history));
                } else {
                    dispatch(onAddRole({
                        name: values.name,
                        accessId: s
                    }, props.history));
                }
            })
        },
    });

    useEffect(() => {
        if (isEdit) {
            dispatch(onGetRole(id))
        }
    }, [dispatch])

    useEffect(() => {
        if (role) {
            role.accessId.map(item => {
                var dt = accessData.filter(s => s.id == item)
                if (dt.length > 0) {
                    dt[0].ref = true;
                }
            })
        }
    }, [role])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="User Management" breadcrumbItem={(isEdit ? "Edit" : "Add") + " Role"} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {
                                        !loading &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <Form
                                                        className="form-horizontal"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col className="col-12">
                                                                <div className="mb-3">
                                                                    <Label className="form-label">Name</Label>
                                                                    <Input
                                                                        name="name"
                                                                        className="form-control"
                                                                        placeholder="Enter role name"
                                                                        type="text"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.name || ""}
                                                                        invalid={
                                                                            validation.touched.name && validation.errors.name ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.name && validation.errors.name ? (
                                                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mx-3">
                                                            {
                                                                !loading && access &&
                                                                access.map((item) => {
                                                                    return <Col key={'group' + item.groupId} className={'col-sm-3 mt-5'}>
                                                                        <h4>{item.groupName}</h4>
                                                                        {
                                                                            item.groupAccess.map((acc) => {
                                                                                return <div className="form-check ms-2 mt-2" key={'acc' + acc.id}>
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        className="form-check-Input"
                                                                                        id={"accessCheck" + acc.id}
                                                                                        defaultChecked={isEdit ?
                                                                                            (role && role.accessId && role.accessId.includes(acc.id) ? true : false)
                                                                                            : false}
                                                                                        innerRef={ref => setRef(ref, acc.id)}
                                                                                    />
                                                                                    <Label
                                                                                        className="form-check-label ms-1"
                                                                                        htmlFor={"accessCheck" + acc.id}
                                                                                    >
                                                                                        {acc.name}
                                                                                    </Label>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </Col>
                                                                })
                                                            }
                                                            <Col>

                                                            </Col>
                                                        </Row>

                                                        <div className="mt-5 mb-2">
                                                            <Link to="/usermanagement/role">
                                                                <Button
                                                                    color="secondary"
                                                                    className="btn btn-secondary float-end"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                color="primary"
                                                                className="btn btn-primary float-end me-2"
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

RoleCreateUpdate.propTypes = {
    history: PropTypes.object,
};

export default withRouter(RoleCreateUpdate)
