import {
  GET_LIST_ASSET,
  GET_LIST_ASSET_SUCCESS,
  GET_LIST_ASSET_FAIL,
  GET_ASSET,
  GET_ASSET_SUCCESS,
  GET_ASSET_FAIL,
  ADD_ASSET,
  ADD_ASSET_SUCCESS,
  ADD_ASSET_FAIL,
  UPDATE_ASSET,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAIL,
  DELETE_ASSET,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAIL,
  EXPORT_EXCEL_ASSET,
  EXPORT_EXCEL_ASSET_SUCCESS,
  EXPORT_EXCEL_ASSET_FAILED,
  GET_TEMPLATE_EXCEL_ASSET,
  GET_TEMPLATE_EXCEL_ASSET_SUCCESS,
  GET_TEMPLATE_EXCEL_ASSET_FAILED,
  SUBMIT_IMPORT_EXCEL_ASSET,
  SUBMIT_IMPORT_EXCEL_ASSET_SUCCESS,
  SUBMIT_IMPORT_EXCEL_ASSET_FAILED,
} from "./actionTypes";

export const getListAsset = filter => ({
  type: GET_LIST_ASSET,
  payload: filter
});
export const getListAssetSuccess = assets => ({
  type: GET_LIST_ASSET_SUCCESS,
  payload: assets,
});
export const getListAssetFail = error => ({
  type: GET_LIST_ASSET_FAIL,
  payload: error,
});

export const getAssetById = (id, companyId) => ({
  type: GET_ASSET,
  payload: { id: id, companyId: companyId }
});
export const getAssetByIdSuccess = asset => ({
  type: GET_ASSET_SUCCESS,
  payload: asset,
});
export const getAssetByIdFail = error => ({
  type: GET_ASSET_FAIL,
  payload: error,
});

export const addAsset = (asset, history) => ({
  type: ADD_ASSET,
  payload: { asset, history },
});
export const addAssetSuccess = () => ({
  type: ADD_ASSET_SUCCESS,
});
export const addAssetFail = error => ({
  type: ADD_ASSET_FAIL,
  payload: error,
});

export const updateAsset = (asset, history) => ({
  type: UPDATE_ASSET,
  payload: { asset, history },
});
export const updateAssetSuccess = () => ({
  type: UPDATE_ASSET_SUCCESS,
});
export const updateAssetFail = error => ({
  type: UPDATE_ASSET_FAIL,
  payload: error,
});

export const deleteAsset = (asset, filter) => ({
  type: DELETE_ASSET,
  payload: { asset, filter },
});
export const deleteAssetSuccess = () => ({
  type: DELETE_ASSET_SUCCESS,
});
export const deleteAssetFail = error => ({
  type: DELETE_ASSET_FAIL,
  payload: error,
});

export const exportExcelAsset = filter => ({
  type: EXPORT_EXCEL_ASSET,
  payload: filter,
});
export const exportExcelSuccess = () => ({
  type: EXPORT_EXCEL_ASSET_SUCCESS,
});
export const exportExcelFail = error => ({
  type: EXPORT_EXCEL_ASSET_FAILED,
  payload: error,
});

export const getTemplateExcelAsset = filter => ({
  type: GET_TEMPLATE_EXCEL_ASSET,
  payload: { filter },
});
export const getTemplateExcelSuccess = () => ({
  type: GET_TEMPLATE_EXCEL_ASSET_SUCCESS,
});
export const getTemplateExcelFail = error => ({
  type: GET_TEMPLATE_EXCEL_ASSET_FAILED,
  payload: error,
});

export const submitImportExcel = (uploadFile, companyId, history) => ({
  type: SUBMIT_IMPORT_EXCEL_ASSET,
  payload: { uploadFile, companyId, history },
});
export const submitImportExcelSuccess = () => ({
  type: SUBMIT_IMPORT_EXCEL_ASSET_SUCCESS,
});
export const submitImportExcelFail = error => ({
  type: SUBMIT_IMPORT_EXCEL_ASSET_FAILED,
  payload: error,
});