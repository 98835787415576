import {
  GET_LIST_WORKSHEET,
  GET_LIST_WORKSHEET_SUCCESS,
  GET_LIST_WORKSHEET_FAIL,
  EXPORT_EXCEL_WORKSHEET,
  EXPORT_EXCEL_WORKSHEET_SUCCESS,
  EXPORT_EXCEL_WORKSHEET_FAIL,
  CLEAR_LIST_WORKSHEET,
  BALANCING_WORKSHEET,
  BALANCING_WORKSHEET_SUCCESS,
  BALANCING_WORKSHEET_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  listWorksheet: [],
  listSummaryAccount: [],
  listSummary: [],
  listAccountNumber: [],
  worksheet: null,
  error: '',
  loading: false
};

const Worksheet = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_WORKSHEET:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_WORKSHEET_SUCCESS:
      return {
        ...state,
        listWorksheet: action.payload.listWorksheet,
        listSummaryAccount: action.payload.listSummaryAccount,
        listSummary: action.payload.listSummary,
        listAccountNumber: action.payload.listAccountNumber,
        loading: false,
      };
    case GET_LIST_WORKSHEET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case EXPORT_EXCEL_WORKSHEET:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_EXCEL_WORKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EXPORT_EXCEL_WORKSHEET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case BALANCING_WORKSHEET:
      return {
        ...state,
        loading: true,
      };
    case BALANCING_WORKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BALANCING_WORKSHEET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };


    case CLEAR_LIST_WORKSHEET:
      return {
        ...state,
        listWorksheet: [],
      };

    default:
      return state;
  }
};

export default Worksheet;
