/* PERIOD */
export const GET_LIST_ACCOUNT_TRANSACTION = "GET_LIST_ACCOUNT_TRANSACTION";
export const GET_LIST_ACCOUNT_TRANSACTION_SUCCESS = "GET_LIST_ACCOUNT_TRANSACTION_SUCCESS";
export const GET_LIST_ACCOUNT_TRANSACTION_FAIL = "GET_LIST_ACCOUNT_TRANSACTION_FAIL";

export const GET_ACCOUNT_TRANSACTION = "GET_ACCOUNT_TRANSACTION";
export const GET_ACCOUNT_TRANSACTION_SUCCESS = "GET_ACCOUNT_TRANSACTION_SUCCESS";
export const GET_ACCOUNT_TRANSACTION_FAIL = "GET_ACCOUNT_TRANSACTION_FAIL";

export const ADD_ACCOUNT_TRANSACTION = "ADD_ACCOUNT_TRANSACTION";
export const ADD_ACCOUNT_TRANSACTION_SUCCESS = "ADD_ACCOUNT_TRANSACTION_SUCCESS";
export const ADD_ACCOUNT_TRANSACTION_FAIL = "ADD_ACCOUNT_TRANSACTION_FAIL";

export const UPDATE_ACCOUNT_TRANSACTION = "UPDATE_ACCOUNT_TRANSACTION";
export const UPDATE_ACCOUNT_TRANSACTION_SUCCESS = "UPDATE_ACCOUNT_TRANSACTION_SUCCESS";
export const UPDATE_ACCOUNT_TRANSACTION_FAIL = "UPDATE_ACCOUNT_TRANSACTION_FAIL";

export const DELETE_ACCOUNT_TRANSACTION = "DELETE_ACCOUNT_TRANSACTION";
export const DELETE_ACCOUNT_TRANSACTION_SUCCESS = "DELETE_ACCOUNT_TRANSACTION_SUCCESS";
export const DELETE_ACCOUNT_TRANSACTION_FAIL = "DELETE_ACCOUNT_TRANSACTION_FAIL";

export const CLEAR_LIST_ACCOUNT_TRANSACTION = "CLEAR_LIST_ACCOUNT_TRANSACTION";

export const ADD_BY_EXCEL_ACCOUNT_TRANSACTION = "ADD_BY_EXCEL_ACCOUNT_TRANSACTION";
export const ADD_BY_EXCEL_ACCOUNT_TRANSACTION_SUCCESS = "ADD_BY_EXCEL_ACCOUNT_TRANSACTION_SUCCESS";
export const ADD_BY_EXCEL_ACCOUNT_TRANSACTION_FAIL = "ADD_BY_EXCEL_ACCOUNT_TRANSACTION_FAIL";

export const EXPORT_EXCEL_ACCOUNT_TRANSACTION = "EXPORT_EXCEL_ACCOUNT_TRANSACTION";
export const EXPORT_EXCEL_ACCOUNT_TRANSACTION_SUCCESS = "EXPORT_EXCEL_ACCOUNT_TRANSACTION_SUCCESS";
export const EXPORT_EXCEL_ACCOUNT_TRANSACTION_FAIL = "EXPORT_EXCEL_ACCOUNT_TRANSACTION_FAIL";