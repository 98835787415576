import React, { useState, useMemo, useEffect, useRef, createRef } from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import './custom.css'
import {
    Container,
    Button,
    Col,
    Row,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";

//Import Breadcrumb

import {
    changePasswordUser as onChangePasswordUser,
} from "../../store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const ChangePassword = () => {
    //meta title
    document.title = "Change Password | Des Counts";
    const dispatch = useDispatch();
    const [showPasswordCurrent, setShowPasswordCurrent] = useState(false)
    const [showPasswordNew, setShowPasswordNew] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    const { loading } = useSelector(state => ({
        loading: state.User.loading,
    }));

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            oldPassword: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().min(8, "Password must be at least 8 character").required("Please Enter Current Password"),
            password: Yup.string().min(8, "Password must be at least 8 character").required("Please Enter New Password"),
            confirmPassword: Yup
                .string()
                .required("Please Enter New Password")
                .oneOf([Yup.ref('password')], 'Confirmation Password not matched')
        }),
        onSubmit: (values) => {
            dispatch(onChangePasswordUser({
                password: values.password,
                oldPassword: values.oldPassword
            }))
        },
    });

    useEffect(() => {
        if (!loading) {
            validation.resetForm()
        }
    }, [loading])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {
                                        !loading &&
                                        <>
                                            <CardTitle>
                                                Change Password
                                            </CardTitle>
                                            <Container fluid={true} className={'px-3 mt-2'}>
                                                <Row className="mt-3">
                                                    <Col>
                                                        <Form
                                                            className="form-horizontal"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                validation.handleSubmit();
                                                                return false;
                                                            }}
                                                        >
                                                            <Row>
                                                                <Col>
                                                                    <Label className="form-label">Current Password</Label>
                                                                    <div className="input-group">
                                                                        <Input
                                                                            name="oldPassword"
                                                                            className="form-control"
                                                                            autoComplete="old-password"
                                                                            id="old-password"
                                                                            placeholder="Enter ..."
                                                                            type={showPasswordCurrent ? "text" : "password"}
                                                                            value={validation.values.oldPassword || ""}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={
                                                                                validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                                                                            }
                                                                        />
                                                                        <div className="input-group-addon position-absolute">
                                                                            <a onClick={() => {
                                                                                setShowPasswordCurrent(!showPasswordCurrent)
                                                                            }}>
                                                                                {!showPasswordCurrent && <i className="fas fa-eye-slash"></i>}
                                                                                {showPasswordCurrent && <i className="fas fa-eye"></i>}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    {validation.touched.oldPassword && validation.errors.oldPassword ? (
                                                                        <FormFeedback type="invalid"
                                                                            style={{ backgroundImage: 'none !important' }}>{validation.errors.oldPassword}</FormFeedback>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-3">
                                                                <Col>
                                                                    <Label className="form-label">New Password</Label>
                                                                    <div className="input-group">
                                                                        <Input
                                                                            name="password"
                                                                            className="form-control"
                                                                            autoComplete="new-password"
                                                                            id="new-password"
                                                                            placeholder="Enter ..."
                                                                            type={showPasswordNew ? "text" : "password"}
                                                                            value={validation.values.password || ""}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={
                                                                                validation.touched.password && validation.errors.password ? true : false
                                                                            }
                                                                        />
                                                                        <div className="input-group-addon position-absolute" style={{ right: '10px', top: '10px' }}>
                                                                            <a onClick={() => {
                                                                                setShowPasswordNew(!showPasswordNew)
                                                                            }}>
                                                                                {!showPasswordNew && <i className="fas fa-eye-slash"></i>}
                                                                                {showPasswordNew && <i className="fas fa-eye"></i>}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    {validation.touched.password && validation.errors.password ? (
                                                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-3">
                                                                <Col>
                                                                    <Label className="form-label">Confirm New Password</Label>
                                                                    <div className="input-group">
                                                                        <Input
                                                                            name="confirmPassword"
                                                                            className="form-control"
                                                                            autoComplete="confirm-password"
                                                                            id="confirm-password"
                                                                            placeholder="Enter ..."
                                                                            type={showPasswordConfirm ? "text" : "password"}
                                                                            value={validation.values.confirmPassword || ""}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={
                                                                                validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                                            }
                                                                        />
                                                                        <div className="input-group-addon position-absolute" style={{ right: '10px', top: '10px' }}>
                                                                            <a onClick={() => {
                                                                                setShowPasswordConfirm(!showPasswordConfirm)
                                                                            }}>
                                                                                {!showPasswordConfirm && <i className="fas fa-eye-slash"></i>}
                                                                                {showPasswordConfirm && <i className="fas fa-eye"></i>}
                                                                            </a>
                                                                        </div>
                                                                    </div>

                                                                    {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                                        <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>
                                                            <div className="mt-3 mb-2">
                                                                <Button
                                                                    color="primary"
                                                                    className="btn btn-primary"
                                                                    type="submit">
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

ChangePassword.propTypes = {
    history: PropTypes.object,
};

export default withRouter(ChangePassword)
