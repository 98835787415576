import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import fileDownload from 'js-file-download'
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_LIST_WORKSHEET,
    EXPORT_EXCEL_WORKSHEET,
    BALANCING_WORKSHEET
} from "./actionTypes"
import {
    getListWorksheetSuccess,
    getListWorksheetFail,
    exportExcelWorksheetSuccess,
    exportExcelWorksheetFail,
    balancingWorksheetSuccess,
    balancingWorksheetFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getListWorksheet,
    exportExcelWorksheet,
    balancingWorksheet
} from "../../helpers/backend_helper"

function* onFetchListWorksheet({ payload: filter }) {
    try {
        const response = yield call(getListWorksheet, filter)
        if (response.status) {
            yield put(getListWorksheetSuccess({
                listWorksheet: response.data,
                listSummaryAccount: response.summaryAccount,
                listSummary: response.summary,
                listAccountNumber: response.dataAccountNumber,
            }))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getListWorksheetFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getListWorksheetFail(error))
    }
}

function* onExportExcelWorksheet({ payload: filter }) {
    try {
        const response = yield call(exportExcelWorksheet, filter, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        let endFileNameIndex = headerLine.lastIndexOf(';');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        fileDownload(response.data, filename)
        yield put(exportExcelWorksheetSuccess())
    } catch (error) {
        NotifHelper.error(error)
        yield put(exportExcelWorksheetFail(error))
    }
}

function* onBalancingWorksheet({ payload: filter }) {
    try {
        const response = yield call(balancingWorksheet, filter)
        if (response.status) {
            NotifHelper.success(msg.UPDATE_BALANCING_SUCCESS)
            yield put(balancingWorksheetSuccess())
            const response1 = yield call(getListWorksheet, filter)
            if (response1.status) {
                yield put(getListWorksheetSuccess({
                    listWorksheet: response1.data,
                    listSummaryAccount: response1.summaryAccount,
                    listSummary: response1.summary,
                    listAccountNumber: response1.dataAccountNumber,
                }))
            }
            else {
                NotifHelper.error(response1.message)
                yield put(getListWorksheetFail(response1.message))
            }
        }
        else {
            NotifHelper.success(response.message)
            yield put(balancingWorksheetFail(error))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(balancingWorksheetFail(error))
    }
}

function* woksheetSaga() {
    yield takeEvery(GET_LIST_WORKSHEET, onFetchListWorksheet)
    yield takeEvery(EXPORT_EXCEL_WORKSHEET, onExportExcelWorksheet)
    yield takeEvery(BALANCING_WORKSHEET, onBalancingWorksheet)
}

export default woksheetSaga