import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const chartAccountNumber = ({ dataColors, data }) => {
    const apaexlineChartColors = getChartColorsArray(dataColors);

    const series = [
        { name: "Debit", data: data.yValueDebit },
        { name: "Credit", data: data.yValueCredit },
    ]
    const options = {
        chart: {
            height: 380,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        colors: apaexlineChartColors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [3, 3],
            curve: 'straight'
        },
        title: {
            text: 'Data Transaction',
            align: 'left',
            style: {
                fontWeight: '500',
            },
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.2
            },
            borderColor: '#f1f1f1'
        },
        markers: {
            style: 'inverted',
            size: 6
        },
        xaxis: {
            categories: data.xLabel,
            title: {
                text: 'Tanggal'
            }
        },
        yaxis: {
            show: false,
            title: {
                text: 'Nominal'
            },
            min: 0,
            labels: {
                formatter: function (value) {
                    if (parseFloat(value) >= 1000) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                        return value;
                    }
                }
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
            }
        }],
    }

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="380"
            className="apex-charts"
        />
    )
}

export default chartAccountNumber
