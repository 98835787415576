/* COMPANY */
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAIL = "GET_COMPANy_FAIL";

export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";

export const IMPORT_ACCOUNT_COMPANY = "IMPORT_ACCOUNT_COMPANY";
export const IMPORT_ACCOUNT_COMPANY_SUCCESS = "IMPORT_ACCOUNT_COMPANY_SUCCESS";
export const IMPORT_ACCOUNT_COMPANY_FAIL = "IMPORT_ACCOUNT_COMPANY_FAIL";

export const UPLOAD_TEMPLATE_REPORT = "UPLOAD_TEMPLATE_REPORT";
export const UPLOAD_TEMPLATE_REPORT_SUCCESS = "UPLOAD_TEMPLATE_REPORT_SUCCESS";
export const UPLOAD_TEMPLATE_REPORT_FAIL = "UPLOAD_TEMPLATE_REPORT_FAIL";

export const UPLOAD_TEMPLATE_REPORT_PROFITLOSS = "UPLOAD_TEMPLATE_REPORT_PROFITLOSS";
export const UPLOAD_TEMPLATE_REPORT_PROFITLOSS_SUCCESS = "UPLOAD_TEMPLATE_REPORT_PROFITLOSS_SUCCESS";
export const UPLOAD_TEMPLATE_REPORT_PROFITLOSS_FAIL = "UPLOAD_TEMPLATE_REPORT_PROFITLOSS_FAIL";

export const EXPORT_ACCOUNT_LIST = "EXPORT_ACCOUNT_LIST";
export const EXPORT_ACCOUNT_LIST_SUCCESS = "EXPORT_ACCOUNT_LIST_SUCCESS";
export const EXPORT_ACCOUNT_LIST_FAIL = "EXPORT_ACCOUNT_LIST_FAIL";