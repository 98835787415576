import PropTypes from 'prop-types'
import React from "react"
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"

const ModalReOpenPeriod = ({ show, periodName, onSubmit, onCancel }) => {
  return (
    <Modal isOpen={show} toggle={onCancel} centered={true}>
      <ModalHeader>
        Confirmation Re-Open Period {periodName}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h2>Are you sure?</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={onSubmit}
              >
                Yes, Re-Open period!
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ModalReOpenPeriod.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  show: PropTypes.any
}

export default ModalReOpenPeriod
