import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_ACCOUNT_NUMBER_DASHBOARD,
    GET_ACCOUNT_NUMBER_DASHBOARD_SUCCESS,
    GET_ACCOUNT_NUMBER_DASHBOARD_FAILED,
    GET_DATA_DASHBOARD,
    GET_DATA_DASHBOARD_SUCCESS,
    GET_DATA_DASHBOARD_FAILED,
} from "./actionTypes";

const INIT_STATE = {
    chartsData: [],
    accountNumberData: null,
    loadingAccountNumberData: false,
    data: null,
    loading: false,
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsData: action.payload.data
                    };
                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsDataError: action.payload.error
                    };


                default:
                    return state;
            }

        case GET_ACCOUNT_NUMBER_DASHBOARD:
            return {
                ...state,
                loadingAccountNumberData: true
            };
        case GET_ACCOUNT_NUMBER_DASHBOARD_SUCCESS:
            return {
                ...state,
                loadingAccountNumberData: false,
                accountNumberData: action.payload
            };
        case GET_ACCOUNT_NUMBER_DASHBOARD_FAILED:
            return {
                ...state,
                loadingAccountNumberData: false,
            };

        case GET_DATA_DASHBOARD:
            return {
                ...state,
                loading: true
            };
        case GET_DATA_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case GET_DATA_DASHBOARD_FAILED:
            return {
                ...state,
                loading: false,
            };


        default:
            return {
                ...state,
                loading: false,
            };
    }
}


export default Dashboard;