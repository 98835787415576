import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_LIST_ACCOUNT,
    GET_ACCOUNT,
    ADD_ACCOUNT,
    UPDATE_ACCOUNT,
    DELETE_ACCOUNT,
} from "./actionTypes"
import {
    getListAccountSuccess,
    getListAccountFail,
    getAccountByIdSuccess,
    getAccountByIdFail,
    addAccountSuccess,
    addAccountFail,
    updateAccountFail,
    deleteAccountFail,
    updateAccountSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
    getListAccount,
    getAccount,
    addAccount,
    updateAccount,
    deleteAccount
} from "../../helpers/backend_helper"

function* onFetchListAccount({ payload: filter }) {
    try {
        const response = yield call(getListAccount, filter)
        if (response.status) {
            yield put(getListAccountSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getListAccountFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getListAccountFail(error))
    }
}

function* onGetccountById({ payload: { id, companyId } }) {
    try {
        const response = yield call(getAccount, { id: id, companyId : companyId })
        if (response.status) {
            yield put(getAccountByIdSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getAccountByIdFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getAccountByIdFail(error))
    }
}

function* onAddAccount({ payload: { account, history } }) {
    try {
        const response = yield call(addAccount, account)
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_ADD_SUCCESS)
            yield put(addAccountSuccess(response.data))
            history.push("/masterdata/account")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addAccountFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addAccountFail(error))
    }
}

function* onUpdateAccount({ payload: { account, history } }) {
    try {
        const response = yield call(updateAccount, account)
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_UPDATE_SUCCESS)
            yield put(updateAccountSuccess())
            history.push("/masterdata/account")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updateAccountFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updateAccountFail(error))
    }
}

function* onDeleteAccount({ payload: { account, filter } }) {
    try {
        const response = yield call(deleteAccount, account)
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_DELETE_SUCCESS)
            const responseNewData = yield call(getListAccount, filter)
            if (responseNewData.status) {
                yield put(getListAccountSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getListAccountFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deleteAccountFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deleteAccountFail(error))
    }
}

function* accountSaga() {
    yield takeEvery(GET_LIST_ACCOUNT, onFetchListAccount)
    yield takeEvery(GET_ACCOUNT, onGetccountById)
    yield takeEvery(ADD_ACCOUNT, onAddAccount)
    yield takeEvery(UPDATE_ACCOUNT, onUpdateAccount)
    yield takeEvery(DELETE_ACCOUNT, onDeleteAccount)
}

export default accountSaga