import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_PERIODS,
    ADD_PERIOD,
    UPDATE_PERIOD,
    DELETE_PERIOD,
    GET_PERIOD,
    CLOSE_PERIOD,
    REOPEN_PERIOD,
    GET_TEMPLATE_BEGINNING_BALANCE,
    SUBMIT_BEGINNING_BALANCE
} from "./actionTypes"
import {
    getPeriodsSuccess,
    getPeriodsFail,
    addPeriodFail,
    updatePeriodFail,
    deletePeriodFail,
    getPeriodSuccess,
    getPeriodFail,
    updatePeriodSuccess,
    addPeriodSuccess,
    closePeriodSuccess,
    closePeriodFail,
    reOpenPeriodSuccess,
    reOpenPeriodFail,
    getTemplateBeginningBalanceSuccess,
    getTemplateBeginningBalanceFail,
    submitBeginningBalanceSuccess,
    submitBeginningBalanceFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getPeriods,
    getPeriod,
    addPeriod,
    updatePeriod,
    deletePeriod,
    closePeriod,
    reOpenPeriod,
    getTemplateBeginningBalance,
    submitBeginningBalance
} from "../../helpers/backend_helper"
import fileDownload from 'js-file-download'

function* fetchPeriods({ payload: filter }) {
    try {
        const response = yield call(getPeriods, filter)
        if (response.status) {
            yield put(getPeriodsSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getPeriodsFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getPeriodsFail(error))
    }
}

function* getPeriodById({ payload: param }) {
    try {
        const response = yield call(getPeriod, param)
        if (response.status) {
            yield put(getPeriodSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getPeriodFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getPeriodFail(error))
    }
}

function* addNewPeriod({ payload: { period, history } }) {
    try {
        const response = yield call(addPeriod, period)
        if (response.status) {
            NotifHelper.success(msg.PERIOD_ADD_SUCCESS)
            yield put(addPeriodSuccess())
            history.push("/masterdata/period")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addPeriodFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addPeriodFail(error))
    }
}

function* onUpdatePeriod({ payload: { period, history } }) {
    try {
        const response = yield call(updatePeriod, period)
        if (response.status) {
            NotifHelper.success(msg.PERIOD_UPDATE_SUCCESS)
            yield put(updatePeriodSuccess())
            history.push("/masterdata/period")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updatePeriodFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updatePeriodFail(error))
    }
}

function* onDeletePeriod({ payload: { period, filter } }) {
    try {
        const response = yield call(deletePeriod, period)
        if (response.status) {
            NotifHelper.success(msg.PERIOD_DELETE_SUCCESS)
            const responseNewData = yield call(getPeriods, filter)
            if (responseNewData.status) {
                yield put(getPeriodsSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getPeriodsFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deletePeriodFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deletePeriodFail(error))
    }
}

function* onClosePeriod({ payload: { period, filter } }) {
    try {
        const response = yield call(closePeriod, period)
        if (response.status) {
            NotifHelper.success(msg.PERIOD_CLOSED_SUCCESS)
            yield put(closePeriodSuccess())
            const responseNewData = yield call(getPeriods, filter)
            if (responseNewData.status) {
                yield put(getPeriodsSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getPeriodsFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(closePeriodFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(closePeriodFail(error))
    }
}

function* onReOpenPeriod({ payload: { period, filter } }) {
    try {
        const response = yield call(reOpenPeriod, period)
        if (response.status) {
            NotifHelper.success(msg.REOPEN_PERIOD_SUCCESS)
            yield put(reOpenPeriodSuccess())
            const responseNewData = yield call(getPeriods, filter)
            if (responseNewData.status) {
                yield put(getPeriodsSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getPeriodsFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(reOpenPeriodFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(reOpenPeriodFail(error))
    }
}

function* onGetTemplateBeginningBalance({ payload: { filter } }) {
    try {
        const response = yield call(getTemplateBeginningBalance, filter, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        let endFileNameIndex = headerLine.lastIndexOf(';');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        fileDownload(response.data, filename)
        yield put(getTemplateBeginningBalanceSuccess())
    } catch (error) {
        NotifHelper.error(error)
        yield put(getTemplateBeginningBalanceFail(error))
    }
}

function* onSubmitBeginningBalance({ payload: { uploadFile, companyId, periodId, history } }) {
    try {
        const response = yield call(submitBeginningBalance, uploadFile, {
            companyId: companyId,
            periodId: periodId
        })
        if (response.status) {
            NotifHelper.success(msg.SUBMIT_BEGINNING_BALANCE_SUCCESS)
            yield put(submitBeginningBalanceSuccess())
        }
        else {
            NotifHelper.error(response.message)
            yield put(submitBeginningBalanceFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(submitBeginningBalanceFail(error))
    }
}

function* periodSaga() {
    yield takeEvery(GET_PERIODS, fetchPeriods)
    yield takeEvery(GET_PERIOD, getPeriodById)
    yield takeEvery(ADD_PERIOD, addNewPeriod)
    yield takeEvery(UPDATE_PERIOD, onUpdatePeriod)
    yield takeEvery(DELETE_PERIOD, onDeletePeriod)
    yield takeEvery(CLOSE_PERIOD, onClosePeriod)
    yield takeEvery(REOPEN_PERIOD, onReOpenPeriod)
    yield takeEvery(GET_TEMPLATE_BEGINNING_BALANCE, onGetTemplateBeginningBalance)
    yield takeEvery(SUBMIT_BEGINNING_BALANCE, onSubmitBeginningBalance)
}

export default periodSaga