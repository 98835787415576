/* PERIOD */
export const GET_LIST_ASSET_CATEGORY = "GET_LIST_ASSET_CATEGORY";
export const GET_LIST_ASSET_CATEGORY_SUCCESS = "GET_LIST_ASSET_CATEGORY_SUCCESS";
export const GET_LIST_ASSET_CATEGORY_FAIL = "GET_LIST_ASSET_CATEGORY_FAIL";

export const GET_ASSET_CATEGORY = "GET_ASSET_CATEGORY";
export const GET_ASSET_CATEGORY_SUCCESS = "GET_ASSET_CATEGORY_SUCCESS";
export const GET_ASSET_CATEGORY_FAIL = "GET_ASSET_CATEGORY_FAIL";

export const ADD_ASSET_CATEGORY = "ADD_ASSET_CATEGORY";
export const ADD_ASSET_CATEGORY_SUCCESS = "ADD_ASSET_CATEGORY_SUCCESS";
export const ADD_ASSET_CATEGORY_FAIL = "ADD_ASSET_CATEGORY_FAIL";

export const UPDATE_ASSET_CATEGORY = "UPDATE_ASSET_CATEGORY";
export const UPDATE_ASSET_CATEGORY_SUCCESS = "UPDATE_ASSET_CATEGORY_SUCCESS";
export const UPDATE_ASSET_CATEGORY_FAIL = "UPDATE_ASSET_CATEGORY_FAIL";

export const DELETE_ASSET_CATEGORY = "DELETE_ASSET_CATEGORY";
export const DELETE_ASSET_CATEGORY_SUCCESS = "DELETE_ASSET_CATEGORY_SUCCESS";
export const DELETE_ASSET_CATEGORY_FAIL = "DELETE_ASSET_CATEGORY_FAIL";

export const CLEAR_LIST_ASSET_CATEGORY = "CLEAR_LIST_ASSET_CATEGORY";
