import {
  GET_DAFTAR_PIUTANG,
  GET_DAFTAR_PIUTANG_SUCCESS,
  GET_DAFTAR_PIUTANG_FAIL,
  GET_PIUTANG,
  GET_PIUTANG_SUCCESS,
  GET_PIUTANG_FAIL,
  ADD_PIUTANG,
  ADD_PIUTANG_FAIL,
  UPDATE_PIUTANG,
  UPDATE_PIUTANG_FAIL,
  DELETE_PIUTANG,
  DELETE_PIUTANG_FAIL,
  ADD_PIUTANG_PAYMENT,
  ADD_PIUTANG_PAYMENT_SUCCESS,
  ADD_PIUTANG_PAYMENT_FAIL,
  DELETE_PIUTANG_PAYMENT,
  DELETE_PIUTANG_PAYMENT_SUCCESS,
  DELETE_PIUTANG_PAYMENT_FAIL,
  ADD_PIUTANG_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  daftarPiutang: [],
  piutang: null,
  error: '',
  loading: false,
  loadingPayment: false
};

const Piutang = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DAFTAR_PIUTANG:
      return {
        ...state,
        loading: true,
      };
    case GET_DAFTAR_PIUTANG_SUCCESS:
      return {
        ...state,
        daftarPiutang: action.payload,
        loading: false,
      };
    case GET_DAFTAR_PIUTANG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_PIUTANG:
      return {
        ...state,
        loading: true,
      };
    case GET_PIUTANG_SUCCESS:
      return {
        ...state,
        piutang: action.payload,
        loading: false,
      };
    case GET_PIUTANG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_PIUTANG:
      return {
        ...state,
        loading: true,
      };
    case ADD_PIUTANG_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PIUTANG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_PIUTANG:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PIUTANG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_PIUTANG:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PIUTANG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_PIUTANG_PAYMENT:
      return {
        ...state,
        loadingPayment: true,
      };
    case ADD_PIUTANG_PAYMENT_SUCCESS:
      return {
        ...state,
        loadingPayment: false,
      };
    case ADD_PIUTANG_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingPayment: false,
      };

    case DELETE_PIUTANG_PAYMENT:
      return {
        ...state,
        loadingPayment: true,
      };
    case DELETE_PIUTANG_PAYMENT_SUCCESS:
      return {
        ...state,
        loadingPayment: false
      };
    case DELETE_PIUTANG_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingPayment: false,
      };

    default:
      return state;
  }
};

export default Piutang;
