import React, { useState, useMemo, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import {
  Container,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import TableContainer from '../../components/Common/TableContainer';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  deleteCompany as onDeleteCompany,
  getCompanies as onGetCompanies,
  importAccount as onImportAccount,
  uploadTemplate as onUploadTemplate,
  uploadTemplateProfitLoss as onUploadTemplateProfitLoss,
  exportAccountList as onExportAccountList
} from "../../store/actions";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

import DeleteModal from "./modalDelete";
import ModalImportAccount from "../../components/Common/ModalImportAccount";
import ModalUploadTemplate from "../../components/Common/ModalUploadTemplate";

const Company = () => {

  //meta title
  document.title = "Company | Des Counts";

  const dispatch = useDispatch();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenImportAccount, setIsOpenImportAccount] = useState(false);
  const [isOpenUploadTemplate, setIsOpenUploadTemplate] = useState(false);
  const [isOpenUploadTemplateProfitLoss, setIsOpenUploadTemplateProfitLoss] = useState(false);
  const [company, setCompany] = useState({});
  const filterName = useRef('');

  const { companies, loading } = useSelector(state => ({
    companies: state.Company.companies,
    loading: state.Company.loading,
  }));

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value;
        }
      },
      {
        Header: 'Template Report Neraca',
        accessor: 'reportTemplate',
        disableFilters: true,
        Cell: (cellProps) => {
          return !cellProps.value || cellProps.value == '' ? '[Not Set]' : cellProps.value;
        }
      },
      {
        Header: 'Template Report ProfitLoss',
        accessor: 'reportTemplateProfitLoss',
        disableFilters: true,
        Cell: (cellProps) => {
          return !cellProps.value || cellProps.value == '' ? '[Not Set]' : cellProps.value;
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        headerCellStyle: 'text-center',
        style: {
          textAlign: "center",
        },
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-2 justify-content-center">
              <Link
                to={"/masterdata/company-create-update/" + cellProps.row.original.id}
                className="btn btn-sm btn-success">
                Edit
              </Link>
              <Link
                to="#"
                className="btn btn-sm btn-info"
                onClick={() => {
                  const data = cellProps.row.original;
                  dispatch(onExportAccountList({ companyId: data.id }))
                }}>
                Download Account's List
              </Link>
              <Link
                to="#"
                className="btn btn-sm btn-primary"
                onClick={() => {
                  const data = cellProps.row.original;
                  setCompany(data);
                  setIsOpenImportAccount(true);
                }}>
                Import Account
              </Link>
              <Link
                to="#"
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  const data = cellProps.row.original;
                  setCompany(data);
                  setIsOpenUploadTemplate(true);
                }}>
                Upload Template Neraca
              </Link>
              <Link
                to="#"
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  const data = cellProps.row.original;
                  setCompany(data);
                  setIsOpenUploadTemplateProfitLoss(true);
                }}>
                Upload Template ProfitLoss
              </Link>
              <Link
                to="#"
                className="btn btn-sm btn-danger"
                onClick={() => {
                  const data = cellProps.row.original;
                  onOpenDeleteCommand(data);
                }}>
                Delete
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const handleOrderClicks = () => {
  }

  const onRefreshCommand = () => {
    dispatch(onGetCompanies({ name: filterName.current.value }));
  }

  const onResetFilterCommand = () => {
    filterName.current.value = '';
  }

  const onOpenDeleteCommand = (data) => {
    setCompany(data)
    setIsOpenDelete(true);
  }

  const onDeleteCommand = () => {
    setIsOpenDelete(false)
    dispatch(onDeleteCompany(company))
  }

  const onSubmitImportAccount = (type) => {
    setIsOpenImportAccount(false)
    dispatch(onImportAccount({ companyId: company.id, companyType: type }))
  }

  const onSubmitUploadTemplate = (fileUpload) => {
    setIsOpenUploadTemplate(false)
    const data = new FormData()
    data.append('files', fileUpload)
    dispatch(onUploadTemplate(data, company.id, { name: filterName.current.value }))
  }

  const onSubmitUploadTemplateProfitLoss = (fileUpload) => {
    setIsOpenUploadTemplateProfitLoss(false)
    const data = new FormData()
    data.append('files', fileUpload)
    dispatch(onUploadTemplateProfitLoss(data, company.id, { name: filterName.current.value }))
  }

  useEffect(() => {
    onRefreshCommand();
  }, [dispatch]);

  return (
    <React.Fragment>
      <DeleteModal show={isOpenDelete} onDeleteClick={onDeleteCommand} onCloseClick={() => setIsOpenDelete(false)} />
      <ModalImportAccount show={isOpenImportAccount} onSubmit={onSubmitImportAccount} onCancel={() => setIsOpenImportAccount(false)} company={company} />
      <ModalUploadTemplate show={isOpenUploadTemplate} onSubmit={onSubmitUploadTemplate} onCancel={() => setIsOpenUploadTemplate(false)} company={company} />
      <ModalUploadTemplate show={isOpenUploadTemplateProfitLoss} onSubmit={onSubmitUploadTemplateProfitLoss} onCancel={() => setIsOpenUploadTemplateProfitLoss(false)} company={company} />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Master Data" breadcrumbItem="Company" />
          <Row className="mb-3">
            <Col xs="12">
              <Card>
                <CardHeader>
                  <CardTitle>Filter</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                  <Row>
                    <Col>
                      <label className="col-md-2 col-form-label">
                        Name
                      </label>
                      <div className="col-md-12">
                        <input className="form-control" type="text" ref={filterName} />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="justify-content-end">
                      <Button color="primary" className="btn btn-primary waves-effect waves-light  float-end" style={{ minWidth: '200px' }}
                        disabled={loading}
                        onClick={onRefreshCommand}>
                        <i className="bx bx-filter-alt"></i>
                      </Button>
                      <Button color="info" className="btn btn-info waves-effect waves-light me-2 float-end" style={{ minWidth: '100px' }}
                        disabled={loading}
                        onClick={onResetFilterCommand}>
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    !loading &&
                    <>
                      <div className="d-flex justify-content-end mb-2">
                        <Link to="/masterdata/company-create-update">
                          <Button color="success" className="btn btn-success waves-effect waves-light">
                            Add Company
                          </Button>
                        </Link>
                      </div>
                      <TableContainer
                        columns={columns}
                        data={companies ? companies : []}
                        isGlobalFilter={false}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                      />
                    </>
                  }
                  {
                    loading &&
                    <div className="d-flex justify-content-center my-5">
                      <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Company.propTypes = {
  companies: PropTypes.array,
  className: PropTypes.string,
  onGetCompanies: PropTypes.func,
  onAddNewCompany: PropTypes.func,
  onUpdateCompany: PropTypes.func,
  onDeleteCompany: PropTypes.func,
  preGlobalFilteredRows: PropTypes.any,
};

export default Company
