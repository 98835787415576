export const POST_LOGIN = "/auth/authenticate";
export const CHECK_LOGIN = "/auth/check-login";
export const GET_ACCESS = "/auth/get-access";
export const CLAIM_ACCESS = "/auth/claim";

//DASHBOARD
export const GET_ACCOUNT_NUMBER_DASHBOARD = "/dasbor/get";
export const GET_DATA_DASHBOARD = "/dasbor/get-data";

//COMPANY
export const GET_COMPANIES = "/company/get";
export const GET_COMPANY = "/company/get-by-id";
export const ADD_COMPANY = "/company/add";
export const UPDATE_COMPANY = "/company/update";
export const DELETE_COMPANY = "/company/delete";

//IMPORT ACCOUNT COMPANY
export const IMPORT_ACCOUNT_COMPANY = "/company/import-account-code";

//UPLOAD TEMPLATE REPORT
export const UPLOAD_TEMPLATE_REPORT = "/company/upload-report-template";

//UPLOAD TEMPLATE REPORT PROFITLOSS
export const UPLOAD_TEMPLATE_REPORT_PROFITLOSS = "/company/upload-report-template-profitloss";

//UPLOAD TEMPLATE REPORT
export const EXPORT_ACCOUNT_LIST = "/company/get-account-code-list";

//PERIOD
export const GET_PERIODS = "/period/get";
export const GET_PERIOD = "/period/get-by-id";
export const ADD_PERIOD = "/period/add";
export const UPDATE_PERIOD = "/period/update";
export const DELETE_PERIOD = "/period/delete";
export const CLOSE_PERIOD = "/period/close";
export const REOPEN_PERIOD = "/period/reopen";
export const GET_TEMPLATE_BEGINNING_BALANCE = "/period/get-excel-beginning-balance";
export const SUBMIT_BEGINNING_BALANCE = "/period/import-beginning-balance";

//ACCOUNT
export const GET_LIST_ACCOUNT = "/account/get";
export const GET_ACCOUNT_BY_ID = "/account/get-by-id";
export const ADD_ACCOUNT = "/account/add";
export const UPDATE_ACCOUNT = "/account/update";
export const DELETE_ACCOUNT = "/account/delete";

//ASSET CATEGORY
export const GET_LIST_ASSET_CATEGORY = "/asset-category/get";
export const GET_ASSET_CATEGORY_BY_ID = "/asset-category/get-by-id";
export const ADD_ASSET_CATEGORY = "/asset-category/add";
export const UPDATE_ASSET_CATEGORY = "/asset-category/update";
export const DELETE_ASSET_CATEGORY = "/asset-category/delete";

//ASSET TYPE
export const GET_LIST_ASSET_TYPE = "/asset-type/get";
export const GET_ASSET_TYPE_BY_ID = "/asset-type/get-by-id";
export const ADD_ASSET_TYPE = "/asset-type/add";
export const UPDATE_ASSET_TYPE = "/asset-type/update";
export const DELETE_ASSET_TYPE = "/asset-type/delete";

//ASSET
export const GET_LIST_ASSET = "/asset/get";
export const GET_ASSET_BY_ID = "/asset/get-by-id";
export const ADD_ASSET = "/asset/add";
export const UPDATE_ASSET = "/asset/update";
export const DELETE_ASSET = "/asset/delete";
export const GET_EXCEL_ASSET = "/asset/get-excel";
export const GET_TEMPLATE_EXCEL_ASSET = "/asset/get-excel-template";
export const SUBMIT_IMPORT_EXCEL_ASSET = "/asset/add-by-excel";

//ACCOUNT_NUMBER
export const GET_LIST_ACCOUNT_NUMBER = "/accountnumber/get";
export const GET_ACCOUNT_NUMBER_BY_ID = "/accountnumber/get-by-id";
export const ADD_ACCOUNT_NUMBER = "/accountnumber/add";
export const UPDATE_ACCOUNT_NUMBER = "/accountnumber/update";
export const DELETE_ACCOUNT_NUMBER = "/accountnumber/delete";

//PIUTANG
export const GET_DAFTAR_PIUTANG = "/piutang/get";
export const GET_PIUTANG = "/piutang/get-by-id";
export const ADD_PIUTANG = "/piutang/add";
export const UPDATE_PIUTANG = "/piutang/update";
export const DELETE_PIUTANG = "/piutang/delete";
export const ADD_PIUTANG_PAYMENT = "/piutangbayar/add";
export const DELETE_PIUTANG_PAYMENT = "/piutangbayar/delete";

//ACCOUNT TRANSACTION
export const GET_LIST_ACCOUNT_TRANSACTION= "/accounttransaction/get";
export const GET_ACCOUNT_TRANSACTION= "/accounttransaction/get-by-id";
export const ADD_ACCOUNT_TRANSACTION = "/accounttransaction/add";
export const UPDATE_ACCOUNT_TRANSACTION = "/accounttransaction/update";
export const DELETE_ACCOUNT_TRANSACTION = "/accounttransaction/delete";
export const ADD_ACCOUNT_TRANSACTION_BY_EXCEL = "/accounttransaction/add-by-excel";
export const EXPORT_EXCEL_ACCOUNT_TRANSACTION = "/accounttransaction/get-excel";

//WORKSHEET
export const GET_LIST_WORKSHEET = "/worksheet/get";
export const GET_EXCEL_WORKSHEET = "/worksheet/get-excel";
export const BALANCING_WORKSHEET = "/worksheet/update-balancing";

//ROLE
export const GET_LIST_ROLE = "/role/get";
export const GET_ROLE = "/role/get-by-id";
export const ADD_ROLE = "/role/add";
export const UPDATE_ROLE = "/role/update";
export const DELETE_ROLE = "/role/delete";

//USER
export const GET_LIST_USER = "/user/get";
export const GET_USER = "/user/get-by-id";
export const ADD_USER = "/user/add";
export const UPDATE_USER = "/user/update";
export const DELETE_USER = "/user/delete";
export const RESET_PASSWORD_USER = "/user/reset-password";
export const CHANGE_PASSWORD_USER = "/user/change-password";

//REPORT
export const GET_REPORT_EXCEL = "/report/get-excel";
export const GET_REPORT_PDF = "/report/get-pdf";
export const GET_REPORT_PROFITLOSS_EXCEL = "/report-profitloss/get-excel";
export const GET_REPORT_PROFITLOSS_PDF = "/report-profitloss/get-pdf";

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

// JOBS
export const GET_JOB_LIST = "/jobs";
export const ADD_NEW_JOB_LIST = "/add/job";
export const UPDATE_JOB_LIST = "/update/job";
export const DELETE_JOB_LIST = "/delete/job";

//Apply Jobs
export const GET_APPLY_JOB = "/jobApply";
export const DELETE_APPLY_JOB = "add/applyjob";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
// export const UPDATE_USER = "/update/user";
// export const DELETE_USER = "/delete/user";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

export const GET_EARNING_DATA = "/earning-charts-data";

export const GET_PRODUCT_COMMENTS = "/comments-product";

export const ON_LIKNE_COMMENT = "/comments-product-action";

export const ON_ADD_REPLY = "/comments-product-add-reply";

export const ON_ADD_COMMENT = "/comments-product-add-comment";
