/* PERIOD */
export const GET_PERIODS = "GET_PERIODS";
export const GET_PERIODS_SUCCESS = "GET_PERIODS_SUCCESS";
export const GET_PERIODS_FAIL = "GET_PERIODS_FAIL";

export const GET_PERIOD = "GET_PERIOD";
export const GET_PERIOD_SUCCESS = "GET_PERIOD_SUCCESS";
export const GET_PERIOD_FAIL = "GET_PERIOD_FAIL";

export const ADD_PERIOD = "ADD_PERIOD";
export const ADD_PERIOD_SUCCESS = "ADD_PERIOD_SUCCESS";
export const ADD_PERIOD_FAIL = "ADD_PERIOD_FAIL";

export const UPDATE_PERIOD = "UPDATE_PERIOD";
export const UPDATE_PERIOD_SUCCESS = "UPDATE_PERIOD_SUCCESS";
export const UPDATE_PERIOD_FAIL = "UPDATE_PERIOD_FAIL";

export const DELETE_PERIOD = "DELETE_PERIOD";
export const DELETE_PERIOD_FAIL = "DELETE_PERIOD_FAIL";

export const CLEAR_PERIODS = "CLEAR_PERIODS";

export const CLOSE_PERIOD = "CLOSE_PERIOD";
export const CLOSE_PERIOD_SUCCESS = "CLOSE_PERIOD_SUCCESS";
export const CLOSE_PERIOD_FAIL = "CLOSE_PERIOD_FAIL";

export const REOPEN_PERIOD = "REOPEN_PERIOD";
export const REOPEN_PERIOD_SUCCESS = "REOPEN_PERIOD_SUCCESS";
export const REOPEN_PERIOD_FAIL = "REOPEN_PERIOD_FAIL";

export const GET_TEMPLATE_BEGINNING_BALANCE = "GET_TEMPLATE_BEGINNING_BALANCE";
export const GET_TEMPLATE_BEGINNING_BALANCE_SUCCESS = "GET_TEMPLATE_BEGINNING_BALANCE_SUCCESS";
export const GET_TEMPLATE_BEGINNING_BALANCE_FAIL = "GET_TEMPLATE_BEGINNING_BALANCE_FAIL";

export const SUBMIT_BEGINNING_BALANCE = "SUBMIT_BEGINNING_BALANCE";
export const SUBMIT_BEGINNING_BALANCE_SUCCESS = "SUBMIT_BEGINNING_BALANCE_SUCCESS";
export const SUBMIT_BEGINNING_BALANCE_FAIL = "SUBMIT_BEGINNING_BALANCE_FAIL";
