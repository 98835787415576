import SweetAlert from 'sweetalert2'

export const SuccessAlert = (title, message) => {
    SweetAlert.fire({
        titleText: title,
        icon: 'success',
        text: message,
        backdrop: true,
        confirmButtonColor: '#34c38f',
        allowOutsideClick: false
    })
}