import {
  GET_LIST_ASSET,
  GET_LIST_ASSET_SUCCESS,
  GET_LIST_ASSET_FAIL,
  GET_ASSET,
  GET_ASSET_SUCCESS,
  GET_ASSET_FAIL,
  ADD_ASSET,
  ADD_ASSET_SUCCESS,
  ADD_ASSET_FAIL,
  UPDATE_ASSET,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAIL,
  DELETE_ASSET,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAIL,
  EXPORT_EXCEL_ASSET,
  EXPORT_EXCEL_ASSET_SUCCESS,
  EXPORT_EXCEL_ASSET_FAILED,
  GET_TEMPLATE_EXCEL_ASSET,
  GET_TEMPLATE_EXCEL_ASSET_SUCCESS,
  GET_TEMPLATE_EXCEL_ASSET_FAILED,
  SUBMIT_IMPORT_EXCEL_ASSET,
  SUBMIT_IMPORT_EXCEL_ASSET_SUCCESS,
  SUBMIT_IMPORT_EXCEL_ASSET_FAILED,
} from "./actionTypes";

const INIT_STATE = {
  listAsset: [],
  asset: null,
  error: '',
  loading: false
};

const Asset = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_ASSET:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_ASSET_SUCCESS:
      return {
        ...state,
        listAsset: action.payload,
        loading: false,
      };
    case GET_LIST_ASSET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ASSET:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSET_SUCCESS:
      return {
        ...state,
        asset: action.payload,
        loading: false,
      };
    case GET_ASSET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_ASSET:
      return {
        ...state,
        loading: true,
      };
    case ADD_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ASSET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_ASSET:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ASSET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_ASSET:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ASSET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case EXPORT_EXCEL_ASSET:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_EXCEL_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EXPORT_EXCEL_ASSET_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_TEMPLATE_EXCEL_ASSET:
      return {
        ...state,
        loading: true,
      };
    case GET_TEMPLATE_EXCEL_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_TEMPLATE_EXCEL_ASSET_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case SUBMIT_IMPORT_EXCEL_ASSET:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_IMPORT_EXCEL_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SUBMIT_IMPORT_EXCEL_ASSET_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Asset;
