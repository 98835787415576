import React, { useState, useMemo, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Label,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import Select from "react-select";
import TableContainer from '../../components/Common/TableContainer';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanies as onGetCompanies,
    getListAssetCategory as onGetAssetCategorys,
    deleteAssetCategory as onDeleteAssetCategory
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import DeleteModal from "../../components/Common/ModalDelete";


const AssetCategory = () => {

    //meta title
    document.title = "Asset Category | Master Data | Des Counts";

    const dispatch = useDispatch();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [assetCategory, setAssetCategory] = useState({});

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }

    const filterName = useRef("");
    const filterCompany = useRef("");

    const { companies, loadingCompany } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
    }));

    const { listAssetCategory, loading } = useSelector(state => ({
        listAssetCategory: state.AssetCategory.listAssetCategory,
        loading: state.AssetCategory.loading,
    }));

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                headerCellStyle: 'text-center',
                style: {
                    textAlign: "center",
                },
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <Link
                                to={"/masterdata/asset-category-create-update/" + cellProps.row.original.companyId + "/" + cellProps.row.original.id}
                                className="text-success"
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    onOpenDeleteCommand(data);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    const handleOrderClicks = () => {
    }

    const onRefreshCommand = () => {
        if (selectedCompany) {
            dispatch(onGetAssetCategorys({
                companyId: selectedCompany ? selectedCompany.value : null,
                name: filterName.current.value,
            }));
        }
    }

    const onResetFilterCommand = () => {
        filterName.current.value = '';
        filterCompany.current.clearValue();
    }

    const onOpenDeleteCommand = (data) => {
        setAssetCategory(data)
        setIsOpenDelete(true);
    }

    const onDeleteCommand = () => {
        setIsOpenDelete(false)
        dispatch(onDeleteAssetCategory(assetCategory, {
            companyId: selectedCompany ? selectedCompany.value : null,
            name: filterName.current.value,
        }))
    }

    useEffect(() => {
        onRefreshCommand();
    }, [dispatch]);

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])

    useEffect(() => {
        onRefreshCommand()
    }, [selectedCompany])

    useEffect(() => {
        dispatch(onGetCompanies({}));
    }, [])

    return (
        <React.Fragment>
            <DeleteModal show={isOpenDelete} onDeleteClick={onDeleteCommand} onCloseClick={() => setIsOpenDelete(false)} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Master Data" breadcrumbItem="Asset Category" />
                    <Row className="mb-3">
                        <Col xs="12">
                            <Card>
                                <CardBody className="pt-0">
                                    <CardTitle className="my-3">Filter</CardTitle>
                                    <Row>
                                        <Col className="col-12 col-sm-6">
                                            <div>
                                                <Label>Company</Label>
                                                <Select
                                                    value={selectedCompany}
                                                    onChange={handleSelectCompany}
                                                    options={optionCompany}
                                                    isLoading={loadingCompany}
                                                    isClearable={true}
                                                    ref={filterCompany}
                                                    className="select2-selection"
                                                    menuPosition="fixed"
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-6">
                                            <div>
                                                <div>
                                                    <Label>Name</Label>
                                                    <input className="form-control" type="text" ref={filterName} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="justify-content-end">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light  float-end" style={{ minWidth: '200px' }}
                                                disabled={loading}
                                                onClick={onRefreshCommand}>
                                                <i className="bx bx-filter-alt"></i>
                                            </Button>
                                            <Button color="info" className="btn btn-info waves-effect waves-light me-2 float-end" style={{ minWidth: '100px' }}
                                                disabled={loading}
                                                onClick={onResetFilterCommand}>
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Link to="/masterdata/asset-category-create-update">
                                            <Button color="success" className="btn btn-success waves-effect waves-light">
                                                Add Category
                                            </Button>
                                        </Link>
                                    </div>
                                    {
                                        !loading && selectedCompany &&
                                        <>
                                            <TableContainer
                                                columns={columns}
                                                data={listAssetCategory}
                                                isGlobalFilter={false}
                                                isAddOptions={true}
                                                handleOrderClicks={handleOrderClicks}
                                                customPageSize={10}
                                            />
                                        </>
                                    }
                                    {
                                        !loading && !selectedCompany &&
                                        <div className="d-flex justify-content-center my-5">
                                            <p>Please select company.</p>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

AssetCategory.propTypes = {
    listAssetCategory: PropTypes.array,
    className: PropTypes.string,
    onGetCompanies: PropTypes.func,
    onGetAssetCategorys: PropTypes.func,
    onDeleteAssetCategory: PropTypes.func,
    preGlobalFilteredRows: PropTypes.any,
};

export default AssetCategory
