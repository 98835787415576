import { del, get, getFile, post, put, postFile, getFilePost } from "./api_helper";
import * as url from "./url_helper";
import jwt_decode from "jwt-decode";

//is user is logged in
const IsUserAuthenticated = (param) => {
    

    if (localStorage.getItem("AccessToken") == null) {
        return false;
    }
    const token = localStorage.getItem('AccessToken');
    var decodedToken = jwt_decode(token, { complete: true });
    var dateNow = new Date();
    if (param != undefined && param != null && param.length > 0) {
        // get(url.CLAIM_ACCESS, { accessId: param[0].id }).then(s => {
        //     if (!s.status) {
        //         // window.location.href = "/login"
        //     }
        // });
    }
    
    if (decodedToken.exp >= (dateNow.getTime() / 1000)) {
        return true;
    }
    else {
        return false;
    }
};

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);
const loginCheck = data => get(url.CHECK_LOGIN, data);
export const getAccessData = data => get(url.GET_ACCESS, data);

//Dashboard
export const getAccountNumberDashboard = filter => get(url.GET_ACCOUNT_NUMBER_DASHBOARD, filter);
export const getDashboardData = filter => get(url.GET_DATA_DASHBOARD, filter);

//Company
export const getCompanies = filter => get(url.GET_COMPANIES, filter);
export const getCompany = id => get(url.GET_COMPANY, id);
export const addCompany = company => post(url.ADD_COMPANY, company);
export const updateCompany = company => put(url.UPDATE_COMPANY, company);
export const deleteCompany = param => del(url.DELETE_COMPANY, param);

//Import Account
export const importAccountCompany = data => get(url.IMPORT_ACCOUNT_COMPANY, data);
//Upload Template
export const uploadTemplateReport = (data, param) => postFile(url.UPLOAD_TEMPLATE_REPORT, data, param);
//Upload Template ProfitLoss
export const uploadTemplateReportProfitLoss = (data, param) => postFile(url.UPLOAD_TEMPLATE_REPORT_PROFITLOSS, data, param);
//Export Account List
export const exportAccountList = (filter, config) => getFile(url.EXPORT_ACCOUNT_LIST, filter, config);


//Period
export const getPeriods = filter => get(url.GET_PERIODS, filter);
export const getPeriod = param => get(url.GET_PERIOD, param);
export const addPeriod = data => post(url.ADD_PERIOD, data);
export const updatePeriod = data => put(url.UPDATE_PERIOD, data);
export const deletePeriod = param => del(url.DELETE_PERIOD, param);
export const closePeriod = data => post(url.CLOSE_PERIOD, data);
export const reOpenPeriod = data => post(url.REOPEN_PERIOD, data);

export const getTemplateBeginningBalance = (filter, config) => getFilePost(url.GET_TEMPLATE_BEGINNING_BALANCE, filter, config);
export const submitBeginningBalance = (data, param) => postFile(url.SUBMIT_BEGINNING_BALANCE, data, param);

//Account
export const getListAccount = filter => get(url.GET_LIST_ACCOUNT, filter);
export const getAccount = param => get(url.GET_ACCOUNT_BY_ID, param);
export const addAccount = data => post(url.ADD_ACCOUNT, data);
export const updateAccount = data => put(url.UPDATE_ACCOUNT, data);
export const deleteAccount = param => del(url.DELETE_ACCOUNT, param);

//Asset Category
export const getListAssetCategory = filter => get(url.GET_LIST_ASSET_CATEGORY, filter);
export const getAssetCategory = param => get(url.GET_ASSET_CATEGORY_BY_ID, param);
export const addAssetCategory = data => post(url.ADD_ASSET_CATEGORY, data);
export const updateAssetCategory = data => put(url.UPDATE_ASSET_CATEGORY, data);
export const deleteAssetCategory = param => del(url.DELETE_ASSET_CATEGORY, param);

//Asset Type
export const getListAssetType = filter => get(url.GET_LIST_ASSET_TYPE, filter);
export const getAssetType = param => get(url.GET_ASSET_TYPE_BY_ID, param);
export const addAssetType = data => post(url.ADD_ASSET_TYPE, data);
export const updateAssetType = data => put(url.UPDATE_ASSET_TYPE, data);
export const deleteAssetType = param => del(url.DELETE_ASSET_TYPE, param);

//Asset
export const getListAsset = filter => get(url.GET_LIST_ASSET, filter);
export const getAssetById = param => get(url.GET_ASSET_BY_ID, param);
export const addAsset = data => post(url.ADD_ASSET, data);
export const updateAsset = data => put(url.UPDATE_ASSET, data);
export const deleteAsset = param => del(url.DELETE_ASSET, param);
export const exportExcelAsset = (filter, config) => getFile(url.GET_EXCEL_ASSET, filter, config);
export const getTemplateExcelAsset = (filter, config) => getFilePost(url.GET_TEMPLATE_EXCEL_ASSET, filter, config);
export const submitImportExcelAsset = (data, param) => postFile(url.SUBMIT_IMPORT_EXCEL_ASSET, data, param);

//AccountNumber
export const getListAccountNumber = filter => get(url.GET_LIST_ACCOUNT_NUMBER, filter);
export const getAccountNumber = param => get(url.GET_ACCOUNT_NUMBER_BY_ID, param);
export const addAccountNumber = data => post(url.ADD_ACCOUNT_NUMBER, data);
export const updateAccountNumber = data => put(url.UPDATE_ACCOUNT_NUMBER, data);
export const deleteAccountNumber = param => del(url.DELETE_ACCOUNT_NUMBER, param);

//Piutang
export const getDaftarPiutang = filter => get(url.GET_DAFTAR_PIUTANG, filter);
export const getPiutang = param => get(url.GET_PIUTANG, param);
export const addPiutang = data => post(url.ADD_PIUTANG, data);
export const updatePiutang = data => put(url.UPDATE_PIUTANG, data);
export const deletePiutang = param => del(url.DELETE_PIUTANG, param);
export const addPiutangPayment = data => post(url.ADD_PIUTANG_PAYMENT, data);
export const deletePiutangPayment = data => del(url.DELETE_PIUTANG_PAYMENT, data);

//Account Transaction
export const getListAccountTransaction = filter => get(url.GET_LIST_ACCOUNT_TRANSACTION, filter);
export const getAccountTransaction = filter => get(url.GET_ACCOUNT_TRANSACTION, filter);
export const addAccountTransaction = data => post(url.ADD_ACCOUNT_TRANSACTION, data);
export const updateAccountTransaction = data => put(url.UPDATE_ACCOUNT_TRANSACTION, data);
export const deleteAccountTransaction = param => del(url.DELETE_ACCOUNT_TRANSACTION, param);
export const addAccountTransactionByExcel = (data, param) => postFile(url.ADD_ACCOUNT_TRANSACTION_BY_EXCEL, data, param);
export const exportExcelAccountTransaction = (filter, config) => getFile(url.EXPORT_EXCEL_ACCOUNT_TRANSACTION, filter, config);

//Worksheet
export const getListWorksheet = filter => get(url.GET_LIST_WORKSHEET, filter);
export const exportExcelWorksheet = (filter, config) => getFile(url.GET_EXCEL_WORKSHEET, filter, config);
export const balancingWorksheet = filter => get(url.BALANCING_WORKSHEET, filter);
//Role
export const getListRole = filter => get(url.GET_LIST_ROLE, filter);
export const getRole = param => get(url.GET_ROLE, param);
export const addRole = data => post(url.ADD_ROLE, data);
export const updateRole = data => put(url.UPDATE_ROLE, data);
export const deleteRole = param => del(url.DELETE_ROLE, param);

//User
export const getListUser = filter => get(url.GET_LIST_USER, filter);
export const getUser = param => get(url.GET_USER, param);
export const addUser = data => post(url.ADD_USER, data);
export const updateUser = data => put(url.UPDATE_USER, data);
export const deleteUser = param => del(url.DELETE_USER, param);
export const resetPasswordUser = data => post(url.RESET_PASSWORD_USER, data);
export const changePasswordUser = data => post(url.CHANGE_PASSWORD_USER, data);

//Report
export const getReportExcel = (data, param) => getFilePost(url.GET_REPORT_EXCEL, data, param);
export const getReportPdf = (data, param) => getFilePost(url.GET_REPORT_PDF, data, param);
export const getReportProfitLossExcel = (data, param) => getFilePost(url.GET_REPORT_PROFITLOSS_EXCEL, data, param);
export const getReportProfitLossPdf = (data, param) => getFilePost(url.GET_REPORT_PROFITLOSS_PDF, data, param);


export {
    postLogin, IsUserAuthenticated, loginCheck
};