import {
  GET_LIST_ASSET_CATEGORY,
  GET_LIST_ASSET_CATEGORY_SUCCESS,
  GET_LIST_ASSET_CATEGORY_FAIL,
  GET_ASSET_CATEGORY,
  GET_ASSET_CATEGORY_SUCCESS,
  GET_ASSET_CATEGORY_FAIL,
  ADD_ASSET_CATEGORY,
  ADD_ASSET_CATEGORY_SUCCESS,
  ADD_ASSET_CATEGORY_FAIL,
  UPDATE_ASSET_CATEGORY,
  UPDATE_ASSET_CATEGORY_SUCCESS,
  UPDATE_ASSET_CATEGORY_FAIL,
  DELETE_ASSET_CATEGORY,
  DELETE_ASSET_CATEGORY_SUCCESS,
  DELETE_ASSET_CATEGORY_FAIL,
  CLEAR_LIST_ASSET_CATEGORY
} from "./actionTypes";

const INIT_STATE = {
  listAssetCategory: [],
  assetCategory: null,
  error: '',
  loading: false
};

const AssetCategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_ASSET_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        listAssetCategory: action.payload,
        loading: false,
      };
    case GET_LIST_ASSET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ASSET_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        assetCategory: action.payload,
        loading: false,
      };
    case GET_ASSET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_ASSET_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case ADD_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ASSET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_ASSET_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ASSET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_ASSET_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ASSET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CLEAR_LIST_ASSET_CATEGORY:
      return {
        ...state,
        listAssetCategory: [],
      };

    default:
      return state;
  }
};

export default AssetCategory;
