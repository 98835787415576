import React, { useState, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import PropTypes, { func } from "prop-types";
import {
    Table,
    Container,
    Button,
    Col,
    Row,
    FormFeedback,
    Label,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Form,
    FormGroup,
    Input,
    InputGroup
} from "reactstrap";
import Select from "react-select";
import { NotifHelper } from "../../components/Common/NotifHelper"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getPiutangById as onGetPiutangById,
    getListAccountNumber as onGetListAccountNumber,
    getListAccount as onGetListAccount,
    addPiutangPayment as onAddPiutangPayment,
    deletePiutangPayment as onDeletePiutangPayment
} from "../../store/actions";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { upperCase } from "lodash";
import CurrencyFormat from 'react-currency-format';
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import DeleteModal from "../../components/Common/ModalDelete";


const PiutangPayment = (props) => {
    //meta title
    document.title = "Payment Piutang | Des Counts";
    const dispatch = useDispatch();
    const companyId = props?.match?.params?.companyId;
    const id = props?.match?.params?.id;
    const [isAddPayment, setIsAddPayment] = useState(false)
    const [newPayment, SetNewPayment] = useState([])
    const [selectedPayment, setSelectedPayment] = useState(null)
    const [isOpenDelete, setIsOpenDelete] = useState(false)


    const { piutang, listAccountNumber, listAccount, loading, loadingAccountNumber, loadingAccount, loadingAddPayment } = useSelector(state => ({
        piutang: state.Piutang.piutang,
        listAccountNumber: state.AccountNumber.listAccountNumber,
        listAccount: state.Account.listAccount,
        loading: state.Piutang.loading,
        loadingAccountNumber: state.AccountNumber.loading,
        loadingAccount: state.Account.loading,
        loadingAddPayment: state.Piutang.loadingPayment
    }));

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            accountNumberId: "",
            date: "",
        },
        validationSchema: Yup.object({
            accountNumberId: Yup.string().required("Please Select Company"),
            date: Yup.string().required("Please Select Date"),
        }),
        onSubmit: (values) => {
            var validDetail = true;
            var debit = 0;
            var credit = 0;
            newPayment.forEach(item => {
                if (item.nominal == null || item.nominal == undefined || item.type == null || item.account == null) {
                    validDetail = false;
                }
                if (item.type != null && item.type.value == 'credit' && item.nominal != null) {
                    credit += item.nominal
                }
                if (item.type != null && item.type.value == 'debit' && item.nominal != null) {
                    debit += item.nominal
                }
            })
            if (debit != credit || !validDetail) {
                NotifHelper.error("Payment Detail Not Valid!")
            }
            else {
                dispatch(onAddPiutangPayment({
                    companyId: piutang.companyId,
                    piutangId: piutang.id,
                    accountNumberId: values.accountNumberId,
                    date: values.date,
                    detail: newPayment.map(item => {
                        return {
                            accountId: parseInt(item.account.value),
                            type: item.type.value,
                            nominal: item.nominal
                        }
                    })
                }, props.history));
            }
        },
    });

    const [selectedAccountNumber, setSelectedAccountNumber] = useState(null);
    const [optionAccountNumber, setOptionAccountNumber] = useState([]);

    const [optionAccount, setOptionAccount] = useState([]);
    const [optionType] = useState([
        { label: "CREDIT", value: "credit" },
        { label: "DEBIT", value: "debit" },
    ]);

    function handleDateChange(e) {
        const dd = e[0].getDate().toString();
        const mm = e[0].getMonth() + 1;
        const yyyy = e[0].getFullYear();
        validation.setFieldValue("date", String(yyyy).padStart(2, '0') + "-" + String(mm).padStart(2, '0') + "-" + String(dd).padStart(2, '0'), true)
    }

    function handleSelectAccountNumber(e) {
        setSelectedAccountNumber(e)
        validation.setFieldValue("accountNumberId", e.value.toString(), true)
    }

    function handleSelectAccount(e, id) {
        const curDetail = newPayment;
        curDetail.map(item => {
            if (item.id == id) {
                item.account = e;
            }
        })
        SetNewPayment(curDetail)
    }

    function handleChangeNominal(e, id) {
        const curDetail = newPayment;
        curDetail.map(item => {
            if (item.id == id) {
                item.nominal = e.floatValue;
            }
        })
        SetNewPayment(curDetail)
    }

    function handleSelectType(e, id) {
        const curDetail = newPayment;
        curDetail.map(item => {
            if (item.id == id) {
                item.type = e;
            }
        })
        SetNewPayment(curDetail)
    }

    function handleDeleteDetail(item) {
        const curDetail = newPayment;
        var idx = curDetail.indexOf(item)
        if (idx > -1) {
            curDetail.splice(idx, 1)
            SetNewPayment(curDetail)
        }
    }

    function addDetail() {
        const curDetail = newPayment;
        curDetail.push({
            id: curDetail[curDetail.length - 1].id + 1,
            account: null,
            type: null,
            nominal: 0
        })
        SetNewPayment(curDetail)
    }

    const onOpenDeleteCommand = (data) => {
        setSelectedPayment(data)
        setIsOpenDelete(true);
    }

    const onDeleteCommand = () => {
        setIsOpenDelete(false)
        dispatch(onDeletePiutangPayment({
            id: selectedPayment.id,
            companyId: selectedPayment.companyId,
            piutangId: selectedPayment.piutangId
        }))
    }

    useEffect(() => {
        if (listAccountNumber) {
            setOptionAccountNumber(listAccountNumber.map((item) => {
                return { label: item.name + " - " + item.number, value: item.id.toString() }
            }))
        }
    }, [listAccountNumber])

    useEffect(() => {
        if (listAccount) {
            setOptionAccount(listAccount.map((item) => {
                return { label: item.code + " - " + item.name, value: item.id.toString() }
            }))
        }
    }, [listAccount])

    useEffect(() => {
        dispatch(onGetPiutangById(id, companyId));
    }, [])

    useEffect(() => {
        if (isAddPayment) {
            SetNewPayment([{
                id: 1,
                account: null,
                type: null,
                nominal: 0
            }])
            dispatch(onGetListAccountNumber({
                companyId: piutang.companyId
            }));
            dispatch(onGetListAccount({
                companyId: piutang.companyId
            }));
        }
    }, [isAddPayment])

    useEffect(() => {
        if (!loadingAddPayment) {
            if (isAddPayment) {
                setIsAddPayment(false)
            }
            dispatch(onGetPiutangById(id, companyId))
        }
    }, [loadingAddPayment])

    return (
        <React.Fragment>
            <DeleteModal show={isOpenDelete} onDeleteClick={onDeleteCommand} onCloseClick={() => setIsOpenDelete(false)} />
            {
                loading &&
                <div className="d-flex justify-content-center my-5">
                    <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                </div>
            }
            {
                !loading &&
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Piutang" breadcrumbItem="Piutang Payment" />
                        {
                            !loading && piutang &&
                            <Row>
                                <Col xs={12}>
                                    <Card>
                                        <CardHeader className="d-flex">
                                            <CardTitle className={'align-self-center mb-0'}>Piutang Data</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="table-responsive">
                                                        <Table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Company</td>
                                                                    <td>{piutang.company.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Type</td>
                                                                    <td>{upperCase(piutang.type)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Remarks</td>
                                                                    <td>{piutang.remarks}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="table-responsive">
                                                        <Table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Account</td>
                                                                    <td>
                                                                        {piutang.account.code} - {piutang.account.name}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Nominal</td>
                                                                    <td>
                                                                        <CurrencyFormat value={piutang.nominal} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                                                                            displayType={'text'} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Remain</td>
                                                                    <td>
                                                                        <CurrencyFormat value={piutang.remain} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                                                                            displayType={'text'} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {
                            !isAddPayment &&
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardHeader className="d-flex">
                                            <CardTitle className={'align-self-center mb-0 flex-grow-1'}>Payment Data</CardTitle>
                                            <Button className="btn btn-secondary waves-effect waves-light me-2" onClick={() => props.history.goBack()}>Back to List Piutang</Button>
                                            {
                                                piutang && piutang.remain > 0 &&
                                                <Button className="btn btn-success waves-effect waves-light"
                                                    onClick={() => setIsAddPayment(true)}>Add Payment</Button>
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            {
                                                !loading && piutang &&
                                                <div className="table-responsive react-table">
                                                    <Table bordered hover size={'sm'} className={'mb-0'}>
                                                        <thead className="table-light table-nowrap">
                                                            <tr>
                                                                <th className={"text-center"}>Account Number / Bank</th>
                                                                <th className={"text-center"}>Date</th>
                                                                <th className={"text-center"}>Account Code</th>
                                                                <th className={"text-center"}>Type</th>
                                                                <th className={"text-center"}>Nominal</th>
                                                                <th className={"text-center"}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            piutang.piutangBayar.map(bayar => {
                                                                return <tbody key={'bayar' + bayar.id}>
                                                                    {
                                                                        bayar.piutangBayarDetail.map((detail, i) => {
                                                                            return <tr key={'bayarDetail' + detail.id}>
                                                                                {
                                                                                    i == 0 &&
                                                                                    <>
                                                                                        <td rowSpan={bayar.piutangBayarDetail.length + 1} className={'align-middle px-2'}>
                                                                                            {bayar.accountNumber.name}
                                                                                        </td>
                                                                                        <td rowSpan={bayar.piutangBayarDetail.length + 1} className={'align-middle text-center px-2'}>
                                                                                            {bayar.date}
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td className="text-center p-2">{detail.account.code}</td>
                                                                                <td className="text-center p-2">{upperCase(detail.type)}</td>
                                                                                <td className="text-end p-2">
                                                                                    <CurrencyFormat value={detail.nominal} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                                                                                        displayType={'text'} />
                                                                                </td>
                                                                                {
                                                                                    i == 0 &&
                                                                                    <td rowSpan={bayar.piutangBayarDetail.length + 1} className={'align-middle text-center px-2'}>
                                                                                        <Button className="btn btn-danger waves-effect waves-light mt-auto"
                                                                                            type="button" onClick={() => onOpenDeleteCommand(bayar)}>
                                                                                            <i className="bx bx-trash"></i>
                                                                                        </Button>
                                                                                    </td>
                                                                                }
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            })
                                                        }
                                                    </Table>
                                                </div>
                                            }
                                            {
                                                loading &&
                                                <div className="d-flex justify-content-center my-5">
                                                    <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                                </div>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {
                            isAddPayment && !loadingAddPayment &&
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}>
                                <Row>
                                    <Col xs="12">
                                        <Card>
                                            <CardHeader className="d-flex">
                                                <CardTitle className={'align-self-center mb-0 flex-grow-1'}>Add Payment</CardTitle>
                                                <Button className="btn btn-secondary waves-effect waves-light me-2" onClick={() => setIsAddPayment(false)}>Cancel</Button>
                                                <Button className="btn btn-success waves-effect waves-light" type="submit">Submit</Button>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col className="col-12 col-sm-6">
                                                        <Label>Account Number</Label><Label className="text-danger">*</Label>
                                                        <div
                                                            style={validation.touched.accountNumberId && validation.errors.accountNumberId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                { border: '0', borderRadius: '0.25rem' }}
                                                        >
                                                            <Select
                                                                value={selectedAccountNumber}
                                                                onChange={handleSelectAccountNumber}
                                                                onBlur={validation.handleBlur}
                                                                options={optionAccountNumber}
                                                                isLoading={loadingAccountNumber}
                                                                className="select2-selection"
                                                            />
                                                        </div>
                                                        {validation.touched.accountNumberId && validation.errors.accountNumberId ? (
                                                            <FormFeedback className="d-block" type="invalid">{validation.errors.accountNumberId}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                    <Col className="col-12 col-sm-6">
                                                        <FormGroup>
                                                            <Label>Date</Label><Label className="text-danger">*</Label>
                                                            <InputGroup
                                                                style={validation.touched.date && validation.errors.date ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                    { border: '0', borderRadius: '0.25rem' }}>
                                                                <Flatpickr
                                                                    className={"form-control d-block" + (validation.errors.date ? "is-invalid" : "")}
                                                                    placeholder="Select Date"
                                                                    options={{
                                                                        dateFormat: "Y-m-d"
                                                                    }}
                                                                    onChange={e => handleDateChange(e)}
                                                                    value={validation.values.date}
                                                                />
                                                            </InputGroup>
                                                            {validation.touched.date && validation.errors.date ? (
                                                                <FormFeedback className="d-block" type="invalid">{validation.errors.date}</FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <CardTitle className="my-2">Detail</CardTitle>
                                                {
                                                    newPayment && newPayment.map((item) => {
                                                        return <Row key={item.id} className='d-flex mt-3'>
                                                            <Col className="col-12 col-sm-4">
                                                                <Label>Account</Label><Label className="text-danger">*</Label>
                                                                <div>
                                                                    <Select
                                                                        value={item.account}
                                                                        onChange={e => handleSelectAccount(e, item.id)}
                                                                        options={optionAccount}
                                                                        isLoading={loadingAccount}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col className="col-12 col-sm-3">
                                                                <Label>Type</Label><Label className="text-danger">*</Label>
                                                                <div>
                                                                    <Select
                                                                        value={item.type}
                                                                        onChange={e => handleSelectType(e, item.id)}
                                                                        options={optionType}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col className="col-12 col-sm-4">
                                                                <Label>Nominal</Label><Label className="text-danger">*</Label>
                                                                <CurrencyFormat thousandSeparator="." decimalSeparator=","
                                                                    value={item.nominal} customInput={Input} onValueChange={e => handleChangeNominal(e, item.id)} className='text-end' />
                                                            </Col>
                                                            <Col className="col-12 col-sm-1 text-center align-self-end">
                                                                {
                                                                    item.id != 1 &&
                                                                    <Button className="btn btn-danger waves-effect waves-light mt-auto"
                                                                        type="button" onClick={() => handleDeleteDetail(item)}>
                                                                        <i className="bx bx-trash"></i>
                                                                    </Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    })
                                                }
                                                <div className="d-flex justify-content-center my-3">
                                                    <Button className="btn btn-primary waves-effect waves-light" onClick={() => addDetail()}>
                                                        <i className="bx bx-plus"></i> New
                                                    </Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        }
                        {
                            loadingAddPayment &&
                            <div className="d-flex justify-content-center my-5">
                                <PulseLoader loading={loadingAddPayment} color={'#FFF'} size={12} className="align-self-center" />
                            </div>
                        }
                    </Container>
                </div>
            }
        </React.Fragment>
    )
}

PiutangPayment.propTypes = {
    className: PropTypes.string,
};

export default PiutangPayment
