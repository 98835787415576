export const GET_REPORT_EXCEL = "GET_REPORT_EXCEL";
export const GET_REPORT_EXCEL_SUCCESS = "GET_REPORT_EXCEL_SUCCESS";
export const GET_REPORT_EXCEL_FAIL = "GET_REPORT_EXCEL_FAIL";

export const GET_REPORT_PDF = "GET_REPORT_PDF";
export const GET_REPORT_PDF_SUCCESS = "GET_REPORT_PDF_SUCCESS";
export const GET_REPORT_PDF_FAIL = "GET_REPORT_PDF_FAIL";

export const GET_REPORT_PROFITLOSS_EXCEL = "GET_REPORT_PROFITLOSS_EXCEL";
export const GET_REPORT_PROFITLOSS_EXCEL_SUCCESS = "GET_REPORT_PROFITLOSS_EXCEL_SUCCESS";
export const GET_REPORT_PROFITLOSS_EXCEL_FAIL = "GET_REPORT_PROFITLOSS_EXCEL_FAIL";

export const GET_REPORT_PROFITLOSS_PDF = "GET_REPORT_PROFITLOSS_PDF";
export const GET_REPORT_PROFITLOSS_PDF_SUCCESS = "GET_REPORT_PROFITLOSS_PDF_SUCCESS";
export const GET_REPORT_PROFITLOSS_PDF_FAIL = "GET_REPORT_PROFITLOSS_PDF_FAIL";