import React, { useState, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import {
    Container,
    Button,
    Col,
    Row,
    Label,
    Card,
    CardBody,
    CardTitle,
    Table as TableReact
} from "reactstrap";
import Select from "react-select";
import { Column, Table } from 'react-virtualized';
import "react-virtualized/styles.css";
import _ from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanies as onGetCompanies,
    getListWorksheet as onGetWorksheets,
    getPeriods as onGetPeriods,
    clearListWorksheet as onClearWorksheets,
    exportExcelWorksheet as onExportExcelWorksheets,
    balancingWorksheet as onBalancingWorksheets
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import CurrencyFormat from 'react-currency-format';
import ModalConfirmationBalancing from './modalConfirmationBalancing'

const Worksheet = () => {

    //meta title
    document.title = "Worksheet | Des Counts";
    const dispatch = useDispatch();
    const [isOpenBalancing, SetIsOpenBalancing] = useState(false)
    const [listColumn, setListColumn] = useState([])
    const [listData, setListData] = useState([])

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }

    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [optionPeriod, setOptionPeriod] = useState([]);
    function handleSelectPeriod(e) {
        setSelectedPeriod(e);
    }

    const filterCompany = useRef("");
    const filterPeriod = useRef("");

    const {
        companies,
        loadingCompany,
        periods,
        loadingPeriod,
        worksheets,
        loading,
        summaryAccounts,
        summarys,
        accountNumbers,
    } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
        periods: state.Period.periods,
        loadingPeriod: state.Period.loading,
        worksheets: state.Worksheet.listWorksheet,
        loading: state.Worksheet.loading,
        summaryAccounts: state.Worksheet.listSummaryAccount,
        summarys: state.Worksheet.listSummary,
        accountNumbers: state.Worksheet.listAccountNumber,
    }));


    const onRefreshCommand = () => {
        if (selectedCompany && selectedPeriod) {
            dispatch(onGetWorksheets({
                companyId: selectedCompany ? selectedCompany.value : null,
                periodId: selectedPeriod ? selectedPeriod.value : null,
            }));
        }
    }

    const onResetFilterCommand = () => {
        filterCompany.current.clearValue();
        filterPeriod.current.clearValue();
    }

    const onExportExcel = () => {
        dispatch(onExportExcelWorksheets({
            companyId: selectedCompany ? selectedCompany.value : null,
            periodId: selectedPeriod ? selectedPeriod.value : null,
        }))
    }

    const onOpenBalancingCommand = () => {
        if (selectedCompany && selectedPeriod) {
            SetIsOpenBalancing(true)
        }
    }

    const onCloseBalancingCommand = () => {
        SetIsOpenBalancing(false)
    }

    const onBalancingWorksheetCommand = () => {
        SetIsOpenBalancing(false)
        if (selectedCompany && selectedPeriod) {
            dispatch(onBalancingWorksheets({ companyId: selectedCompany.value, periodId: selectedPeriod.value }))
        }
    }

    useEffect(() => {
        dispatch(onGetCompanies({}));
    }, [dispatch]);

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (periods) {
            setOptionPeriod(periods.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [periods])

    useEffect(() => {
        if (selectedCompany) {
            filterPeriod.current.clearValue()
            dispatch(onGetPeriods({ companyId: selectedCompany.value }))
        }
    }, [selectedCompany])

    useEffect(() => {
        // dispatch(onClearWorksheets())
    }, [selectedCompany, selectedPeriod])

    useEffect(() => {
        if (worksheets) {
            const newDataColumns = [
                { dataKey: 'accountCode' },
                { dataKey: 'accountName' },
                { dataKey: 'beginningBalanceDebit' },
                { dataKey: 'beginningBalanceCredit' },
                { dataKey: 'accountCode' },
            ];
            const newListData = worksheets.map((item, idxData) => {
                const Detail = () => {
                    var res = {};
                    var newDataColumnsTemp = newDataColumns;
                    item.detail.map((i) => {
                        var temp = {};
                        temp['accNum' + i.accountNumberId + 'Debit'] = i.debit == 0 ? '-' : <CurrencyFormat value={i.debit} displayType={'text'} thousandSeparator='.' decimalSeparator=',' decimalScale={0} />
                        temp['accNum' + i.accountNumberId + 'Credit'] = i.credit == 0 ? '-' : <CurrencyFormat value={i.credit} displayType={'text'} thousandSeparator='.' decimalSeparator=',' decimalScale={0} />
                        res = { ...res, ...temp }
                        newDataColumnsTemp = [...newDataColumnsTemp, { dataKey: 'accNum' + i.accountNumberId + 'Debit' }, { dataKey: 'accNum' + i.accountNumberId + 'Credit' }]
                    })
                    if (idxData == 0) {
                        setListColumn([...newDataColumnsTemp,
                        { dataKey: 'contentAccountCompoundDebit' },
                        { dataKey: 'contentAccountCompoundCredit' },
                        { dataKey: 'contentAccountTrialBalanceDebit' },
                        { dataKey: 'contentAccountTrialBalanceCredit' },
                        { dataKey: 'accountCode' },
                        { dataKey: 'adjustmentDebit' },
                        { dataKey: 'adjustmentCredit' },
                        { dataKey: 'nsspDebit' },
                        { dataKey: 'nsspCredit' },
                        { dataKey: 'profitLossDebit' },
                        { dataKey: 'profitLossCredit' },
                        { dataKey: 'balanceSheetDebit' },
                        { dataKey: 'balanceSheetCredit' },
                        ]);
                    }
                    return res
                }
                const Compound = () => {
                    var res = {};
                    var temp = {};
                    temp['contentAccountCompoundDebit'] = item.compoundJurnal.debit == 0 ? '-' : <CurrencyFormat value={item.compoundJurnal.debit} displayType={'text'} thousandSeparator='.' decimalSeparator=',' decimalScale={0} />
                    temp['contentAccountCompoundCredit'] = item.compoundJurnal.credit == 0 ? '-' : <CurrencyFormat value={item.compoundJurnal.credit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    res = { ...res, ...temp }
                    return res
                }
                const TrialBalance = () => {
                    var res = {};
                    var temp = {};
                    temp['contentAccountTrialBalanceDebit'] = item.trialBalance.debit == 0 ? '-' : <CurrencyFormat value={item.trialBalance.debit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    temp['contentAccountTrialBalanceCredit'] = item.trialBalance.credit == 0 ? '-' : <CurrencyFormat value={item.trialBalance.credit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    res = { ...res, ...temp }
                    return res
                }
                const Adjustment = () => {
                    var res = {};
                    var temp = {};
                    temp['adjustmentDebit'] = item.adjustment.debit == 0 ? '-' : <CurrencyFormat value={item.adjustment.debit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    temp['adjustmentCredit'] = item.adjustment.credit == 0 ? '-' : <CurrencyFormat value={item.adjustment.credit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    res = { ...res, ...temp }
                    return res
                }
                const Nssp = () => {
                    var res = {};
                    var temp = {};
                    temp['nsspDebit'] = item.nssp.debit == 0 ? '-' : <CurrencyFormat value={item.nssp.debit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    temp['nsspCredit'] = item.nssp.credit == 0 ? '-' : <CurrencyFormat value={item.nssp.credit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    res = { ...res, ...temp }
                    return res
                }
                const ProfitLoss = () => {
                    var res = {};
                    var temp = {};
                    temp['profitLossDebit'] = item.profitLoss.debit == 0 ? '-' : <CurrencyFormat value={item.profitLoss.debit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    temp['profitLossCredit'] = item.profitLoss.credit == 0 ? '-' : <CurrencyFormat value={item.profitLoss.credit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    res = { ...res, ...temp }
                    return res
                }
                const BalanceSheet = () => {
                    var res = {};
                    var temp = {};
                    temp['balanceSheetDebit'] = item.balanceSheet.debit == 0 ? '-' : <CurrencyFormat value={item.balanceSheet.debit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    temp['balanceSheetCredit'] = item.balanceSheet.credit == 0 ? '-' : <CurrencyFormat value={item.balanceSheet.credit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0}/>
                    res = { ...res, ...temp }
                    return res
                }
                var detail = Detail()
                var compound = Compound()
                var trialBalance = TrialBalance()
                var adjustment = Adjustment()
                var nssp = Nssp()
                var profitLoss = ProfitLoss()
                var balanceSheet = BalanceSheet()
                return {
                    accountCode: item.accountCode,
                    accountName: item.accountName,
                    beginningBalanceDebit: item.accountDebit == 0 ? '-' : <CurrencyFormat value={item.accountDebit} displayType={'text'} thousandSeparator='.' decimalSeparator=',' decimalScale={0} />,
                    beginningBalanceCredit: item.accountCredit == 0 ? '-' : <CurrencyFormat value={item.accountCredit} displayType={'text'} thousandSeparator='.' decimalSeparator=','  decimalScale={0} />,
                    ...detail,
                    ...compound,
                    ...trialBalance,
                    ...adjustment,
                    ...nssp,
                    ...profitLoss,
                    ...balanceSheet
                }
            })
            setListData(newListData)
        }
    }, [worksheets])

    return (
        <React.Fragment>
            <ModalConfirmationBalancing show={isOpenBalancing} onSubmit={onBalancingWorksheetCommand} onClose={onCloseBalancingCommand} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Worksheet" breadcrumbItem="Worksheet's List" />
                    <Row className="mb-3">
                        <Col xs="12">
                            <Card>
                                <CardBody className="pt-0">
                                    <CardTitle className="my-3">Filter</CardTitle>
                                    <Row>
                                        <Col className="col-12 col-sm-6">
                                            <div>
                                                <Label>Company</Label>
                                                <Select
                                                    value={selectedCompany}
                                                    onChange={handleSelectCompany}
                                                    options={optionCompany}
                                                    isLoading={loadingCompany}
                                                    isClearable={true}
                                                    ref={filterCompany}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-6">
                                            <div>
                                                <Label>Period</Label>
                                                <Select
                                                    value={selectedPeriod}
                                                    onChange={handleSelectPeriod}
                                                    options={optionPeriod}
                                                    isLoading={loadingPeriod}
                                                    isClearable={true}
                                                    ref={filterPeriod}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="justify-content-end">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light  float-end" style={{ minWidth: '200px' }}
                                                disabled={loading}
                                                onClick={onRefreshCommand}>
                                                <i className="bx bx-filter-alt"></i>
                                            </Button>
                                            <Button color="info" className="btn btn-info waves-effect waves-light me-2 float-end" style={{ minWidth: '100px' }}
                                                disabled={loading}
                                                onClick={onResetFilterCommand}>
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {
                                        !loading && selectedCompany && selectedPeriod && worksheets &&
                                        <>
                                            <div className="d-flex justify-content-end mb-2">
                                                {
                                                    selectedCompany && selectedPeriod &&
                                                    <div className="flex-grow-1">
                                                        <Button color="primary" className="btn btn-primary waves-effect waves-light"
                                                            onClick={onOpenBalancingCommand}>
                                                            Update Balancing
                                                        </Button>
                                                    </div>
                                                }
                                                <Button color="success" className="btn btn-success waves-effect waves-light"
                                                    onClick={onExportExcel}>
                                                    Export Excel
                                                </Button>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="d-flex bg-light">
                                                    <div className='text-center bg-light align-self-center py-2' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                        Code
                                                    </div>
                                                    <div className='text-start bg-light  align-self-center py-2 ms-0' style={{ minWidth: '200px', marginLeft: '10px', marginRight: '10px' }}>
                                                        List of Account
                                                    </div>
                                                    <div className='text-center bg-light pt-2' style={{ minWidth: '220px'}}>
                                                        <div>Beginning Balance</div>
                                                        <div className="d-flex">
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Debit
                                                            </div>
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Credit
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='text-center bg-light align-self-center py-2 ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                        Code
                                                    </div>
                                                    {
                                                        worksheets && worksheets.length > 0 &&
                                                        worksheets[0].detail.map((item) => {
                                                            return <div key={'headerAccountNumber' + item.accountNumberId} className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                                <div>{item.accountNumberName}</div>
                                                                <div className="d-flex">
                                                                    <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                        Debit
                                                                    </div>
                                                                    <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                        Credit
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                    <div className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                        <div>COMPOUND-JOURNAL ENTRY</div>
                                                        <div className="d-flex">
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                Debit
                                                            </div>
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                Credit
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                        <div>TRIAL BALANCE</div>
                                                        <div className="d-flex">
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                Debit
                                                            </div>
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                Credit
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='text-center bg-light align-self-center py-2 ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                        Code
                                                    </div>
                                                    <div className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                        <div>ADJUSTMENT</div>
                                                        <div className="d-flex">
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Debit
                                                            </div>
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Credit
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                        <div>NSSP</div>
                                                        <div className="d-flex">
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Debit
                                                            </div>
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Credit
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                        <div>Profit & Loss</div>
                                                        <div className="d-flex">
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Debit
                                                            </div>
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Credit
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                        <div>Balance Sheet</div>
                                                        <div className="d-flex">
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Debit
                                                            </div>
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                Credit
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Table
                                                    width={2550}
                                                    disableHeader={true}
                                                    height={500}
                                                    headerHeight={0}
                                                    rowHeight={30}
                                                    rowCount={listData.length}
                                                    rowGetter={({ index }) => listData[index]}>
                                                    {
                                                        listColumn && listColumn.map((item, idx) => {
                                                            return <Column key={'col' + idx}
                                                                dataKey={item.dataKey}
                                                                cellDataGetter={({ rowData }) => rowData.index}
                                                                disableSort={true}
                                                                flexGrow={1}
                                                                className={
                                                                    (item.dataKey == 'accountCode' ? 'text-center' : (
                                                                        item.dataKey == 'accountName' ? 'text-start' : 'text-end'
                                                                    ))
                                                                }
                                                                cellRenderer={({
                                                                    dataKey,
                                                                    rowData,
                                                                }) => {
                                                                    return rowData[dataKey];
                                                                }}
                                                                width={idx == 1 ? 200 : 100}
                                                                minWidth={idx == 1 ? 200 : 100}
                                                                maxWidth={idx == 1 ? 200 : 100} />
                                                        })
                                                    }
                                                </Table>
                                                <div className="d-flex bg-light">
                                                    <div className='text-center bg-light align-self-center py-2' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                    </div>
                                                    <div className='text-start bg-light  align-self-center py-2 ms-0' style={{ minWidth: '200px', marginLeft: '10px', marginRight: '10px' }}>
                                                    </div>
                                                    <div className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                        <div className="d-flex">
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                {worksheets.reduce((acc, cur) => acc + cur.accountDebit, 0) == 0 ? '-' : <CurrencyFormat value={worksheets.reduce((acc, cur) => acc + cur.accountDebit, 0)} displayType={'text'} thousandSeparator='.' decimalSeparator=',' decimalScale={0} />}
                                                            </div>
                                                            <div className='text-center bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                {worksheets.reduce((acc, cur) => acc + cur.accountCredit, 0) == 0 ? '-' : <CurrencyFormat value={worksheets.reduce((acc, cur) => acc + cur.accountCredit, 0)} displayType={'text'} thousandSeparator='.' decimalSeparator=',' decimalScale={0} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='text-center bg-light align-self-center py-2 ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                    </div>
                                                    {
                                                        summaryAccounts &&
                                                        summaryAccounts.map((item) => {
                                                            return <div key={'footerAN' + item.accountNumberId} className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                                <div className="d-flex">
                                                                    <div className='text-end bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                        {item.debit == 0 ? '-' : <CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator='.' decimalSeparator=',' />}
                                                                    </div>
                                                                    <div className='text-end bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                        {item.credit == 0 ? '-' : <CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator='.' decimalSeparator=',' />}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                    {
                                                        summarys &&
                                                        summarys.map((item, idx) => {
                                                            return <>
                                                                {idx == 2 &&
                                                                    <div className='text-center bg-light align-self-center py-2' style={{ minWidth: '100px', marginLeft: '0px', marginRight: '10px' }}>
                                                                        Code
                                                                    </div>
                                                                }
                                                                <div className='text-center bg-light pt-2' style={{ minWidth: '220px' }}>
                                                                    <div className="d-flex">
                                                                        <div className='text-end bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                            {item.debit == 0 ? '-' : <CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator='.' decimalSeparator=',' />}
                                                                        </div>
                                                                        <div className='text-end bg-light py-2  ms-0' style={{ minWidth: '100px', marginLeft: '10px', marginRight: '10px' }}>
                                                                            {item.credit == 0 ? '-' : <CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator='.' decimalSeparator=',' />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        !loading && (!selectedCompany || !selectedPeriod) &&
                                        <div className="d-flex justify-content-center my-5">
                                            <p>Please select company and period.</p>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

Worksheet.propTypes = {
    className: PropTypes.string,
    preGlobalFilteredRows: PropTypes.any,
};

export default Worksheet
