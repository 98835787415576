import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from 'react-router-dom';
import {
    Container,
    Button,
    Col,
    Row,
    Form,
    Input,
    InputGroup,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
    addAsset as onAddAsset,
    getAssetById as onGetAssetById,
    updateAsset as onUpdateAsset,
    getCompanies as onGetCompanies,
    getListAssetType as onGetAssetTypes,
    clearListAssetType as onClearAssetType,
    getListAssetCategory as onGetAssetCategories,
    clearListAssetCategory as onClearAssetCategory
} from "../../store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import CurrencyFormat from 'react-currency-format';

const AssetCreateUpdate = (props) => {

    //meta title
    document.title = "Asset | Des Counts";
    const id = props?.match?.params?.id;
    const companyId = props?.match?.params?.companyId;
    const isEdit = id != undefined && companyId != undefined;

    const { companies, loadingCompany, asset, loading, assetCategories, loadingCategory, assetTypes, loadingType } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
        asset: state.Asset.asset,
        loading: state.Asset.loading,
        assetCategories: state.AssetCategory.listAssetCategory,
        loadingCategory: state.AssetCategory.loading,
        assetTypes: state.AssetType.listAssetType,
        loadingType: state.AssetType.loading,
    }));

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e)
        validation.setFieldValue("companyId", e ? e.value : null, true)
    }

    const [selectedAssetCategory, setSelectedAssetCategory] = useState(null);
    const [optionAssetCategory, setOptionAssetCategory] = useState([]);
    function handleSelectAssetCategory(e) {
        setSelectedAssetCategory(e);
        validation.setFieldValue("categoryId", e ? e.value : null, true)
    }

    const [selectedAssetType, setSelectedAssetType] = useState(null);
    const [optionAssetType, setOptionAssetType] = useState([]);
    function handleSelectAssetType(e) {
        setSelectedAssetType(e);
        validation.setFieldValue("typeId", e ? e.value : null, true)
    }

    const [selectedIsFix, setSelectedIsFix] = useState(null);
    const [optionIsFix] = useState([
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]);
    function handleSelectIsFix(e) {
        setSelectedIsFix(e);
        validation.setFieldValue("isFix", e ? e.value : null, true)
    }

    const [selectedIsIntangible, setSelectedIsIntangible] = useState(null);
    const [optionIsIntangible] = useState([
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]);
    function handleSelectIsIntangible(e) {
        setSelectedIsIntangible(e);
        validation.setFieldValue("isIntangible", e ? e.value : null, true)
    }

    function handleDateChange(e) {
        const dd = e[0].getDate().toString();
        const mm = e[0].getMonth() + 1;
        const yyyy = e[0].getFullYear();
        validation.setFieldValue("acquisition", String(yyyy).padStart(2, '0') + "-" + String(mm).padStart(2, '0') + "-" + String(dd).padStart(2, '0'), true)
    }

    function handleChangeAcquisitionValue(e) {
        validation.setFieldValue("acquisitionValue", e.floatValue, true)
    }

    function handleChangeUsageLife(e) {
        validation.setFieldValue("usageLife", e.floatValue, true)
    }

    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyId: isEdit && asset ? asset.companyId : null,
            categoryId: isEdit && asset ? asset.categoryId : null,
            typeId: isEdit && asset ? asset.typeId : null,
            isFix: isEdit && asset ? asset.isFix : null,
            isIntangible: isEdit && asset ? asset.isIntangible : null,
            name: isEdit && asset ? asset.name : null,
            bpu: isEdit && asset ? asset.bpu : null,
            acquisition: isEdit && asset ? asset.acquisition : null,
            quantity: isEdit && asset ? asset.quantity : null,
            acquisitionValue: isEdit && asset ? asset.acquisitionValue : null,
            usageLife: isEdit && asset ? asset.usageLife : null,
        },
        validationSchema: Yup.object({
            companyId: Yup.string().required("Please Select Company").nullable(),
            categoryId: Yup.string().required("Please Select Category").nullable(),
            typeId: Yup.string().required("Please Select Type").nullable(),
            isFix: Yup.string().required("Please Select Fix Asset").nullable(),
            isIntangible: Yup.string().required("Please Select Intangible").nullable(),
            name: Yup.string().required("Please Enter Asset Name").nullable(),
            bpu: Yup.string().required("Please Enter Asset Bpu").nullable(),
            acquisition: Yup.string().required("Please Select Acquisition Date").nullable(),
            quantity: Yup.string().required("Please Enter Quantity").nullable(),
            acquisitionValue: Yup.string().required("Please Enter Asset Value").nullable(),
            usageLife: Yup.string().required("Please Enter Asset Usage Life").nullable(),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                dispatch(onUpdateAsset({ ...values, id: asset.id }, props.history));
            } else {
                dispatch(onAddAsset(values, props.history));
            }
        },
    });

    const filterCategory = useRef("");
    const filterType = useRef("");

    useEffect(() => {
        if (!isEdit) {
            dispatch(onClearAssetCategory())
            dispatch(onClearAssetType())
        }
        dispatch(onGetCompanies({}))
        if (isEdit) {
            dispatch(onGetAssetById(id, companyId))
        }
    }, [dispatch])

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id.toString() }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (assetCategories) {
            setOptionAssetCategory(assetCategories.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [assetCategories])

    useEffect(() => {
        if (assetTypes) {
            setOptionAssetType(assetTypes.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [assetTypes])

    useEffect(() => {
        if (isEdit && asset && companies) {
            var company = optionCompany.filter(s => s.value == asset.companyId);
            if (company.length > 0) {
                setSelectedCompany(company[0])
            }
        }
    }, [optionCompany])

    useEffect(() => {
        if (isEdit && asset && assetCategories) {
            var category = optionAssetCategory.filter(s => s.value == asset.categoryId);
            if (category.length > 0) {
                setSelectedAssetCategory(category[0])
            }
        }
    }, [optionAssetCategory])

    useEffect(() => {
        if (isEdit && asset && assetTypes) {
            var type = optionAssetType.filter(s => s.value == asset.typeId);
            if (type.length > 0) {
                setSelectedAssetType(type[0])
            }
        }
    }, [optionAssetType])

    useEffect(() => {
        if (isEdit && asset) {
            var isFix = optionIsFix.filter(s => s.value == asset.isFix);
            if (isFix.length > 0) {
                setSelectedIsFix(isFix[0])
            }

            var isIntangible = optionIsIntangible.filter(s => s.value == asset.isIntangible);
            if (isIntangible.length > 0) {
                setSelectedIsIntangible(isIntangible[0])
            }
        }
    }, [asset])

    useEffect(() => {
        if (selectedCompany) {
            if (!isEdit) {
                filterCategory.current.clearValue();
                filterType.current.clearValue();
            }
            dispatch(onGetAssetCategories({ companyId: selectedCompany.value }))
            dispatch(onGetAssetTypes({ companyId: selectedCompany.value }))
        }
    }, [selectedCompany])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Asset" breadcrumbItem={(isEdit ? "Edit" : "Add") + " Asset"} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody style={loading ? { pointerEvents: "none" } : {}}>
                                    <Row>
                                        <Col>
                                            <Form
                                                className="form-horizontal"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <Row>
                                                    <Col className="col-12 col-sm-4">
                                                        <Label>Company</Label><Label className="text-danger">*</Label>
                                                        <div
                                                            style={validation.touched.companyId && validation.errors.companyId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                { border: '0', borderRadius: '0.25rem' }}
                                                        >
                                                            <Select
                                                                value={selectedCompany}
                                                                onChange={handleSelectCompany}
                                                                onBlur={validation.handleBlur}
                                                                options={optionCompany}
                                                                isLoading={loadingCompany}
                                                                isDisabled={isEdit}
                                                                className="select2-selection"
                                                            />
                                                        </div>
                                                        {validation.touched.companyId && validation.errors.companyId ? (
                                                            <FormFeedback className="d-block" type="invalid">{validation.errors.companyId}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                    <Col className="col-12 col-sm-4">
                                                        <Label>Category</Label><Label className="text-danger">*</Label>
                                                        <div
                                                            style={validation.touched.categoryId && validation.errors.categoryId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                { border: '0', borderRadius: '0.25rem' }}
                                                        >
                                                            <Select
                                                                value={selectedAssetCategory}
                                                                onChange={handleSelectAssetCategory}
                                                                onBlur={validation.handleBlur}
                                                                options={optionAssetCategory}
                                                                isLoading={loadingCategory}
                                                                ref={filterCategory}
                                                                className="select2-selection"
                                                            />
                                                        </div>
                                                        {validation.touched.categoryId && validation.errors.categoryId ? (
                                                            <FormFeedback className="d-block" type="invalid">{validation.errors.categoryId}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                    <Col className="col-12 col-sm-4">
                                                        <Label>Type</Label><Label className="text-danger">*</Label>
                                                        <div
                                                            style={validation.touched.typeId && validation.errors.typeId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                { border: '0', borderRadius: '0.25rem' }}
                                                        >
                                                            <Select
                                                                value={selectedAssetType}
                                                                onChange={handleSelectAssetType}
                                                                onBlur={validation.handleBlur}
                                                                options={optionAssetType}
                                                                isLoading={loadingType}
                                                                ref={filterType}
                                                                className="select2-selection"
                                                            />
                                                        </div>
                                                        {validation.touched.typeId && validation.errors.typeId ? (
                                                            <FormFeedback className="d-block" type="invalid">{validation.errors.typeId}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col className="col-12 col-sm-4">
                                                        <Label>Fixed Asset</Label><Label className="text-danger">*</Label>
                                                        <div
                                                            style={validation.touched.isFix && validation.errors.isFix ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                { border: '0', borderRadius: '0.25rem' }}
                                                        >
                                                            <Select
                                                                value={selectedIsFix}
                                                                onChange={handleSelectIsFix}
                                                                onBlur={validation.handleBlur}
                                                                options={optionIsFix}
                                                                isLoading={false}
                                                                className="select2-selection"
                                                            />
                                                        </div>
                                                        {validation.touched.isFix && validation.errors.isFix ? (
                                                            <FormFeedback className="d-block" type="invalid">{validation.errors.isFix}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                    <Col className="col-12 col-sm-4">
                                                        <Label>Acquisition Date</Label><Label className="text-danger">*</Label>
                                                        <InputGroup
                                                            style={validation.touched.acquisition && validation.errors.acquisition ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                { border: '0', borderRadius: '0.25rem' }}>
                                                            <Flatpickr
                                                                className={"form-control d-block" + (validation.touched.acquisition && validation.errors.acquisition ? "is-invalid" : "")}
                                                                placeholder="Select Date"
                                                                value={validation.values.acquisition ?? ""}
                                                                options={{
                                                                    dateFormat: "Y-m-d"
                                                                }}
                                                                onChange={handleDateChange}
                                                            />
                                                        </InputGroup>
                                                        {validation.touched.acquisition && validation.errors.acquisition ? (
                                                            <FormFeedback className="d-block" type="invalid">{validation.errors.acquisition}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                    <Col className="col-12 col-sm-4">
                                                        <Label className="form-label">Name</Label><Label className="text-danger">*</Label>
                                                        <Input
                                                            name="name"
                                                            className="form-control"
                                                            placeholder="Enter asset name"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.name ?? ""}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col className="col-12 col-sm-4">
                                                        <Label className="form-label">BPU</Label><Label className="text-danger">*</Label>
                                                        <Input
                                                            name="bpu"
                                                            className="form-control"
                                                            placeholder="Enter asset bpu"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.bpu ?? ""}
                                                            invalid={
                                                                validation.touched.bpu && validation.errors.bpu ? true : false
                                                            }
                                                        />
                                                        {validation.touched.bpu && validation.errors.bpu ? (
                                                            <FormFeedback type="invalid">{validation.errors.bpu}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                    <Col className="col-12 col-sm-4">
                                                        <Label className="form-label">Quantity</Label><Label className="text-danger">*</Label>
                                                        <Input
                                                            name="quantity"
                                                            className="form-control"
                                                            placeholder="Enter asset quantity"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.quantity ?? ""}
                                                            invalid={
                                                                validation.touched.quantity && validation.errors.quantity ? true : false
                                                            }
                                                        />
                                                        {validation.touched.quantity && validation.errors.quantity ? (
                                                            <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                    <Col className="col-12 col-sm-4">
                                                        <Label>Acquisition Value</Label><Label className="text-danger">*</Label>
                                                        <CurrencyFormat thousandSeparator="." decimalSeparator=","
                                                            value={validation.values.acquisitionValue ?? validation.acquisitionValue} customInput={Input} className={(validation.touched.acquisitionValue && validation.errors.acquisitionValue ? " is-invalid text-end" : " text-end")}
                                                            name="acquisitionValue" onValueChange={handleChangeAcquisitionValue} />
                                                        {validation.touched.acquisitionValue && validation.errors.acquisitionValue ? (
                                                            <FormFeedback className="d-block" type="invalid">{validation.errors.acquisitionValue}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col className="col-12 col-sm-4">
                                                        <Label>Usage Life (Year)</Label><Label className="text-danger">*</Label>
                                                        <CurrencyFormat thousandSeparator="." decimalSeparator=","
                                                            value={validation.values.usageLife ?? validation.usageLife} customInput={Input} className={(validation.touched.usageLife && validation.errors.usageLife ? " is-invalid text-end" : " text-end")}
                                                            name="usageLife" onValueChange={handleChangeUsageLife} />
                                                        {validation.touched.usageLife && validation.errors.usageLife ? (
                                                            <FormFeedback className="d-block" type="invalid">{validation.errors.usageLife}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                    <Col className="col-12 col-sm-4">
                                                        <Label>Intangible</Label><Label className="text-danger">*</Label>
                                                        <div
                                                            style={validation.touched.isIntangible && validation.errors.isIntangible ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                { border: '0', borderRadius: '0.25rem' }}
                                                        >
                                                            <Select
                                                                value={selectedIsIntangible}
                                                                onChange={handleSelectIsIntangible}
                                                                onBlur={validation.handleBlur}
                                                                options={optionIsIntangible}
                                                                isLoading={false}
                                                                className="select2-selection"
                                                            />
                                                        </div>
                                                        {validation.touched.isIntangible && validation.errors.isIntangible ? (
                                                            <FormFeedback className="d-block" type="invalid">{validation.errors.isIntangible}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                </Row>

                                                {
                                                    !loading &&
                                                    <div className="mt-3 mb-2">
                                                        <Link to="/asset">
                                                            <Button
                                                                color="secondary"
                                                                className="btn btn-secondary float-end"
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Link>
                                                        <Button
                                                            color="primary"
                                                            className="btn btn-primary float-end me-2"
                                                            type="submit"
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                }
                                            </Form>
                                        </Col>
                                    </Row>
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

AssetCreateUpdate.propTypes = {
    history: PropTypes.object,
};

export default withRouter(AssetCreateUpdate)
