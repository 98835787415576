import {
  GET_LIST_ROLE,
  GET_LIST_ROLE_SUCCESS,
  GET_LIST_ROLE_FAIL,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  listRole: [],
  role: null,
  error: '',
  loading: false,
};

const Role = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_ROLE:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_ROLE_SUCCESS:
      return {
        ...state,
        listRole: action.payload,
        loading: false,
      };
    case GET_LIST_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ROLE:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload,
        loading: false,
      };
    case GET_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_ROLE:
      return {
        ...state,
        loading: true,
      };
    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_ROLE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_ROLE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Role;
