import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { CardTitle, Col, Label, Modal, ModalBody, ModalHeader, Row, Input } from "reactstrap"

const ModalUploadTemplate = ({ show, onSubmit, onCancel, company }) => {
    const [fileUpload, setFileUpload] = useState(null)

    useEffect(() => {
        setFileUpload(null)
    }, [show])

    return (
        <Modal isOpen={show} toggle={onCancel} centered={true}>
            <ModalHeader>
                <CardTitle>Upload Template Report - {company?.name}</CardTitle>
            </ModalHeader>
            <ModalBody className="p-3">
                <Row>
                    <Col lg={12}>
                        <div className="text-start">
                            <Label>Template XLSX</Label>
                            <Input className="form-control" type="file" accept=".xlsx" onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    setFileUpload(e.target.files[0])
                                }
                                else {
                                    setFileUpload(null)
                                }
                            }} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end mt-3">
                            <button
                                type="button"
                                className="btn btn-success ms-2"
                                onClick={() => {
                                    onSubmit(fileUpload)
                                }}
                                disabled={!fileUpload}>
                                Upload
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger ms-2"
                                onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

ModalUploadTemplate.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    show: PropTypes.any,
    company: PropTypes.any,
}

export default ModalUploadTemplate
