import {
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  ADD_COMPANY,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_FAIL,
  IMPORT_ACCOUNT_COMPANY,
  IMPORT_ACCOUNT_COMPANY_SUCCESS,
  IMPORT_ACCOUNT_COMPANY_FAIL,
  UPLOAD_TEMPLATE_REPORT,
  UPLOAD_TEMPLATE_REPORT_SUCCESS,
  UPLOAD_TEMPLATE_REPORT_FAIL,
  EXPORT_ACCOUNT_LIST,
  EXPORT_ACCOUNT_LIST_SUCCESS,
  EXPORT_ACCOUNT_LIST_FAIL,
  UPLOAD_TEMPLATE_REPORT_PROFITLOSS,
  UPLOAD_TEMPLATE_REPORT_PROFITLOSS_SUCCESS,
  UPLOAD_TEMPLATE_REPORT_PROFITLOSS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  companies: [],
  company: null,
  error: '',
  loading: false,
};

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };
    case GET_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        loading: false,
      };
    case GET_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case ADD_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case UPDATE_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case DELETE_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case IMPORT_ACCOUNT_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case IMPORT_ACCOUNT_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case IMPORT_ACCOUNT_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPLOAD_TEMPLATE_REPORT:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_TEMPLATE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_TEMPLATE_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPLOAD_TEMPLATE_REPORT_PROFITLOSS:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_TEMPLATE_REPORT_PROFITLOSS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_TEMPLATE_REPORT_PROFITLOSS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case EXPORT_ACCOUNT_LIST:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EXPORT_ACCOUNT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Company;
