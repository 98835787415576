import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_ACCOUNT_NUMBER_DASHBOARD,
    GET_ACCOUNT_NUMBER_DASHBOARD_SUCCESS,
    GET_ACCOUNT_NUMBER_DASHBOARD_FAILED,
    GET_DATA_DASHBOARD,
    GET_DATA_DASHBOARD_SUCCESS,
    GET_DATA_DASHBOARD_FAILED,
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});

export const getAccountNumberDashboard = filter => ({
    type: GET_ACCOUNT_NUMBER_DASHBOARD,
    payload: filter
});

export const getAccountNumberDashboardSuccess = data => ({
    type: GET_ACCOUNT_NUMBER_DASHBOARD_SUCCESS,
    payload : data
});

export const getAccountNumberDashboardFailed = () => ({
    type: GET_ACCOUNT_NUMBER_DASHBOARD_FAILED,
});

export const getDataDashboard = filter => ({
    type: GET_DATA_DASHBOARD,
    payload: filter
});

export const getDataDashboardSuccess = data => ({
    type: GET_DATA_DASHBOARD_SUCCESS,
    payload : data
});

export const getDataDashboardFailed = () => ({
    type: GET_DATA_DASHBOARD_FAILED,
});
