import {
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  ADD_COMPANY,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_FAIL,
  IMPORT_ACCOUNT_COMPANY,
  IMPORT_ACCOUNT_COMPANY_SUCCESS,
  IMPORT_ACCOUNT_COMPANY_FAIL,
  UPLOAD_TEMPLATE_REPORT,
  UPLOAD_TEMPLATE_REPORT_SUCCESS,
  UPLOAD_TEMPLATE_REPORT_FAIL,
  EXPORT_ACCOUNT_LIST,
  EXPORT_ACCOUNT_LIST_SUCCESS,
  EXPORT_ACCOUNT_LIST_FAIL,
  UPLOAD_TEMPLATE_REPORT_PROFITLOSS,
  UPLOAD_TEMPLATE_REPORT_PROFITLOSS_SUCCESS,
  UPLOAD_TEMPLATE_REPORT_PROFITLOSS_FAIL,
} from "./actionTypes";

export const getCompanies = filter => ({
  type: GET_COMPANIES,
  payload: filter
});
export const getCompaniesSuccess = companies => ({
  type: GET_COMPANIES_SUCCESS,
  payload: companies,
});
export const getCompaniesFail = error => ({
  type: GET_COMPANIES_FAIL,
  payload: error,
});

export const getCompanyById = id => ({
  type: GET_COMPANY,
  payload: id
});
export const getCompanySuccess = company => ({
  type: GET_COMPANY_SUCCESS,
  payload: company,
});
export const getCompanyFail = error => ({
  type: GET_COMPANY_FAIL,
  payload: error,
});

export const addNewCompany = (company, history) => ({
  type: ADD_COMPANY,
  payload: { company, history },
});
export const addCompanyFail = error => ({
  type: ADD_COMPANY_FAIL,
  payload: error,
});

export const updateCompany = (company, history, toastr) => ({
  type: UPDATE_COMPANY,
  payload: { company, history, notif: toastr },
});
export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
});

export const deleteCompany = (company, filter) => ({
  type: DELETE_COMPANY,
  payload: { company, filter },
});
export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
});

export const importAccount = data => ({
  type: IMPORT_ACCOUNT_COMPANY,
  payload: { data }
});
export const importAccountSuccess = () => ({
  type: IMPORT_ACCOUNT_COMPANY_SUCCESS,
});
export const importAccountFail = error => ({
  type: IMPORT_ACCOUNT_COMPANY_FAIL,
  payload: error,
});

export const uploadTemplate = (uploadFile, companyId, filter) => ({
  type: UPLOAD_TEMPLATE_REPORT,
  payload: { uploadFile, companyId, filter },
});
export const uploadTemplateSuccess = () => ({
  type: UPLOAD_TEMPLATE_REPORT_SUCCESS,
});
export const uploadTemplateFail = error => ({
  type: UPLOAD_TEMPLATE_REPORT_FAIL,
  payload: error,
});

export const uploadTemplateProfitLoss = (uploadFile, companyId, filter) => ({
  type: UPLOAD_TEMPLATE_REPORT_PROFITLOSS,
  payload: { uploadFile, companyId, filter },
});
export const uploadTemplateProfitLossSuccess = () => ({
  type: UPLOAD_TEMPLATE_REPORT_PROFITLOSS_SUCCESS,
});
export const uploadTemplateProfitLossFail = error => ({
  type: UPLOAD_TEMPLATE_REPORT_PROFITLOSS_FAIL,
  payload: error,
});

export const exportAccountList = data => ({
  type: EXPORT_ACCOUNT_LIST,
  payload: { data }
});
export const exportAccountListSuccess = () => ({
  type: EXPORT_ACCOUNT_LIST_SUCCESS,
});
export const exportAccountListFail = error => ({
  type: EXPORT_ACCOUNT_LIST_FAIL,
  payload: error,
});
