import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { IsUserAuthenticated } from "helpers/backend_helper"
import { MENU } from "constants/menuList"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  history,
  ...rest
}) => {
  var find = MENU.filter(s => s.path == rest.path)
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !IsUserAuthenticated(find, history)) {
          return (
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          )
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
