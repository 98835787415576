export const GET_LIST_USER = "GET_LIST_USER";
export const GET_LIST_USER_SUCCESS = "GET_LIST_USER_SUCCESS";
export const GET_LIST_USER_FAIL = "GET_LIST_USER_FAIL";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const CLEAR_LIST_USER = "CLEAR_LIST_USER";

export const RESET_PASSWORD_USER = "RESET_PASSWORD_USER";
export const RESET_PASSWORD_USER_SUCCESS = "RESET_PASSWORD_USER_SUCCESS";
export const RESET_PASSWORD_USER_FAIL = "RESET_PASSWORD_USER_FAIL";

export const CHANGE_PASSWORD_USER = "CHANGE_PASSWORD_USER";
export const CHANGE_PASSWORD_USER_SUCCESS = "CHANGE_PASSWORD_USER_SUCCESS";
export const CHANGE_PASSWORD_USER_FAIL = "CHANGE_PASSWORD_USER_FAIL";