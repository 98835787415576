import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"
import fileDownload from 'js-file-download'

// Calender Redux States
import {
    GET_LIST_ACCOUNT_TRANSACTION,
    GET_ACCOUNT_TRANSACTION,
    ADD_ACCOUNT_TRANSACTION,
    UPDATE_ACCOUNT_TRANSACTION,
    DELETE_ACCOUNT_TRANSACTION,
    ADD_BY_EXCEL_ACCOUNT_TRANSACTION,
    EXPORT_EXCEL_ACCOUNT_TRANSACTION
} from "./actionTypes"
import {
    getListAccountTransactionSuccess,
    getListAccountTransactionFail,
    getAccountTransactionSuccess,
    getAccountTransactionFail,
    addAccountTransactionSuccess,
    addAccountTransactionFail,
    updateAccountTransactionSuccess,
    updateAccountTransactionFail,
    deleteAccountTransactionSuccess,
    deleteAccountTransactionFail,
    addAccountTransactionByExcelSuccess,
    addAccountTransactionByExcelFail,
    exportExcelAccountTransactionSuccess,
    exportExcelAccountTransactionFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getListAccountTransaction,
    getAccountTransaction,
    addAccountTransaction,
    updateAccountTransaction,
    deleteAccountTransaction,
    addAccountTransactionByExcel,
    exportExcelAccountTransaction
} from "../../helpers/backend_helper"

function* onFetchListTransaction({ payload: filter }) {
    try {
        const response = yield call(getListAccountTransaction, filter)
        if (response.status) {
            yield put(getListAccountTransactionSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getListAccountTransactionFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getListAccountTransactionFail(error))
    }
}

function* onGetAccountTransaction({ payload: filter }) {
    try {
        const response = yield call(getAccountTransaction, filter)
        if (response.status) {
            yield put(getAccountTransactionSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getAccountTransactionFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getAccountTransactionFail(error))
    }
}

function* onAddAccountTransaction({ payload: { accountTransaction, history } }) {
    try {
        const response = yield call(addAccountTransaction, accountTransaction)
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_TRANSACTION_ADD_SUCCESS)
            yield put(addAccountTransactionSuccess())
            history.push("/account-transaction")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addAccountTransactionFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addAccountTransactionFail(error))
    }
}

function* onUpdateAccountTransaction({ payload: { accountTransaction, history } }) {
    try {
        const response = yield call(updateAccountTransaction, accountTransaction)
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_TRANSACTION_UPDATE_SUCCESS)
            yield put(updateAccountTransactionSuccess())
            history.push("/account-transaction")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updateAccountTransactionFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updateAccountTransactionFail(error))
    }
}

function* onDeleteAccountTransaction({ payload: { accountTransaction, filter } }) {
    try {
        const response = yield call(deleteAccountTransaction, accountTransaction)
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_TRANSACTION_DELETE_SUCCESS)
            yield put(deleteAccountTransactionSuccess())
            const responseNewData = yield call(getListAccountTransaction, filter)
            if (responseNewData.status) {
                yield put(getListAccountTransactionSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getListAccountTransactionFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deleteAccountTransactionFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deleteAccountTransactionFail(error))
    }
}

function* onAddAccountTransactionByExcel({ payload: { uploadFile, companyId, history } }) {
    try {
        const response = yield call(addAccountTransactionByExcel, uploadFile, {
            companyId: companyId
        })
        if (response.status) {
            NotifHelper.success(msg.ACCOUNT_TRANSACTION_ADD_SUCCESS)
            yield put(addAccountTransactionByExcelSuccess())
            history.push("/account-transaction")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addAccountTransactionByExcelFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addAccountTransactionByExcelFail(error))
    }
}

function* onExportExcelAccountTransaction({ payload: { filter } }) {
    try {
        const response = yield call(exportExcelAccountTransaction, filter, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        let endFileNameIndex = headerLine.lastIndexOf(';');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        fileDownload(response.data, filename)
        yield put(exportExcelAccountTransactionSuccess())
    } catch (error) {
        NotifHelper.error(error)
        yield put(exportExcelAccountTransactionFail(error))
    }
}

function* accountTransactionSaga() {
    yield takeEvery(GET_LIST_ACCOUNT_TRANSACTION, onFetchListTransaction)
    yield takeEvery(GET_ACCOUNT_TRANSACTION, onGetAccountTransaction)
    yield takeEvery(ADD_ACCOUNT_TRANSACTION, onAddAccountTransaction)
    yield takeEvery(UPDATE_ACCOUNT_TRANSACTION, onUpdateAccountTransaction)
    yield takeEvery(DELETE_ACCOUNT_TRANSACTION, onDeleteAccountTransaction)
    yield takeEvery(ADD_BY_EXCEL_ACCOUNT_TRANSACTION, onAddAccountTransactionByExcel)
    yield takeEvery(EXPORT_EXCEL_ACCOUNT_TRANSACTION, onExportExcelAccountTransaction)
}

export default accountTransactionSaga