import {
  GET_LIST_ASSET_TYPE,
  GET_LIST_ASSET_TYPE_SUCCESS,
  GET_LIST_ASSET_TYPE_FAIL,
  GET_ASSET_TYPE,
  GET_ASSET_TYPE_SUCCESS,
  GET_ASSET_TYPE_FAIL,
  ADD_ASSET_TYPE,
  ADD_ASSET_TYPE_SUCCESS,
  ADD_ASSET_TYPE_FAIL,
  UPDATE_ASSET_TYPE,
  UPDATE_ASSET_TYPE_SUCCESS,
  UPDATE_ASSET_TYPE_FAIL,
  DELETE_ASSET_TYPE,
  DELETE_ASSET_TYPE_SUCCESS,
  DELETE_ASSET_TYPE_FAIL,
  CLEAR_GET_LIST_ASSET_TYPE,
} from "./actionTypes";

const INIT_STATE = {
  listAssetType: [],
  assetType: null,
  error: '',
  loading: false
};

const AssetType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_ASSET_TYPE:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        listAssetType: action.payload,
        loading: false,
      };
    case GET_LIST_ASSET_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ASSET_TYPE:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        assetType: action.payload,
        loading: false,
      };
    case GET_ASSET_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_ASSET_TYPE:
      return {
        ...state,
        loading: true,
      };
    case ADD_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ASSET_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_ASSET_TYPE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ASSET_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_ASSET_TYPE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ASSET_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CLEAR_GET_LIST_ASSET_TYPE:
      return {
        ...state,
        listAssetType: [],
      };

    default:
      return state;
  }
};

export default AssetType;
