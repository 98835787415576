import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes, { func } from "prop-types";
import { withRouter, Link } from 'react-router-dom';
import {
    Container,
    Button,
    Col,
    Row,
    FormGroup,
    InputGroup,
    Form,
    FormFeedback,
    Label,
    Card,
    CardBody,
    Input
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CurrencyFormat from 'react-currency-format';

import {
    getPiutangById as onGetPiutangById,
    updatePiutang as onUpdatePiutang,
    addPiutang as onAddPiutang,
    getCompanies as onGetCompanies,
    getListAccount as onGetAccounts
} from "../../store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const PiutangCreateUpdate = (props) => {

    //meta title
    document.title = "Add Piutang | Des Counts";
    const id = props?.match?.params?.id;
    const companyId = props?.match?.params?.companyId;
    const isEdit = id != undefined || companyId != null;

    const { companies, accounts, piutang, loading, loadingAccount, error } = useSelector(state => ({
        companies: state.Company.companies,
        accounts: state.Account.listAccount,
        loadingAccount: state.Account.loading,
        piutang: state.Piutang.piutang,
        loading: state.Piutang.loading,
        error: state.Piutang.error,
    }));

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e)
        validation.setFieldValue("companyId", e.value.toString(), true)
    }

    const [selectedAccount, setSelectedAccount] = useState(null);
    const [optionAccount, setOptionAccount] = useState([]);
    function handleSelectAccount(e) {
        setSelectedAccount(e)
        validation.setFieldValue("accountId", e.value.toString(), true)
    }

    const [selectedType, setSelectedType] = useState(null);
    const [optionType, setOptionType] = useState([
        { label: "CREDIT", value: "credit" },
        { label: "DEBIT", value: "debit" },
    ]);
    function handleSelectType(e) {
        setSelectedType(e)
        validation.setFieldValue("type", e.value, true)
    }

    function handleChangeNominal(e) {
        validation.setFieldValue("nominal", e.floatValue, true)
    }

    function handleDateChange(e) {
        const dd = e[0].getDate().toString();
        const mm = e[0].getMonth() + 1;
        const yyyy = e[0].getFullYear();
        validation.setFieldValue("date", String(yyyy).padStart(2, '0') + "-" + String(mm).padStart(2, '0') + "-" + String(dd).padStart(2, '0'), true)
    }

    function handleDateReminderChange(e){
        const dd = e[0].getDate().toString();
        const mm = e[0].getMonth() + 1;
        const yyyy = e[0].getFullYear();
        validation.setFieldValue("reminderDate", String(yyyy).padStart(2, '0') + "-" + String(mm).padStart(2, '0') + "-" + String(dd).padStart(2, '0'), true)
    }

    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyId: isEdit && piutang ? piutang.companyId : "",
            accountId: isEdit && piutang ? piutang.accountId : "",
            date: isEdit && piutang ? piutang.date : "",
            type: isEdit && piutang ? piutang.type : "",
            nominal: isEdit && piutang ? piutang.nominal : null,
            reminderDate: isEdit && piutang ? piutang.reminderDate : null,
            remarks: isEdit && piutang ? piutang.remarks : "",
        },
        validationSchema: Yup.object({
            companyId: Yup.string().required("Please Select Company"),
            accountId: Yup.string().required("Please Select Account"),
            date: Yup.string().required("Please Select Date"),
            type: Yup.string().required("Please Select Type"),
            nominal: Yup.string().required("Please Select Type"),
            remarks: Yup.string().required("Remarks is required!"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                // dispatch(onUpdatePeriod({ id: period.id, name: values.name }, props.history));
            } else {
                dispatch(onAddPiutang({
                    companyId: parseInt(values.companyId),
                    accountId: parseInt(values.accountId),
                    date: values.date,
                    type: values.type,
                    remarks: values.remarks,
                    nominal: values.nominal,
                    reminderDate: values.reminderDate,
                }, props.history));
            }
        },
    });

    
    useEffect(() => {
        dispatch(onGetCompanies({}))
        if (isEdit) {
            dispatch(onGetPiutangById(id, companyId))
        }
    }, [dispatch])

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id.toString() }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (accounts) {
            setOptionAccount(accounts.map((item) => {
                return { label: item.code + " - " + item.name, value: item.id.toString() }
            }))
        }
    }, [accounts])

    useEffect(() => {
        if (isEdit && piutang && companies) {
            var company = optionCompany.filter(s => s.value == piutang.companyId);
            if (company.length > 0) {
                setSelectedCompany(company[0])
            }
        }
    }, [optionCompany])

    useEffect(() => {
        if (selectedCompany) {
            dispatch(onGetAccounts({ companyId: selectedCompany.value }))
        }
    }, [selectedCompany])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Piutang" breadcrumbItem={(isEdit ? "Edit" : "Add") + " Piutang"} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {
                                        !loading &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <Form
                                                        className="form-horizontal"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col className="col-12 col-sm-4">
                                                                <Label>Company</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.companyId && validation.errors.companyId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedCompany}
                                                                        onChange={handleSelectCompany}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionCompany}
                                                                        isDisabled={isEdit}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                                {validation.touched.companyId && validation.errors.companyId ? (
                                                                    <FormFeedback className="d-block" type="invalid">Please select company</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-4">
                                                                <Label>Account</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.accountId && validation.errors.accountId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedAccount}
                                                                        onChange={handleSelectAccount}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionAccount}
                                                                        isLoading={loadingAccount}
                                                                        isDisabled={selectedCompany == null}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                                {validation.touched.accountId && validation.errors.accountId ? (
                                                                    <FormFeedback className="d-block" type="invalid">Please select account</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-4">
                                                                <FormGroup>
                                                                    <Label>Date</Label><Label className="text-danger">*</Label>
                                                                    <InputGroup
                                                                        style={validation.touched.date && validation.errors.date ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                            { border: '0', borderRadius: '0.25rem' }}>
                                                                        <Flatpickr
                                                                            className={"form-control d-block" + (validation.errors.date ? "is-invalid" : "")}
                                                                            placeholder="Select Date"
                                                                            options={{
                                                                                dateFormat: "Y-m-d"
                                                                            }}
                                                                            onChange={handleDateChange}
                                                                        />
                                                                    </InputGroup>
                                                                    {validation.touched.date && validation.errors.date ? (
                                                                        <FormFeedback className="d-block" type="invalid">Please select date</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className="col-12 col-sm-4">
                                                                <Label>Type</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.type && validation.errors.type ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedType}
                                                                        onChange={handleSelectType}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionType}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                                {validation.touched.type && validation.errors.type ? (
                                                                    <FormFeedback className="d-block" type="invalid">Please select type</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-4">
                                                                <Label>Nominal</Label><Label className="text-danger">*</Label>
                                                                <CurrencyFormat thousandSeparator="." decimalSeparator=","
                                                                    value={validation.nominal} customInput={Input} className={(validation.touched.nominal && validation.errors.nominal ? " is-invalid text-end" : " text-end")}
                                                                    name="nominal" onValueChange={handleChangeNominal} />
                                                                {validation.touched.nominal && validation.errors.nominal ? (
                                                                    <FormFeedback className="d-block" type="invalid">Please input nominal</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-4">
                                                                <FormGroup>
                                                                    <Label>Reminder Date</Label>
                                                                    <InputGroup>
                                                                        <Flatpickr
                                                                            className={"form-control d-block"}
                                                                            placeholder="Select Date"
                                                                            options={{
                                                                                dateFormat: "Y-m-d"
                                                                            }}
                                                                            onChange={handleDateReminderChange}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mt-2">
                                                            <Col className="col-12 col-sm-12">
                                                                <div>
                                                                    <Label className="form-label">Remarks</Label><Label className="text-danger">*</Label>
                                                                    <Input
                                                                        name="remarks"
                                                                        className="form-control"
                                                                        placeholder="Enter remarks"
                                                                        type="text"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.remarks || ""}
                                                                        invalid={
                                                                            validation.touched.remarks && validation.errors.remarks ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.remarks && validation.errors.remarks ? (
                                                                        <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <div className="mt-3 mb-2">
                                                            <Link to="/piutang">
                                                                <Button
                                                                    color="secondary"
                                                                    className="btn btn-secondary float-end"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                color="primary"
                                                                className="btn btn-primary float-end me-2"
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

PiutangCreateUpdate.propTypes = {
    history: PropTypes.object,
};

export default withRouter(PiutangCreateUpdate)
