import {
  GET_LIST_ACCOUNT_NUMBER,
  GET_LIST_ACCOUNT_NUMBER_SUCCESS,
  GET_LIST_ACCOUNT_NUMBER_FAIL,
  GET_ACCOUNT_NUMBER,
  GET_ACCOUNT_NUMBER_SUCCESS,
  GET_ACCOUNT_NUMBER_FAIL,
  ADD_ACCOUNT_NUMBER,
  ADD_ACCOUNT_NUMBER_SUCCESS,
  ADD_ACCOUNT_NUMBER_FAIL,
  UPDATE_ACCOUNT_NUMBER,
  UPDATE_ACCOUNT_NUMBER_FAIL,
  DELETE_ACCOUNT_NUMBER,
  DELETE_ACCOUNT_NUMBER_FAIL,
  UPDATE_ACCOUNT_NUMBER_SUCCESS,
  CLEAR_LIST_ACCOUNT_NUMBER,
} from "./actionTypes";

const INIT_STATE = {
  listAccountNumber: [],
  accountNumber: null,
  error: '',
  loading: false
};

const AccountNumber = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_ACCOUNT_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        listAccountNumber: action.payload,
        loading: false,
      };
    case GET_LIST_ACCOUNT_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ACCOUNT_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        accountNumber: action.payload,
        loading: false,
      };
    case GET_ACCOUNT_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_ACCOUNT_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case ADD_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ACCOUNT_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_ACCOUNT_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ACCOUNT_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_ACCOUNT_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACCOUNT_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CLEAR_LIST_ACCOUNT_NUMBER:
      return {
        ...state,
        listAccountNumber: [],
        loading: false,
      };

    default:
      return state;
  }
};

export default AccountNumber;
