import React, { useState, useMemo, useEffect, useRef, createRef } from "react";
import PropTypes, { func } from "prop-types";
import { withRouter, Link } from 'react-router-dom';
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Alert,
    InputGroup
} from "reactstrap";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
    getUserById as onGetUser,
    updateUser as onUpdateUser,
    addUser as onAddUser,
    getListRole as onGetRoles,
    getCompanies as onGetCompanies
} from "../../store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const UserCreateUpdate = (props) => {

    //meta title
    document.title = "User | User Management | Des Counts";
    const id = props?.match?.params?.id;
    const isEdit = id != undefined;
    const [companySet, setCompanySet] = useState([])

    const { user, loading, companies, roles, loadingRole } = useSelector(state => ({
        user: state.User.user,
        loading: state.User.loading,
        companies: state.Company.companies,
        roles: state.Role.listRole,
        loadingRole: state.Role.loading,
    }));

    const [selectedRole, setSelectedRole] = useState(null);
    const [optionRole, setOptionRole] = useState([]);
    function handleSelectRole(e) {
        setSelectedRole(e);
        validation.setFieldValue("roleId", e ? e.value : null, true)
    }

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }

    function handleAddCompany() {
        if (selectedCompany) {
            var comSet = companySet;
            comSet.map(item => {
                if (item.id == selectedCompany.value) {
                    item.selected = true;
                }
            })
            setCompanySet(comSet)
        }
    }

    function deleteCompany(id) {
        var comSet = companySet;
        comSet.map(item => {
            if (item.id == id) {
                item.selected = false;
            }
        })
        setCompanySet(comSet)
    }

    const getCompanyAccess = async function () {
        let res = []
        companySet.map((item) => {
            if (item.selected) {
                res.push(item.id)
            }
        })
        return res
    }

    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: isEdit && user ? user.name : "",
            email: isEdit && user ? user.username : "",
            password: "",
            roleId: isEdit && user ? user.roleId : null
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            email: Yup.string().email("Please Enter Valid Email").required("Please Enter Email"),
            password: isEdit ? null : Yup.string().min(8, "Password must be at least 8 character").required("Please Enter Password"),
            roleId: Yup.string().required("Please Select Role").nullable(),
        }),
        onSubmit: (values) => {
            getCompanyAccess().then(s => {
                if (isEdit) {
                    dispatch(onUpdateUser({ 
                        id: user.id, 
                        name: values.name, 
                        username: values.email, 
                        roleId: values.roleId, 
                        company: s 
                    }, props.history));
                } else {
                    dispatch(onAddUser({
                        name: values.name,
                        username: values.email, 
                        roleId: values.roleId, 
                        password: values.password,
                        company: s
                    }, props.history));
                }
            })
        },
    });

    useEffect(() => {
        if (isEdit) {
            dispatch(onGetUser(id))
        }
        dispatch(onGetRoles())
        dispatch(onGetCompanies())
    }, [dispatch])

    useEffect(() => {
        if (companies) {
            setCompanySet(companies.map((item) => {
                return { id: item.id, name: item.name, selected: false }
            }))
            setOptionCompany(companies.map((item) => {
                return { value: item.id, label: item.name }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (roles) {
            setOptionRole(roles.map((item) => {
                return { value: item.id, label: item.name }
            }))
        }
    }, [roles])

    useEffect(() => {
        if (user && companySet) {
            var otherCompany = companySet.filter(s => !(user.companyId.includes(s.id)))
            var companyUser = companies.filter(s => user.companyId.includes(s.id))
            if (companyUser && companyUser.length > 0) {
                companyUser.map(item => {
                    otherCompany.push({ id: item.id, name: item.name, selected: true })
                })
                setCompanySet(otherCompany)
            }
        }
    }, [user])

    useEffect(() => {
        if (isEdit && user && roles) {
            var role = optionRole.filter(s => s.value == user.roleId);
            if (role.length > 0) {
                setSelectedRole(role[0])
            }
        }
    }, [optionRole])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="User Management" breadcrumbItem={(isEdit ? "Edit" : "Add") + " User"} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {
                                        !loading &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <Form
                                                        className="form-horizontal"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col className="col-12 col-sm-6">
                                                                <Label className="form-label">Name</Label>
                                                                <Input
                                                                    name="name"
                                                                    className="form-control"
                                                                    placeholder="Enter name"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.name || ""}
                                                                    invalid={
                                                                        validation.touched.name && validation.errors.name ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.name && validation.errors.name ? (
                                                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-6">
                                                                <Label className="form-label">Email</Label>
                                                                <Input
                                                                    name="email"
                                                                    className="form-control"
                                                                    placeholder="Enter email"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.email || ""}
                                                                    invalid={
                                                                        validation.touched.email && validation.errors.email ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.email && validation.errors.email ? (
                                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-3">
                                                            <Col className="col col-sm-6">
                                                                <div>
                                                                    <Label>Role</Label>
                                                                    <Select
                                                                        value={selectedRole}
                                                                        onChange={handleSelectRole}
                                                                        options={optionRole}
                                                                        isLoading={loadingRole}
                                                                        isClearable={false}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            {
                                                                !isEdit &&
                                                                <Col className="col col-sm-6">
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Password</Label>
                                                                        <Input
                                                                            name="password"
                                                                            className="form-control"
                                                                            autoComplete="new-password"
                                                                            id="new-password"
                                                                            placeholder="Enter password"
                                                                            type="password"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation.values.password || ""}
                                                                            invalid={
                                                                                validation.touched.password && validation.errors.password ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.password && validation.errors.password ? (
                                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            }

                                                        </Row>

                                                        <Row className="mt-3 d-flex">
                                                            <Col className="col col-sm-10">
                                                                <div>
                                                                    <Label>Company</Label>
                                                                    <Select
                                                                        value={selectedCompany}
                                                                        onChange={handleSelectCompany}
                                                                        options={optionCompany}
                                                                        isClearable={false}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col className="col col-sm-2 align-self-end">
                                                                <Button color="primary"
                                                                    className="btn btn-primary float-end"
                                                                    block={true}
                                                                    type="button"
                                                                    onClick={handleAddCompany}>
                                                                    Add
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mt-5">
                                                            <Col>
                                                                <h4>Company Access</h4>
                                                                <div className="mt-3 d-flex">
                                                                    {
                                                                        companySet && companySet.length > 0 &&
                                                                        companySet.map((item) => {
                                                                            if (item.selected) {
                                                                                return <div key={'com' + item.id} className={'bg-primary rounded p-2 me-2 text-white'}>
                                                                                    {item.name}
                                                                                    <Link to={'#'} onClick={() => deleteCompany(item.id)}>
                                                                                        <i className="fa fa-trash text-danger ms-2"></i>
                                                                                    </Link>
                                                                                </div>
                                                                            }
                                                                        })
                                                                    }
                                                                    {
                                                                        ((companySet && companySet.filter(s => s.selected).length == 0) || !companySet) &&
                                                                        <small className="ms-2">No Data</small>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <div className="mt-5 mb-2">
                                                            <Link to="/usermanagement/user">
                                                                <Button
                                                                    color="secondary"
                                                                    className="btn btn-secondary float-end"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                color="primary"
                                                                className="btn btn-primary float-end me-2"
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

UserCreateUpdate.propTypes = {
    history: PropTypes.object,
};

export default withRouter(UserCreateUpdate)
