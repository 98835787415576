import {
  GET_LIST_ACCOUNT,
  GET_LIST_ACCOUNT_SUCCESS,
  GET_LIST_ACCOUNT_FAIL,
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAIL,
  ADD_ACCOUNT,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAIL,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_FAIL,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAIL,
  UPDATE_ACCOUNT_SUCCESS,
  CLEAR_LIST_ACCOUNT,
} from "./actionTypes";

const INIT_STATE = {
  listAccount: [],
  account: null,
  error: '',
  loading: false
};

const Account = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_ACCOUNT_SUCCESS:
      return {
        ...state,
        listAccount: action.payload,
        loading: false,
      };
    case GET_LIST_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload,
        loading: false,
      };
    case GET_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CLEAR_LIST_ACCOUNT:
      return {
        ...state,
        listAccount: [],
      };

    default:
      return state;
  }
};

export default Account;
