import {
  GET_LIST_USER,
  GET_LIST_USER_SUCCESS,
  GET_LIST_USER_FAIL,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_LIST_USER,
  RESET_PASSWORD_USER,
  RESET_PASSWORD_USER_SUCCESS,
  RESET_PASSWORD_USER_FAIL,
  CHANGE_PASSWORD_USER,
  CHANGE_PASSWORD_USER_SUCCESS,
  CHANGE_PASSWORD_USER_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  listUser: [],
  user: null,
  error: '',
  loading: false,
  loadingResetPassword: false,
  newResetPassword: null
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_USER_SUCCESS:
      return {
        ...state,
        listUser: action.payload,
        loading: false,
      };
    case GET_LIST_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case GET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_USER:
      return {
        ...state,
        loading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_USER:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_USER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case RESET_PASSWORD_USER:
      return {
        ...state,
        newResetPassword: null,
        loadingResetPassword: true,
      };
    case RESET_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        newResetPassword: action.payload,
        loadingResetPassword: false,
      };
    case RESET_PASSWORD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingResetPassword: false,
      };

    case CLEAR_LIST_USER:
      return {
        ...state,
        listUser: [],
        loading: false,
      };

    case CHANGE_PASSWORD_USER:
      return {
        ...state,
        loading: true
      };
    case CHANGE_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CHANGE_PASSWORD_USER_FAIL:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default User;
