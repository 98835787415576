import React, { useState, useMemo, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Label,
    Card,
    CardBody,
    CardTitle,
    InputGroup,
    Input
} from "reactstrap";
import Select from "react-select";
import TableContainer from '../../components/Common/TableContainer';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanies as onGetCompanies,
    getPeriods as onGetPeriods,
    getListAccount as onGetAccounts,
    getListAccountNumber as onGetAccountNumbers,
    getListAccountTransaction as onGetAccountTransactions,
    deleteAccountTransaction as onDeleteAccountTransaction,
    clearPeriods as onClearPeriods,
    clearListAccount as onClearListAccount,
    clearListAccountNumber as onClearListAccountNumber,
    clearListAccountTransaction as onClearListAccountTransaction,
    exportExcelAccountTransaction as onExportExcelAccountTransaction
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import CurrencyFormat from 'react-currency-format';
import { upperCase } from "lodash";
import DeleteModal from "../../components/Common/ModalDelete";


const AccountTransaction = () => {

    //meta title
    document.title = "Transaction | Des Counts";

    const dispatch = useDispatch();
    const [accountTransaction, setAccountTransaction] = useState(null)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [remarks, setRemarks] = useState(null)
    const [tglMulaiFilter, setTglMulaiFilter] = useState(null)
    const [tglSampaiFilter, setTglSampaiFilter] = useState(null)

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [optionPeriod, setOptionPeriod] = useState([]);
    function handleSelectPeriod(e) {
        setSelectedPeriod(e);
    }
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [optionAccount, setOptionAccount] = useState([]);
    function handleSelectAccount(e) {
        setSelectedAccount(e);
    }
    const [selectedAccountNumber, setSelectedAccountNumber] = useState(null);
    const [optionAccountNumber, setOptionAccountNumber] = useState([]);
    function handleSelectAccountNumber(e) {
        setSelectedAccountNumber(e);
    }
    const [selectedFiscal, setSelectedFiscal] = useState(null);
    const [optionFiscal] = useState([
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]);
    function handleSelectFiscal(e) {
        setSelectedFiscal(e);
    }
    const [selectedAdjustment, setSelectedAdjustment] = useState(null);
    const [optionAdjustment] = useState([
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]);
    function handleSelectAdjustment(e) {
        setSelectedAdjustment(e);
    }

    const [selectedType, setSelectedType] = useState(null);
    const [optionType] = useState([
        { label: "DEBIT", value: "credit" },
        { label: "CREDIT", value: "debit" },
    ]);
    function handleSelectType(e) {
        setSelectedType(e);
    }

    function handleDateRangeChange(e) {
        if (e.length == 2) {
            const dd = e[0].getDate().toString();
            const mm = e[0].getMonth() + 1;
            const yyyy = e[0].getFullYear();
            setTglMulaiFilter(String(yyyy).padStart(2, '0') + "-" + String(mm).padStart(2, '0') + "-" + String(dd).padStart(2, '0'))
            const dd2 = e[1].getDate().toString();
            const mm2 = e[1].getMonth() + 1;
            const yyyy2 = e[1].getFullYear();
            setTglSampaiFilter(String(yyyy2).padStart(2, '0') + "-" + String(mm2).padStart(2, '0') + "-" + String(dd2).padStart(2, '0'))
        }
        else {
            setTglMulaiFilter(null)
            setTglSampaiFilter(null)
        }
    }

    const filterCompany = useRef("");
    const filterPeriod = useRef("");
    const filterAccount = useRef("");
    const filterAccountNumber = useRef("");
    const filterFiscal = useRef("");
    const filterAdjustment = useRef("");
    const filterDateRange = useRef(null);
    const filterType = useRef("");
    const filterRemarks = useRef(null);


    const {
        companies,
        loadingCompany,
        periods,
        loadingPeriod,
        accounts,
        loadingAccount,
        accountNumbers,
        loadingAccountNumber,
        accountTransactions,
        loading
    } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
        periods: state.Period.periods,
        loadingPeriod: state.Period.loading,
        accounts: state.Account.listAccount,
        loadingAccount: state.Account.loading,
        accountNumbers: state.AccountNumber.listAccountNumber,
        loadingAccountNumber: state.AccountNumber.loading,
        accountTransactions: state.AccountTransaction.listAccountTransaction,
        loading: state.AccountTransaction.loading,
    }));

    const columns = useMemo(
        () => [
            {
                Header: 'Period',
                accessor: 'period.name',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Date',
                accessor: 'dateStr',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Remarks',
                accessor: 'remarks',
                textAlign: 'text-start',
                headerAlign: 'text-start',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Account Code',
                accessor: 'account.code',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Account Name',
                accessor: 'account.name',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Account Number',
                accessor: 'accountNumber.name',
                textAlign: 'text-start',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Type',
                accessor: 'type',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return upperCase(cellProps.value);
                }
            },
            {
                Header: 'Nominal',
                accessor: 'nominal',
                textAlign: 'text-end',
                headerAlign: 'text-end',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <CurrencyFormat value={cellProps.value} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                        displayType={'text'} />;
                }
            },
            {
                Header: 'Fiscal',
                accessor: 'fiscal',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value ? "Yes" : "No";
                }
            },
            {
                Header: 'Adjustment',
                accessor: 'adjustment',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value ? "Yes" : "No";
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                headerCellStyle: 'text-center',
                style: {
                    textAlign: "center",
                },
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <Link
                                to={"/account-transaction/create/" + cellProps.row.original.id + "/" + cellProps.row.original.companyId
                                    + "/" + cellProps.row.original.periodId + "/" + cellProps.row.original.accountId
                                    + "/" + cellProps.row.original.accountNumberId}
                                className="text-success"
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    onOpenDeleteCommand(data);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    const onRefreshCommand = () => {
        if (selectedCompany && selectedPeriod) {
            dispatch(onGetAccountTransactions({
                companyId: selectedCompany ? selectedCompany.value : null,
                periodId: selectedPeriod ? selectedPeriod.value : null,
                accountId: selectedAccount ? selectedAccount.value : null,
                accountNumberId: selectedAccountNumber ? selectedAccountNumber.value : null,
                fiscal: selectedFiscal ? selectedFiscal.value : null,
                adjustment: selectedAdjustment ? selectedAdjustment.value : null,
                tglMulai: tglMulaiFilter && tglSampaiFilter ? tglMulaiFilter : null,
                tglSampai: tglMulaiFilter && tglSampaiFilter ? tglSampaiFilter : null,
                type: selectedType ? selectedType.value : null,
                remarks: remarks ? remarks : null,
            }));
        }
    }

    const onResetFilterCommand = () => {
        filterCompany.current.clearValue();
        filterPeriod.current.clearValue();
        filterAccount.current.clearValue();
        filterAccountNumber.current.clearValue();
        filterFiscal.current.clearValue();
        filterAdjustment.current.clearValue();
        filterDateRange.current.flatpickr.clear();
        filterType.current.clearValue();
        filterRemarks.current.value = null;
        setRemarks(null)
    }

    const onOpenDeleteCommand = (data) => {
        setAccountTransaction(data)
        setIsOpenDelete(true);
    }

    const onDeleteCommand = () => {
        setIsOpenDelete(false)
        dispatch(onDeleteAccountTransaction(accountTransaction, {
            companyId: selectedCompany ? selectedCompany.value : null,
            periodId: selectedPeriod ? selectedPeriod.value : null,
            accountId: selectedAccount ? selectedAccount.value : null,
            accountNumberId: selectedAccountNumber ? selectedAccountNumber.value : null,
            fiscal: selectedFiscal ? selectedFiscal.value : null,
            adjustment: selectedAdjustment ? selectedAdjustment.value : null,
            tglMulai: tglMulaiFilter && tglSampaiFilter ? tglMulaiFilter : null,
            tglSampai: tglMulaiFilter && tglSampaiFilter ? tglSampaiFilter : null,
        }))
    }

    const footer = () => {

        if (accountTransactions && accountTransactions.length > 0) {
            const totalNominal = accountTransactions.reduce((total, cur) => total + cur.nominal, 0);
            return <tfoot className="table-light table-nowrap">
                <tr>
                    <td colSpan={7}>Total</td>
                    <td className="text-end">
                        <CurrencyFormat value={totalNominal} displayType={'text'} decimalScale={0} thousandSeparator={'.'} decimalSeparator={','} />
                    </td>
                    <td colSpan={3}></td>
                </tr>
            </tfoot>
        }
        return null
    }

    useEffect(() => {
        onRefreshCommand();
    }, [dispatch]);

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])
    useEffect(() => {
        if (periods) {
            setOptionPeriod(periods.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [periods])
    useEffect(() => {
        if (accounts) {
            setOptionAccount(accounts.map((item) => {
                return { label: item.code + ' - ' + item.name, value: item.id }
            }))
        }
    }, [accounts])
    useEffect(() => {
        if (accountNumbers) {
            setOptionAccountNumber(accountNumbers.map((item) => {
                return { label: item.name + ' - ' + item.number, value: item.id }
            }))
        }
    }, [accountNumbers])

    useEffect(() => {
        if (selectedCompany) {
            dispatch(onClearPeriods())
            dispatch(onClearListAccount())
            dispatch(onClearListAccountNumber())
            dispatch(onClearListAccountTransaction())
            filterPeriod.current.clearValue()
            filterAccount.current.clearValue()
            filterAccountNumber.current.clearValue()
            dispatch(onGetPeriods({ companyId: selectedCompany.value }));
            dispatch(onGetAccounts({ companyId: selectedCompany.value }));
            dispatch(onGetAccountNumbers({ companyId: selectedCompany.value }));
        }
    }, [selectedCompany])

    useEffect(() => {
        if (!selectedCompany || !selectedPeriod) {
            dispatch(onClearListAccountTransaction());
        }
    }, [selectedCompany, selectedPeriod])

    const onExportExcel = () => {
        if (selectedCompany && selectedPeriod) {
            dispatch(onExportExcelAccountTransaction({
                companyId: selectedCompany ? selectedCompany.value : null,
                periodId: selectedPeriod ? selectedPeriod.value : null,
            }))
        }
    }

    useEffect(() => {
        dispatch(onGetCompanies({}));
    }, [])

    return (
        <React.Fragment>
            <DeleteModal show={isOpenDelete} onDeleteClick={onDeleteCommand} onCloseClick={() => setIsOpenDelete(false)} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Transaction" breadcrumbItem="Transaction's List" />
                    <Row className="mb-3">
                        <Col xs="12">
                            <Card>
                                <CardBody className="pt-0">
                                    <CardTitle className="my-3">Filter</CardTitle>
                                    <Row>
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Company</Label>
                                                <Select
                                                    value={selectedCompany}
                                                    onChange={handleSelectCompany}
                                                    options={optionCompany}
                                                    isLoading={loadingCompany}
                                                    isClearable={true}
                                                    ref={filterCompany}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Period</Label>
                                                <Select
                                                    value={selectedPeriod}
                                                    onChange={handleSelectPeriod}
                                                    options={optionPeriod}
                                                    isLoading={loadingPeriod}
                                                    isClearable={true}
                                                    ref={filterPeriod}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Account</Label>
                                                <Select
                                                    value={selectedAccount}
                                                    onChange={handleSelectAccount}
                                                    options={optionAccount}
                                                    isLoading={loadingAccount}
                                                    isClearable={true}
                                                    ref={filterAccount}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Account Number</Label>
                                                <Select
                                                    value={selectedAccountNumber}
                                                    onChange={handleSelectAccountNumber}
                                                    options={optionAccountNumber}
                                                    isLoading={loadingAccountNumber}
                                                    isClearable={true}
                                                    ref={filterAccountNumber}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-4">
                                            <Label>Date Range</Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select Date"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "Y-m-d"
                                                }}
                                                onChange={handleDateRangeChange}
                                                ref={filterDateRange}
                                            />
                                        </Col>
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Remarks</Label>
                                                <Input
                                                    type="text"
                                                    value={remarks}
                                                    className="form-control"
                                                    innerRef={filterRemarks}
                                                    onChange={e => {
                                                        setRemarks(e.target.value)
                                                    }}
                                                    placeholder={'Enter ...'}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={4}>
                                            <Label>Fiscal</Label>
                                            <Select
                                                value={selectedFiscal}
                                                onChange={handleSelectFiscal}
                                                options={optionFiscal}
                                                isClearable={true}
                                                ref={filterFiscal}
                                                className="select2-selection"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Label>Adjustment</Label>
                                            <Select
                                                value={selectedAdjustment}
                                                onChange={handleSelectAdjustment}
                                                options={optionAdjustment}
                                                isClearable={true}
                                                ref={filterAdjustment}
                                                className="select2-selection"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Label>Type</Label>
                                            <Select
                                                value={selectedType}
                                                onChange={handleSelectType}
                                                options={optionType}
                                                isClearable={true}
                                                ref={filterType}
                                                className="select2-selection"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="justify-content-end">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light  float-end" style={{ minWidth: '200px' }}
                                                disabled={loading}
                                                onClick={onRefreshCommand}>
                                                <i className="bx bx-filter-alt"></i>
                                            </Button>
                                            <Button color="info" className="btn btn-info waves-effect waves-light me-2 float-end" style={{ minWidth: '100px' }}
                                                disabled={loading}
                                                onClick={onResetFilterCommand}>
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1">
                                            {
                                                selectedCompany && selectedPeriod &&
                                                <Button color="success" className="btn btn-success waves-effect waves-light me-2"
                                                    onClick={
                                                        () => {
                                                            onExportExcel()
                                                        }}>
                                                    <span><i className="bx bx-export"></i> Export Excel</span>
                                                </Button>
                                            }
                                        </div>
                                        <Link to="/account-transaction/create">
                                            <Button color="info" className="btn btn-info waves-effect waves-light me-2">
                                                New Transaction
                                            </Button>
                                        </Link>
                                        <Link to="/account-transaction/create-by-excel">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light">
                                                New Transaction By Excel
                                            </Button>
                                        </Link>
                                    </div>
                                    {
                                        !loading && selectedCompany &&
                                        <>
                                            <TableContainer
                                                columns={columns}
                                                data={accountTransactions}
                                                isGlobalFilter={false}
                                                customPageSize={10}
                                                footer={footer}
                                            />
                                        </>
                                    }
                                    {
                                        !loading && (!selectedCompany || !selectedPeriod) &&
                                        <div className="d-flex justify-content-center my-5">
                                            <p>Please select company and period.</p>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

AccountTransaction.propTypes = {
    accounts: PropTypes.array,
    className: PropTypes.string,
    onGetCompanies: PropTypes.func,
    onGetPeriods: PropTypes.func,
    onGetAccounts: PropTypes.func,
    onGetAccountNumbers: PropTypes.func,
    onGetAccountTransactions: PropTypes.func,
    preGlobalFilteredRows: PropTypes.any,
};

export default AccountTransaction
