export const GET_LIST_ROLE = "GET_LIST_ROLE";
export const GET_LIST_ROLE_SUCCESS = "GET_LIST_ROLE_SUCCESS";
export const GET_LIST_ROLE_FAIL = "GET_LIST_ROLE_FAIL";

export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAIL = "GET_ROLE_FAIL";

export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";