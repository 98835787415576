/* PERIOD */
export const GET_LIST_WORKSHEET = "GET_LIST_WORKSHEET";
export const GET_LIST_WORKSHEET_SUCCESS = "GET_LIST_WORKSHEET_SUCCESS";
export const GET_LIST_WORKSHEET_FAIL = "GET_LIST_WORKSHEET_FAIL";

export const EXPORT_EXCEL_WORKSHEET = "EXPORT_EXCEL_WORKSHEET";
export const EXPORT_EXCEL_WORKSHEET_SUCCESS = "EXPORT_EXCEL_WORKSHEET_SUCCESS";
export const EXPORT_EXCEL_WORKSHEET_FAIL = "EXPORT_EXCEL_WORKSHEET_FAIL";

export const BALANCING_WORKSHEET = "BALANCING_WORKSHEET";
export const BALANCING_WORKSHEET_SUCCESS = "BALANCING_WORKSHEET_SUCCESS";
export const BALANCING_WORKSHEET_FAIL = "BALANCING_WORKSHEET_FAIL";

export const CLEAR_LIST_WORKSHEET = "CLEAR_LIST_WORKSHEET";
