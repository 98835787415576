import { all, fork } from "redux-saga/effects"

import CompanySaga from "./company/saga"
import AccountSaga from "./account/saga"
import AccountNumberSaga from "./accountnumber/saga"
import AssetCategorySaga from "./assetcategory/saga"
import AssetTypeSaga from "./assettype/saga"
import AssetSaga from "./asset/saga"
import PeriodSaga from "./period/saga"
import PiutangSaga from "./piutang/saga"
import AccountTransactionSaga from "./accounttransaction/saga"
import WorksheetSaga from "./worksheet/saga"
import RoleSaga from "./role/saga"
import UserSaga from "./user/saga"
import ReportSaga from "./report/saga"


//public
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga";

export default function* rootSaga() {
  yield all([
    fork(CompanySaga),
    fork(PeriodSaga),
    fork(AccountSaga),
    fork(AccountNumberSaga),
    fork(AssetCategorySaga),
    fork(AssetTypeSaga),
    fork(AssetSaga),
    fork(PiutangSaga),
    fork(AccountTransactionSaga),
    fork(WorksheetSaga),
    fork(RoleSaga),
    fork(UserSaga),
    fork(dashboardSaga),
    fork(ReportSaga),
    //public
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
  ])
}
