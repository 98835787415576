import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_LIST_ASSET_TYPE,
    GET_ASSET_TYPE,
    ADD_ASSET_TYPE,
    UPDATE_ASSET_TYPE,
    DELETE_ASSET_TYPE,
} from "./actionTypes"
import {
    getListAssetTypeSuccess,
    getListAssetTypeFail,
    getAssetTypeByIdSuccess,
    getAssetTypeByIdFail,
    addAssetTypeSuccess,
    addAssetTypeFail,
    updateAssetTypeSuccess,
    updateAssetTypeFail,
    deleteAssetTypeSuccess,
    deleteAssetTypeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
    getListAssetType,
    getAssetType,
    addAssetType,
    updateAssetType,
    deleteAssetType
} from "../../helpers/backend_helper"

function* onFetchListAssetType({ payload: filter }) {
    try {
        const response = yield call(getListAssetType, filter)
        if (response.status) {
            yield put(getListAssetTypeSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getListAssetTypeFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getListAssetTypeFail(error))
    }
}

function* onGetAssetTypeById({ payload: { id, companyId } }) {
    try {
        const response = yield call(getAssetType, { id: id, companyId: companyId })
        if (response.status) {
            yield put(getAssetTypeByIdSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getAssetTypeByIdFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getAssetTypeByIdFail(error))
    }
}

function* onAddAssetType({ payload: { assetType, history } }) {
    try {
        const response = yield call(addAssetType, assetType)
        if (response.status) {
            NotifHelper.success(msg.ASSET_TYPE_ADD_SUCCESS)
            yield put(addAssetTypeSuccess())
            history.push("/masterdata/asset-type")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addAssetTypeFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addAssetTypeFail(error))
    }
}

function* onUpdateAssetType({ payload: { assetType, history } }) {
    try {
        const response = yield call(updateAssetType, assetType)
        if (response.status) {
            NotifHelper.success(msg.ASSET_TYPE_UPDATE_SUCCESS)
            yield put(updateAssetTypeSuccess())
            history.push("/masterdata/account")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updateAssetTypeFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updateAssetTypeFail(error))
    }
}

function* onDeleteAssetType({ payload: { assetType, filter } }) {
    try {
        const response = yield call(deleteAssetType, assetType)
        if (response.status) {
            NotifHelper.success(msg.ASSET_TYPE_DELETE_SUCCESS)
            yield put(deleteAssetTypeSuccess())
            const responseNewData = yield call(getListAssetType, filter)
            if (responseNewData.status) {
                yield put(getListAssetTypeSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getListAssetTypeFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deleteAssetTypeFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deleteAssetTypeFail(error))
    }
}

function* assetTypeSaga() {
    yield takeEvery(GET_LIST_ASSET_TYPE, onFetchListAssetType)
    yield takeEvery(GET_ASSET_TYPE, onGetAssetTypeById)
    yield takeEvery(ADD_ASSET_TYPE, onAddAssetType)
    yield takeEvery(UPDATE_ASSET_TYPE, onUpdateAssetType)
    yield takeEvery(DELETE_ASSET_TYPE, onDeleteAssetType)
}

export default assetTypeSaga