import {
  GET_REPORT_EXCEL,
  GET_REPORT_EXCEL_SUCCESS,
  GET_REPORT_EXCEL_FAIL,
  GET_REPORT_PDF,
  GET_REPORT_PDF_SUCCESS,
  GET_REPORT_PDF_FAIL,
  GET_REPORT_PROFITLOSS_EXCEL,
  GET_REPORT_PROFITLOSS_EXCEL_SUCCESS,
  GET_REPORT_PROFITLOSS_EXCEL_FAIL,
  GET_REPORT_PROFITLOSS_PDF,
  GET_REPORT_PROFITLOSS_PDF_SUCCESS,
  GET_REPORT_PROFITLOSS_PDF_FAIL
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,
};

const Report = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_EXCEL:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORT_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_REPORT_EXCEL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

      return {
        ...state,
        error: action.payload,
        loadingPayment: false,
      };

    case GET_REPORT_PDF:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORT_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_REPORT_PDF_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_REPORT_PROFITLOSS_EXCEL:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORT_PROFITLOSS_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_REPORT_PROFITLOSS_EXCEL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_REPORT_PROFITLOSS_PDF:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORT_PROFITLOSS_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_REPORT_PROFITLOSS_PDF_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Report;
