/* PERIOD */
export const GET_LIST_ACCOUNT = "GET_LIST_ACCOUNT";
export const GET_LIST_ACCOUNT_SUCCESS = "GET_LIST_ACCOUNT_SUCCESS";
export const GET_LIST_ACCOUNT_FAIL = "GET_LIST_ACCOUNT_FAIL";

export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAIL = "GET_ACCOUNT_FAIL";

export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAIL = "ADD_ACCOUNT_FAIL";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL";

export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";

export const CLEAR_LIST_ACCOUNT = "CLEAR_LIST_ACCOUNT";
