import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from 'react-router-dom';
import {
    Container,
    Button,
    Col,
    Row,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
    getAssetTypeById as onGetAssetType,
    updateAssetType as onUpdateAssetType,
    addAssetType as onAddAssetType,
    getCompanies as onGetCompanies
} from "../../store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const AssetTypeCreateUpdate = (props) => {

    //meta title
    document.title = "Asset Type | Master Data | Des Counts";
    const id = props?.match?.params?.id;
    const companyId = props?.match?.params?.companyId;
    const isEdit = id != undefined && companyId != undefined;

    const { companies, assetType, loading, error } = useSelector(state => ({
        companies: state.Company.companies,
        assetType: state.AssetType.assetType,
        loading: state.AssetType.loading,
        error: state.AssetType.error,
    }));

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e)
        validation.setFieldValue("companyId", e.value.toString(), true)
    }

    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyId: isEdit && assetType ? assetType.companyId : "",
            name: isEdit && assetType ? assetType.name : "",
        },
        validationSchema: Yup.object({
            companyId: Yup.string().required("Please Select company"),
            name: Yup.string().required("Please Enter Type Name"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                dispatch(onUpdateAssetType({ id: assetType.id, companyId: assetType.companyId, name: values.name }, props.history));
            } else {
                dispatch(onAddAssetType({
                    name: values.name,
                    companyId: parseInt(values.companyId)
                }, props.history));
            }
        },
    });


    useEffect(() => {
        dispatch(onGetCompanies({}))
        if (isEdit) {
            dispatch(onGetAssetType(id, companyId))
        }
    }, [dispatch])

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id.toString() }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (isEdit && assetType && companies) {
            var company = optionCompany.filter(s => s.value == assetType.companyId)
            if (company.length > 0) {
                setSelectedCompany(company[0])
            }
        }
    }, [optionCompany, assetType])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Master Data" breadcrumbItem={(isEdit ? "Edit" : "Add") + " Asset Type"} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {
                                        !loading &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <Form
                                                        className="form-horizontal"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col className="col-12 col-sm-6">
                                                                <div className="mb-3">
                                                                    <Label>Company</Label>
                                                                    <div
                                                                        style={validation.errors.companyId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                            { border: '0', borderRadius: '0.25rem' }}
                                                                    >
                                                                        <Select
                                                                            value={selectedCompany}
                                                                            onChange={handleSelectCompany}
                                                                            onBlur={validation.handleBlur}
                                                                            options={optionCompany}
                                                                            isDisabled={isEdit}
                                                                            className="select2-selection"
                                                                        />
                                                                    </div>
                                                                    {validation.touched.companyId && validation.errors.companyId ? (
                                                                        <FormFeedback className="d-block" type="invalid">{validation.errors.companyId}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                            <Col className="col-12 col-sm-6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label">Name</Label>
                                                                    <Input
                                                                        name="name"
                                                                        className="form-control"
                                                                        placeholder="Enter type name"
                                                                        type="text"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.name || ""}
                                                                        invalid={
                                                                            validation.touched.name && validation.errors.name ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.name && validation.errors.name ? (
                                                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <div className="mt-3 mb-2">
                                                            <Link to="/masterdata/asset-type">
                                                                <Button
                                                                    color="secondary"
                                                                    className="btn btn-secondary float-end"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                color="primary"
                                                                className="btn btn-primary float-end me-2"
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

AssetTypeCreateUpdate.propTypes = {
    history: PropTypes.object,
};

export default withRouter(AssetTypeCreateUpdate)
