import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"
import fileDownload from 'js-file-download'

// Calender Redux States
import {
    GET_COMPANIES,
    ADD_COMPANY,
    UPDATE_COMPANY,
    DELETE_COMPANY,
    GET_COMPANY,
    IMPORT_ACCOUNT_COMPANY,
    UPLOAD_TEMPLATE_REPORT,
    UPLOAD_TEMPLATE_REPORT_PROFITLOSS,
    EXPORT_ACCOUNT_LIST
} from "./actionTypes"
import {
    getCompaniesSuccess,
    getCompaniesFail,
    addCompanyFail,
    updateCompanyFail,
    deleteCompanyFail,
    getCompanySuccess,
    getCompanyFail,
    importAccountSuccess,
    importAccountFail,
    uploadTemplateSuccess,
    uploadTemplateFail,
    uploadTemplateProfitLossSuccess,
    uploadTemplateProfitLossFail,
    exportAccountListSuccess,
    exportAccountListFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getCompanies,
    getCompany,
    addCompany,
    updateCompany,
    deleteCompany,
    importAccountCompany,
    uploadTemplateReport,
    uploadTemplateReportProfitLoss,
    exportAccountList
} from "../../helpers/backend_helper"

function* fetchCompanies({ payload: filter }) {
    try {
        const response = yield call(getCompanies, filter)
        if (response.status) {
            yield put(getCompaniesSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getCompaniesFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getCompaniesFail(error))
    }
}

function* getCompanyById({ payload: id }) {
    try {
        const response = yield call(getCompany, id)
        if (response.status) {
            yield put(getCompanySuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getCompanyFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getCompanyFail(error))
    }
}

function* addNewCompany({ payload: { company, history } }) {
    try {
        const response = yield call(addCompany, company)
        if (response.status) {
            NotifHelper.success(msg.COMPANY_ADD_SUCCESS)
            history.push("/masterdata/company")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addCompanyFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addCompanyFail(error))
    }
}

function* onUpdateCompany({ payload: { company, history } }) {
    try {
        const response = yield call(updateCompany, company)
        if (response.status) {
            NotifHelper.success(msg.COMPANY_UPDATE_SUCCESS)
            history.push("/masterdata/company")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updateCompanyFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updateCompanyFail(error))
    }
}

function* onDeleteCompany({ payload: { company, filter } }) {
    try {
        const response = yield call(deleteCompany, company)
        if (response.status) {
            NotifHelper.success(msg.COMPANY_DELETE_SUCCESS)
            const responseNewData = yield call(getCompanies, filter)
            if (responseNewData.status) {
                yield put(getCompaniesSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getCompaniesFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deleteCompanyFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deleteCompanyFail(error))
    }
}

function* onImportAccount({ payload: { data } }) {
    try {
        const response = yield call(importAccountCompany, data)
        if (response.status) {
            NotifHelper.success(msg.IMPORT_ACCOUNT_COMPANY_SUCCESS)
            yield put(importAccountSuccess())
        }
        else {
            NotifHelper.error(response.message)
            yield put(importAccountFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(importAccountFail(error))
    }
}

function* onUploadTemplateReport({ payload: { uploadFile, companyId, filter } }) {
    try {
        const response = yield call(uploadTemplateReport, uploadFile, {
            companyId: companyId
        })
        if (response.status) {
            NotifHelper.success(msg.UPLOAD_TEMPLATE_REPORT_SUCCESS)
            yield put(uploadTemplateSuccess())
            const responseNewData = yield call(getCompanies, filter)
            if (responseNewData.status) {
                yield put(getCompaniesSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getCompaniesFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(uploadTemplateFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(uploadTemplateFail(error))
    }
}

function* onUploadTemplateReportProfitLoss({ payload: { uploadFile, companyId, filter } }) {
    try {
        const response = yield call(uploadTemplateReportProfitLoss, uploadFile, {
            companyId: companyId
        })
        if (response.status) {
            NotifHelper.success(msg.UPLOAD_TEMPLATE_REPORT_SUCCESS)
            yield put(uploadTemplateProfitLossSuccess())
            const responseNewData = yield call(getCompanies, filter)
            if (responseNewData.status) {
                yield put(getCompaniesSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getCompaniesFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(uploadTemplateProfitLossFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(uploadTemplateProfitLossFail(error))
    }
}

function* onExportAccountList({ payload: { data } }) {
    try {
        const response = yield call(exportAccountList, data, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        let endFileNameIndex = headerLine.lastIndexOf(';');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        fileDownload(response.data, filename)
        yield put(exportAccountListSuccess())
    } catch (error) {
        NotifHelper.error(error)
        yield put(exportAccountListFail(error))
    }
}

function* companySaga() {
    yield takeEvery(GET_COMPANIES, fetchCompanies)
    yield takeEvery(GET_COMPANY, getCompanyById)
    yield takeEvery(ADD_COMPANY, addNewCompany)
    yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
    yield takeEvery(DELETE_COMPANY, onDeleteCompany)
    yield takeEvery(IMPORT_ACCOUNT_COMPANY, onImportAccount)
    yield takeEvery(UPLOAD_TEMPLATE_REPORT, onUploadTemplateReport)
    yield takeEvery(UPLOAD_TEMPLATE_REPORT_PROFITLOSS, onUploadTemplateReportProfitLoss)
    yield takeEvery(EXPORT_ACCOUNT_LIST, onExportAccountList)
}

export default companySaga