import React, { useState, useMemo, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Container,
    Button,
    Col,
    Row,
    Label,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanies as onGetCompanies,
    getPeriods as onGetPeriods,
    getReportExcel as onGetReportExcel,
    getReportPdf as onGetReportPdf,
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const ReportNeraca = () => {

    //meta title
    document.title = "Report Neraca | Des Counts";

    const dispatch = useDispatch();

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }

    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [optionPeriod, setOptionPeriod] = useState([]);
    function handleSelectPeriod(e) {
        setSelectedPeriod(e);
    }

    const filterCompany = useRef("");
    const filterPeriod = useRef("");

    const { companies, loadingCompany } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
    }));

    const { periods, loadingPeriod } = useSelector(state => ({
        periods: state.Period.periods,
        loadingPeriod: state.Period.loading,
    }));

    const { loading } = useSelector(state => ({
        loading: state.Report.loading
    }))

    const onDownloadReportExcel = () => {
        var dataPeriod = selectedPeriod.map(item => {
            return item.value
        })
        dispatch(onGetReportExcel({
            companyId: selectedCompany.value,
            periodIds: dataPeriod
        }))
    }

    const onDownloadReportPdf = () => {
        var dataPeriod = selectedPeriod.map(item => {
            return item.value
        })
        dispatch(onGetReportPdf({
            companyId: selectedCompany.value,
            periodIds: dataPeriod
        }))
    }

    useEffect(() => {
        if (selectedCompany) {
            dispatch(onGetPeriods({ companyId: selectedCompany.value, isOpen: false }));
            filterPeriod.current.clearValue();
        }
    }, [selectedCompany])

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (periods) {
            setOptionPeriod(periods.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [periods])

    useEffect(() => {
        dispatch(onGetCompanies({}));
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Report" breadcrumbItem="Report Neraca" />
                    {
                        loading &&
                        <div className="d-flex justify-content-center my-5">
                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                        </div>
                    }
                    {
                        !loading &&
                        <Row className="mb-3">
                            <Col xs="12">
                                <Card>
                                    <CardBody className="pt-0">
                                        <CardTitle className="my-3">Filter</CardTitle>
                                        <Row>
                                            <Col sm={6}>
                                                <div>
                                                    <Label>Company</Label>
                                                    <Select
                                                        value={selectedCompany}
                                                        onChange={handleSelectCompany}
                                                        options={optionCompany}
                                                        isLoading={loadingCompany}
                                                        ref={filterCompany}
                                                        className="select2-selection"
                                                        menuPosition="fixed"
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div>
                                                    <Label>Period</Label>
                                                    <Select
                                                        value={selectedPeriod}
                                                        onChange={handleSelectPeriod}
                                                        options={optionPeriod}
                                                        isLoading={loadingPeriod}
                                                        isClearable={true}
                                                        className="select2-selection"
                                                        isMulti={true}
                                                        ref={filterPeriod}
                                                        menuPosition="fixed"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col className="justify-content-end">
                                                <Button color="primary" className="btn btn-success waves-effect waves-light float-end ms-2" style={{ minWidth: '200px' }}
                                                    disabled={!selectedCompany || !selectedPeriod || selectedPeriod.length == 0}
                                                    onClick={onDownloadReportExcel}
                                                >
                                                    Download Excel
                                                </Button>
                                                <Button color="danger" className="btn btn-danger waves-effect waves-light float-end" style={{ minWidth: '200px' }}
                                                    disabled={!selectedCompany || !selectedPeriod || selectedPeriod.length == 0}
                                                    onClick={onDownloadReportPdf}
                                                >
                                                    Download PDF
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

ReportNeraca.propTypes = {
    className: PropTypes.string,
    onGetCompanies: PropTypes.func,
    preGlobalFilteredRows: PropTypes.any,
};

export default ReportNeraca
