import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import fileDownload from 'js-file-download'

// Calender Redux States
import {
    GET_REPORT_EXCEL,
    GET_REPORT_PDF,
    GET_REPORT_PROFITLOSS_EXCEL,
    GET_REPORT_PROFITLOSS_PDF
} from "./actionTypes"
import {
    getReportExcelSuccess,
    getReportExcelFail,
    getReportPdfSuccess,
    getReportPdfFail,
    getReportProfitLossExcelSuccess,
    getReportProfitLossExcelFail,
    getReportProfitLossPdfSuccess,
    getReportProfitLossPdfFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getReportExcel,
    getReportPdf,
    getReportProfitLossExcel,
    getReportProfitLossPdf
} from "../../helpers/backend_helper"

function* onGetReportExcel({ payload: { param } }) {
    try {
        const response = yield call(getReportExcel, param, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        let endFileNameIndex = headerLine.lastIndexOf(';');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        fileDownload(response.data, filename)
        yield put(getReportExcelSuccess())
    } catch (error) {
        NotifHelper.error(error)
        yield put(getReportExcelFail(error))
    }
}

function* onGetReportPdf({ payload: { param } }) {
    try {
        const response = yield call(getReportPdf, param, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        let endFileNameIndex = headerLine.lastIndexOf(';');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        const file = new Blob([response.data], { type: "application/pdf" });
        fileDownload(file, filename)
        yield put(getReportPdfSuccess())
    } catch (error) {
        yield put(getReportPdfFail())
    }
}

function* onGetReportProfitLossExcel({ payload: { param } }) {
    try {
        const response = yield call(getReportProfitLossExcel, param, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        let endFileNameIndex = headerLine.lastIndexOf(';');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        fileDownload(response.data, filename)
        yield put(getReportProfitLossExcelSuccess())
    } catch (error) {
        NotifHelper.error(error)
        yield put(getReportProfitLossExcelFail(error))
    }
}

function* onGetReportProfitLossPdf({ payload: { param } }) {
    try {
        const response = yield call(getReportProfitLossPdf, param, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        let endFileNameIndex = headerLine.lastIndexOf(';');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        const file = new Blob([response.data], { type: "application/pdf" });
        fileDownload(file, filename)
        yield put(getReportProfitLossPdfSuccess())
    } catch (error) {
        yield put(getReportProfitLossPdfFail())
    }
}

function* reportSaga() {
    yield takeEvery(GET_REPORT_EXCEL, onGetReportExcel)
    yield takeEvery(GET_REPORT_PDF, onGetReportPdf)
    yield takeEvery(GET_REPORT_PROFITLOSS_EXCEL, onGetReportProfitLossExcel)
    yield takeEvery(GET_REPORT_PROFITLOSS_PDF, onGetReportProfitLossPdf)
}

export default reportSaga