import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  RESET_ERROR,
  LOGIN_CHECK,
  GET_ACCESS,
  GET_ACCESS_SUCCESS
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = access => {
  return {
    type: LOGIN_SUCCESS,
    payload: access,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const resetError = error => {
  return {
    type: RESET_ERROR,
    payload: error,
  }
}

export const getAccess = () => {
  return {
    type: GET_ACCESS,
  }
}

export const getAccessSuccess = access => {
  return {
    type: GET_ACCESS_SUCCESS,
    payload: access,
  }
}