import {
    GET_LIST_ASSET_CATEGORY,
    GET_LIST_ASSET_CATEGORY_SUCCESS,
    GET_LIST_ASSET_CATEGORY_FAIL,
    GET_ASSET_CATEGORY,
    GET_ASSET_CATEGORY_SUCCESS,
    GET_ASSET_CATEGORY_FAIL,
    ADD_ASSET_CATEGORY,
    ADD_ASSET_CATEGORY_SUCCESS,
    ADD_ASSET_CATEGORY_FAIL,
    UPDATE_ASSET_CATEGORY,
    UPDATE_ASSET_CATEGORY_SUCCESS,
    UPDATE_ASSET_CATEGORY_FAIL,
    DELETE_ASSET_CATEGORY,
    DELETE_ASSET_CATEGORY_SUCCESS,
    DELETE_ASSET_CATEGORY_FAIL,
    CLEAR_LIST_ASSET_CATEGORY
  } from "./actionTypes";
  
  export const getListAssetCategory = filter => ({
    type: GET_LIST_ASSET_CATEGORY,
    payload: filter
  });
  export const getListAssetCategorySuccess = assetCategorys => ({
    type: GET_LIST_ASSET_CATEGORY_SUCCESS,
    payload: assetCategorys,
  });
  export const getListAssetCategoryFail = error => ({
    type: GET_LIST_ASSET_CATEGORY_FAIL,
    payload: error,
  });

  export const getAssetCategoryById = (id, companyId) => ({
    type: GET_ASSET_CATEGORY,
    payload: { id : id, companyId : companyId}
  });
  export const getAssetCategoryByIdSuccess = assetCategory => ({
    type: GET_ASSET_CATEGORY_SUCCESS,
    payload: assetCategory,
  });
  export const getAssetCategoryByIdFail = error => ({
    type: GET_ASSET_CATEGORY_FAIL,
    payload: error,
  });
  
  export const addAssetCategory = (assetCategory, history) => ({
    type: ADD_ASSET_CATEGORY,
    payload: { assetCategory, history },
  });
  export const addAssetCategorySuccess = () => ({
    type: ADD_ASSET_CATEGORY_SUCCESS,
  });
  export const addAssetCategoryFail = error => ({
    type: ADD_ASSET_CATEGORY_FAIL,
    payload: error,
  });
  
  export const updateAssetCategory = (assetCategory, history) => ({
    type: UPDATE_ASSET_CATEGORY,
    payload: { assetCategory, history },
  });
  export const updateAssetCategorySuccess = () => ({
    type: UPDATE_ASSET_CATEGORY_SUCCESS,
  });
  export const updateAssetCategoryFail = error => ({
    type: UPDATE_ASSET_CATEGORY_FAIL,
    payload: error,
  });
  
  export const deleteAssetCategory = (assetCategory, filter) => ({
    type: DELETE_ASSET_CATEGORY,
    payload: { assetCategory, filter },
  });
  export const deleteAssetCategorySuccess = () => ({
    type: DELETE_ASSET_CATEGORY_SUCCESS,
  });
  export const deleteAssetCategoryFail = error => ({
    type: DELETE_ASSET_CATEGORY_FAIL,
    payload: error,
  });

  export const clearListAssetCategory = () => ({
    type: CLEAR_LIST_ASSET_CATEGORY,
  });