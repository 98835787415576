import React, { useState, useMemo, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link, NavLink } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Label,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import Select from "react-select";
import TableContainer from '../../components/Common/TableContainer';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanies as onGetCompanies,
    getListAsset as onGetAssets,
    deleteAsset as onDeleteAsset,
    getListAssetType as onGetAssetTypes,
    getListAssetCategory as onGetAssetCategories,
    exportExcelAsset as onExportExcelAsset
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import DeleteModal from "../../components/Common/ModalDelete";
import CurrencyFormat from 'react-currency-format';


const Asset = () => {

    //meta title
    document.title = "Asset | Des Counts";

    const dispatch = useDispatch();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [asset, setAsset] = useState({});

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }

    const [selectedAssetCategory, setSelectedAssetCategory] = useState(null);
    const [optionAssetCategory, setOptionAssetCategory] = useState([]);
    function handleSelectAssetCategory(e) {
        setSelectedAssetCategory(e);
    }

    const [selectedAssetType, setSelectedAssetType] = useState(null);
    const [optionAssetType, setOptionAssetType] = useState([]);
    function handleSelectAssetType(e) {
        setSelectedAssetType(e);
    }

    const [selectedIsFix, setSelectedIsFix] = useState(null);
    const [optionIsFix] = useState([
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]);
    function handleSelectIsFix(e) {
        setSelectedIsFix(e);
    }

    const [selectedIsIntangible, setSelectedIsIntangible] = useState(null);
    const [optionIsIntangible] = useState([
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]);
    function handleSelectIsIntangible(e) {
        setSelectedIsIntangible(e);
    }

    const filterName = useRef("");
    const filterCompany = useRef("");
    const filterCategory = useRef("");
    const filterType = useRef("");
    const filterIsFix = useRef("");
    const filterIsIntangible = useRef("");

    const { companies, loadingCompany, assetCategories, loadingCategory, assetTypes, loadingType } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
        assetCategories: state.AssetCategory.listAssetCategory,
        loadingCategory: state.AssetCategory.loading,
        assetTypes: state.AssetType.listAssetType,
        loadingType: state.AssetType.loading,
    }));

    const { listAsset, loading } = useSelector(state => ({
        listAsset: state.Asset.listAsset,
        loading: state.Asset.loading,
    }));

    const columns = useMemo(
        () => [
            {
                Header: 'Category',
                accessor: 'assetCategory',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Type',
                accessor: 'assetType',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Acquisition Date',
                accessor: 'acquisitionDate',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                textAlign: 'text-start',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'BPU',
                accessor: 'bpu',
                textAlign: 'text-start',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Qty',
                accessor: 'quantity',
                textAlign: 'text-end',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <CurrencyFormat value={cellProps.value} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                        displayType={'text'} />;
                }
            },
            {
                Header: 'Value',
                accessor: 'acquisitionValue',
                textAlign: 'text-end',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <CurrencyFormat value={cellProps.value} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                        displayType={'text'} />;
                }
            },
            {
                Header: 'Usage Life (Year)',
                accessor: 'usageLife',
                textAlign: 'text-end',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <CurrencyFormat value={cellProps.value} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                        displayType={'text'} />;
                }
            },
            {
                Header: 'Dep. Rate (%)',
                accessor: 'depreciationRate',
                textAlign: 'text-end',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <CurrencyFormat value={cellProps.value * 100} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                        displayType={'text'} />;
                }
            },
            {
                Header: 'Fix Asset',
                accessor: 'isFix',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value ? 'Yes' : 'No';
                }
            },
            {
                Header: 'Intangible',
                accessor: 'isIntangible',
                textAlign: 'text-center',
                headerAlign: 'text-center',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value ? 'Yes' : 'No';
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                headerCellStyle: 'text-center',
                style: {
                    textAlign: "center",
                },
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <Link
                                to={"/asset-create-update/" + cellProps.row.original.companyId + "/" + cellProps.row.original.id}
                                className="text-success"
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    onOpenDeleteCommand(data);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    const handleOrderClicks = () => {
    }

    const onRefreshCommand = () => {
        if (selectedCompany) {
            dispatch(onGetAssets({
                companyId: selectedCompany ? selectedCompany.value : null,
                name: filterName.current.value,
                categoryId: selectedAssetCategory ? selectedAssetCategory.value : null,
                typeId: selectedAssetType ? selectedAssetType.value : null,
                isFix: selectedIsFix ? selectedIsFix.value : null,
                isIntangible: selectedIsIntangible ? selectedIsIntangible.value : null
            }));
        }
    }

    const onResetFilterCommand = () => {
        filterName.current.value = '';
        filterCompany.current.clearValue();
        filterCategory.current.clearValue();
        filterType.current.clearValue();
        filterIsFix.current.clearValue();
        filterIsIntangible.current.clearValue();
    }

    const onOpenDeleteCommand = (data) => {
        setAsset(data)
        setIsOpenDelete(true);
    }

    const onDeleteCommand = () => {
        setIsOpenDelete(false)
        dispatch(onDeleteAsset(asset, {
            companyId: selectedCompany ? selectedCompany.value : null,
            name: filterName.current.value,
        }))
    }

    useEffect(() => {
        onRefreshCommand();
    }, [dispatch]);

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (assetCategories) {
            setOptionAssetCategory(assetCategories.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [assetCategories])

    useEffect(() => {
        if (assetTypes) {
            setOptionAssetType(assetTypes.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [assetTypes])

    useEffect(() => {
        if (selectedCompany) {
            filterCategory.current.clearValue();
            filterType.current.clearValue();
            dispatch(onGetAssetCategories({ companyId: selectedCompany.value }))
            dispatch(onGetAssetTypes({ companyId: selectedCompany.value }))
        }
    }, [selectedCompany])

    useEffect(() => {
        dispatch(onGetCompanies({}));
    }, [])

    return (
        <React.Fragment>
            <DeleteModal show={isOpenDelete} onDeleteClick={onDeleteCommand} onCloseClick={() => setIsOpenDelete(false)} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Asset" breadcrumbItem="Asset's List" />
                    <Row className="mb-3">
                        <Col xs="12">
                            <Card>
                                <CardBody className="pt-0">
                                    <CardTitle className="my-3">Filter</CardTitle>
                                    <Row>
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Company</Label>
                                                <Select
                                                    value={selectedCompany}
                                                    onChange={handleSelectCompany}
                                                    options={optionCompany}
                                                    isLoading={loadingCompany}
                                                    isClearable={true}
                                                    ref={filterCompany}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <label className="form-label">
                                                    Name
                                                </label>
                                                <div className="col-md-12">
                                                    <input className="form-control" type="text" ref={filterName} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Category</Label>
                                                <Select
                                                    value={selectedAssetCategory}
                                                    onChange={handleSelectAssetCategory}
                                                    options={optionAssetCategory}
                                                    isLoading={loadingCategory}
                                                    isClearable={true}
                                                    ref={filterCategory}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Type</Label>
                                                <Select
                                                    value={selectedAssetType}
                                                    onChange={handleSelectAssetType}
                                                    options={optionAssetType}
                                                    isLoading={loadingType}
                                                    isClearable={true}
                                                    ref={filterType}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Fix Asset</Label>
                                                <Select
                                                    value={selectedIsFix}
                                                    onChange={handleSelectIsFix}
                                                    options={optionIsFix}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    ref={filterIsFix}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-4">
                                            <div>
                                                <Label>Intangible</Label>
                                                <Select
                                                    value={selectedIsIntangible}
                                                    onChange={handleSelectIsIntangible}
                                                    options={optionIsIntangible}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    ref={filterIsIntangible}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="justify-content-end">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light  float-end" style={{ minWidth: '200px' }}
                                                disabled={loading}
                                                onClick={onRefreshCommand}>
                                                <i className="bx bx-filter-alt"></i>
                                            </Button>
                                            <Button color="info" className="btn btn-info waves-effect waves-light me-2 float-end" style={{ minWidth: '100px' }}
                                                disabled={loading}
                                                onClick={onResetFilterCommand}>
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1">
                                            <Link to="/asset-import-excel">
                                                <Button color="secondary" className="btn btn-secondary waves-effect waves-light">
                                                    Import Excel
                                                </Button>
                                            </Link>
                                        </div>
                                        <div>
                                            {
                                                selectedCompany &&
                                                <Button color="success" className="btn btn-success waves-effect waves-light me-2"
                                                    onClick={() => dispatch(onExportExcelAsset({ companyId: selectedCompany.value, isFix: selectedIsFix?.value ?? false }))}>
                                                    Export Excel
                                                </Button>
                                            }
                                            <Link to="/asset-create-update">
                                                <Button color="primary" className="btn btn-primary waves-effect waves-light">
                                                    New Asset
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                    {
                                        !loading && selectedCompany &&
                                        <>
                                            <TableContainer
                                                columns={columns}
                                                data={listAsset}
                                                isGlobalFilter={false}
                                                isAddOptions={true}
                                                handleOrderClicks={handleOrderClicks}
                                                customPageSize={10}
                                            />
                                        </>
                                    }
                                    {
                                        !loading && !selectedCompany &&
                                        <div className="d-flex justify-content-center my-5">
                                            <p>Please select company.</p>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

Asset.propTypes = {
    listAsset: PropTypes.array,
    className: PropTypes.string,
    onGetCompanies: PropTypes.func,
    onGetAssetTypes: PropTypes.func,
    preGlobalFilteredRows: PropTypes.any,
};

export default Asset
