import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_LIST_ROLE,
    GET_ROLE,
    ADD_ROLE,
    UPDATE_ROLE,
    DELETE_ROLE,
} from "./actionTypes"
import {
    getListRoleSuccess,
    getListRoleFail,
    getRoleSuccess,
    getRoleFail,
    addRoleSuccess,
    addRoleFail,
    updateRoleSuccess,
    updateRoleFail,
    deleteRoleSuccess,
    deleteRoleFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
    getListRole,
    getRole,
    addRole,
    updateRole,
    deleteRole,
} from "../../helpers/backend_helper"

function* fetchListRole({ payload: filter }) {
    try {
        const response = yield call(getListRole, filter)
        if (response.status) {
            yield put(getListRoleSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getListRoleFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getListRoleFail(error))
    }
}

function* onGetRole({ payload: { id } }) {
    try {
        const response = yield call(getRole, { id })
        if (response.status) {
            yield put(getRoleSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getRoleFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getRoleFail(error))
    }
}

function* onAddRole({ payload: { role, history } }) {
    try {
        const response = yield call(addRole, role)
        if (response.status) {
            NotifHelper.success(msg.ROLE_ADD_SUCCESS)
            yield put(addRoleSuccess())
            history.push("/usermanagement/role")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addRoleFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addRoleFail(error))
    }
}

function* onUpdateRole({ payload: { role, history } }) {
    try {
        const response = yield call(updateRole, role)
        if (response.status) {
            NotifHelper.success(msg.ROLE_UPDATE_SUCCESS)
            yield put(updateRoleSuccess())
            history.push("/usermanagement/role")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updateRoleFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updateRoleFail(error))
    }
}

function* onDeleteRole({ payload: { role, filter } }) {
    try {
        const response = yield call(deleteRole, role)
        if (response.status) {
            yield put(deleteRoleSuccess())
            NotifHelper.success(msg.ROLE_DELETE_SUCCESS)
            const responseNewData = yield call(getListRole, filter)
            if (responseNewData.status) {
                yield put(getListRoleSuccess(responseNewData.data))
            } 
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getListRoleFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deleteRoleFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deleteRoleFail(error))
    }
}

function* roleSaga() {
    yield takeEvery(GET_LIST_ROLE, fetchListRole)
    yield takeEvery(GET_ROLE, onGetRole)
    yield takeEvery(ADD_ROLE, onAddRole)
    yield takeEvery(UPDATE_ROLE, onUpdateRole)
    yield takeEvery(DELETE_ROLE, onDeleteRole)
}

export default roleSaga