import {
    GET_LIST_ACCOUNT,
    GET_LIST_ACCOUNT_SUCCESS,
    GET_LIST_ACCOUNT_FAIL,
    GET_ACCOUNT,
    GET_ACCOUNT_SUCCESS,
    GET_ACCOUNT_FAIL,
    ADD_ACCOUNT,
    ADD_ACCOUNT_SUCCESS,
    ADD_ACCOUNT_FAIL,
    UPDATE_ACCOUNT,
    UPDATE_ACCOUNT_FAIL,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_FAIL,
    UPDATE_ACCOUNT_SUCCESS,
    CLEAR_LIST_ACCOUNT
  } from "./actionTypes";
  
  export const getListAccount = filter => ({
    type: GET_LIST_ACCOUNT,
    payload: filter
  });
  export const getListAccountSuccess = accounts => ({
    type: GET_LIST_ACCOUNT_SUCCESS,
    payload: accounts,
  });
  export const getListAccountFail = error => ({
    type: GET_LIST_ACCOUNT_FAIL,
    payload: error,
  });

  export const getAccountById = (id, companyId) => ({
    type: GET_ACCOUNT,
    payload: { id : id, companyId : companyId}
  });
  export const getAccountByIdSuccess = account => ({
    type: GET_ACCOUNT_SUCCESS,
    payload: account,
  });
  export const getAccountByIdFail = error => ({
    type: GET_ACCOUNT_FAIL,
    payload: error,
  });
  
  export const addAccount = (account, history) => ({
    type: ADD_ACCOUNT,
    payload: { account, history },
  });
  export const addAccountSuccess = (account) => ({
    type: ADD_ACCOUNT_SUCCESS,
    payload: account,
  });
  export const addAccountFail = error => ({
    type: ADD_ACCOUNT_FAIL,
    payload: error,
  });
  
  export const updateAccount = (account, history) => ({
    type: UPDATE_ACCOUNT,
    payload: { account, history },
  });
  export const updateAccountSuccess = () => ({
    type: UPDATE_ACCOUNT_SUCCESS,
  });
  export const updateAccountFail = error => ({
    type: UPDATE_ACCOUNT_FAIL,
    payload: error,
  });
  
  export const deleteAccount = (account, filter) => ({
    type: DELETE_ACCOUNT,
    payload: { account, filter },
  });
  export const deleteAccountFail = error => ({
    type: DELETE_ACCOUNT_FAIL,
    payload: error,
  });

  export const clearListAccount = () => ({
    type: CLEAR_LIST_ACCOUNT,
  });