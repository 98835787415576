import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { NotifHelper } from "../../components/Common/NotifHelper"

// Crypto Redux States
import { GET_CHARTS_DATA, GET_ACCOUNT_NUMBER_DASHBOARD, GET_DATA_DASHBOARD } from "./actionTypes";
import {
    apiSuccess,
    apiFail,
    getAccountNumberDashboardSuccess,
    getAccountNumberDashboardFailed,
    getDataDashboardSuccess,
    getDataDashboardFailed
} from "./actions";

//Include Both Helper File with needed methods
import {
    getWeeklyData,
    getYearlyData,
    getMonthlyData
}
    from "../../helpers/fakebackend_helper";

import {
    getAccountNumberDashboard,
    getDashboardData
}
    from "../../helpers/backend_helper";

function* getChartsData({ payload: periodType }) {
    try {
        var response;
        if (periodType == "monthly") {
            response = yield call(getWeeklyData, periodType);
        }
        if (periodType == "yearly") {
            response = yield call(getYearlyData, periodType);
        }
        if (periodType == "weekly") {
            response = yield call(getMonthlyData, periodType);
        }

        yield put(apiSuccess(GET_CHARTS_DATA, response));
    } catch (error) {
        yield put(apiFail(GET_CHARTS_DATA, error));
    }
}

export function* watchGetChartsData() {
    yield takeEvery(GET_CHARTS_DATA, getChartsData);
}

function* fetchAccountNumbersData({ payload: filter }) {
    try {
        const response = yield call(getAccountNumberDashboard, filter)
        if (response.status) {
            yield put(getAccountNumberDashboardSuccess(response.data));
        }
        else {
            NotifHelper.error(response.message)
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getAccountNumberDashboardFailed(error));
    }
}

function* fetchDataDashboard({ payload: filter }) {
    try {
        const response = yield call(getDashboardData, filter)
        if (response.status) {
            yield put(getDataDashboardSuccess(response.data));
        }
        else {
            getDataDashboardFailed(error)
            NotifHelper.error(response.message)
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getDataDashboardFailed(error));
    }
}

function* dashboardSaga() {
    yield all([fork(watchGetChartsData)]);
    yield takeEvery(GET_ACCOUNT_NUMBER_DASHBOARD, fetchAccountNumbersData)
    yield takeEvery(GET_DATA_DASHBOARD, fetchDataDashboard)
}

export default dashboardSaga;
