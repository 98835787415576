import React, { useState, useMemo, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Label,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import Select from "react-select";
import TableContainer from '../../components/Common/TableContainer';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanies as onGetCompanies,
    getListAccount as onGetListAccount,
    getListAccountNumber as onGetAccountNumbers,
    deleteAccountNumber as onDeleteAccountNumber
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import DeleteModal from "../../components/Common/ModalDelete";


const AccountNumber = () => {

    //meta title
    document.title = "Account Number | Master Data | Des Counts";

    const dispatch = useDispatch();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [accountNumber, setAccountNumber] = useState({});

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }

    const [selectedAccount, setSelectedAccount] = useState(null);
    const [optionAccount, setOptionAccount] = useState([]);
    function handleSelectAccount(e) {
        setSelectedAccount(e);
    }

    const filterName = useRef("");
    const filterNumber = useRef("");
    const filterCompany = useRef("");
    const filterAccount = useRef("");

    const { companies, loadingCompany, listAccount, loadingListAccount } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
        listAccount: state.Account.listAccount,
        loadingListAccount: state.Account.loading
    }));

    const { accountNumbers, loading } = useSelector(state => ({
        accountNumbers: state.AccountNumber.listAccountNumber,
        loading: state.AccountNumber.loading,
    }));

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Number',
                accessor: 'number',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Associate Account',
                accessor: 'account',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value?.name ? (cellProps.value?.code + " - " + cellProps.value?.name) :"[Not Set]";
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                headerCellStyle: 'text-center',
                style: {
                    textAlign: "center",
                },
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <Link
                                to={"/masterdata/account-number-create-update/" + cellProps.row.original.companyId + "/" + cellProps.row.original.id}
                                className="text-success"
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    onOpenDeleteCommand(data);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    const handleOrderClicks = () => {
    }

    const onRefreshCommand = () => {
        if (selectedCompany) {
            dispatch(onGetAccountNumbers({
                companyId: selectedCompany ? selectedCompany.value : null,
                name: filterName.current.value,
                number: filterNumber.current.value,
                accountId: selectedAccount ? selectedAccount.value : null
            }));
        }
    }

    const onResetFilterCommand = () => {
        filterCode.current.value = '';
        filterNumber.current.value = '';
        filterCompany.current.clearValue();
        filterAccount.current.clearValue();
    }

    const onOpenDeleteCommand = (data) => {
        setAccountNumber(data)
        setIsOpenDelete(true);
    }

    const onDeleteCommand = () => {
        setIsOpenDelete(false)
        dispatch(onDeleteAccountNumber(accountNumber, {
            companyId: selectedCompany ? selectedCompany.value : null,
            name: filterName.current.value,
            number: filterNumber.current.value,
        }))
    }

    useEffect(() => {
        onRefreshCommand();
    }, [dispatch]);

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (listAccount) {
            setOptionAccount(listAccount.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [listAccount])

    useEffect(() => {
        if (selectedCompany) {
            dispatch(onGetListAccount({ companyId: selectedCompany.value }));
        }
    }, [selectedCompany])

    useEffect(() => {
        dispatch(onGetCompanies({}));
    }, [])

    return (
        <React.Fragment>
            <DeleteModal show={isOpenDelete} onDeleteClick={onDeleteCommand} onCloseClick={() => setIsOpenDelete(false)} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Master Data" breadcrumbItem="Account Number" />
                    <Row className="mb-3">
                        <Col xs="12">
                            <Card>
                                <CardBody className="pt-0">
                                    <CardTitle className="my-3">Filter</CardTitle>
                                    <Row>
                                        <Col sm={3}>
                                            <div>
                                                <Label>Company</Label>
                                                <Select
                                                    value={selectedCompany}
                                                    onChange={handleSelectCompany}
                                                    options={optionCompany}
                                                    isLoading={loadingCompany}
                                                    isClearable={true}
                                                    ref={filterCompany}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={3}>
                                            <div>
                                                <Label>Account</Label>
                                                <Select
                                                    value={selectedAccount}
                                                    onChange={handleSelectAccount}
                                                    options={optionAccount}
                                                    isLoading={loadingListAccount}
                                                    isClearable={true}
                                                    ref={filterAccount}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={3}>
                                            <div>
                                                <Label>Name</Label>
                                                <input className="form-control" type="text" ref={filterName} />
                                            </div>
                                        </Col>
                                        <Col sm={3}>
                                            <div>
                                                <Label>Number</Label>
                                                <input className="form-control" type="text" ref={filterNumber} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="justify-content-end">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light  float-end" style={{ minWidth: '200px' }}
                                                disabled={loading}
                                                onClick={onRefreshCommand}>
                                                <i className="bx bx-filter-alt"></i>
                                            </Button>
                                            <Button color="info" className="btn btn-info waves-effect waves-light me-2 float-end" style={{ minWidth: '100px' }}
                                                disabled={loading}
                                                onClick={onResetFilterCommand}>
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Link to="/masterdata/account-number-create-update">
                                            <Button color="success" className="btn btn-success waves-effect waves-light">
                                                Add Account Number
                                            </Button>
                                        </Link>
                                    </div>
                                    {
                                        !loading && selectedCompany &&
                                        <>
                                            <TableContainer
                                                columns={columns}
                                                data={accountNumbers}
                                                isGlobalFilter={false}
                                                isAddOptions={true}
                                                handleOrderClicks={handleOrderClicks}
                                                customPageSize={10}
                                            />
                                        </>
                                    }
                                    {
                                        !loading && !selectedCompany &&
                                        <div className="d-flex justify-content-center my-5">
                                            <p>Please select company.</p>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

AccountNumber.propTypes = {
    accountNumbers: PropTypes.array,
    className: PropTypes.string,
    onGetCompanies: PropTypes.func,
    onGetAccountNumbers: PropTypes.func,
    onDeleteAccountNumber: PropTypes.func,
    preGlobalFilteredRows: PropTypes.any,
};

export default AccountNumber
