export const MENU = [
    {
        id: 1,
        path: '/masterdata/company'
    },
    {
        id: 2,
        path: '/masterdata/account'
    },
    {
        id: 3,
        path: '/masterdata/account-number'
    },
    {
        id: 4,
        path: '/masterdata/period'
    },
    {
        id: 9,
        path: '/masterdata/asset-category'
    },
    {
        id: 10,
        path: '/masterdata/asset-type'
    },
    {
        id: 7,
        path: '/account-transaction'
    },
    {
        id: 8,
        path: '/worksheet'
    },
    {
        id: 12,
        path: '/piutang'
    },
    {
        id: 11,
        path: '/asset'
    },
    {
        id: 5,
        path: '/usermanagement/role'
    },
    {
        id: 6,
        path: '/usermanagement/user'
    },
]