/* PERIOD */
export const GET_LIST_ASSET = "GET_LIST_ASSET";
export const GET_LIST_ASSET_SUCCESS = "GET_LIST_ASSET_SUCCESS";
export const GET_LIST_ASSET_FAIL = "GET_LIST_ASSET_FAIL";

export const GET_ASSET = "GET_ASSET";
export const GET_ASSET_SUCCESS = "GET_ASSET_SUCCESS";
export const GET_ASSET_FAIL = "GET_ASSET_FAIL";

export const ADD_ASSET = "ADD_ASSET";
export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS";
export const ADD_ASSET_FAIL = "ADD_ASSET_FAIL";

export const UPDATE_ASSET = "UPDATE_ASSET";
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAIL = "UPDATE_ASSET_FAIL";

export const DELETE_ASSET = "DELETE_ASSET";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAIL = "DELETE_ASSET_FAIL";

export const EXPORT_EXCEL_ASSET = "EXPORT_EXCEL_ASSET";
export const EXPORT_EXCEL_ASSET_SUCCESS = "EXPORT_EXCEL_ASSET_SUCCESS";
export const EXPORT_EXCEL_ASSET_FAILED = "EXPORT_EXCEL_ASSET_FAILED";

export const GET_TEMPLATE_EXCEL_ASSET = "GET_TEMPLATE_EXCEL_ASSET";
export const GET_TEMPLATE_EXCEL_ASSET_SUCCESS = "GET_TEMPLATE_EXCEL_ASSET_SUCCESS";
export const GET_TEMPLATE_EXCEL_ASSET_FAILED = "GET_TEMPLATE_EXCEL_ASSET_FAILED";

export const SUBMIT_IMPORT_EXCEL_ASSET = "SUBMIT_IMPORT_EXCEL_ASSET";
export const SUBMIT_IMPORT_EXCEL_ASSET_SUCCESS = "SUBMIT_IMPORT_EXCEL_ASSET_SUCCESS";
export const SUBMIT_IMPORT_EXCEL_ASSET_FAILED = "SUBMIT_IMPORT_EXCEL_ASSET_FAILED";
