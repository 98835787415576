import {
  GET_PERIODS,
  GET_PERIODS_SUCCESS,
  GET_PERIODS_FAIL,
  GET_PERIOD,
  GET_PERIOD_SUCCESS,
  GET_PERIOD_FAIL,
  ADD_PERIOD,
  ADD_PERIOD_FAIL,
  UPDATE_PERIOD,
  UPDATE_PERIOD_FAIL,
  DELETE_PERIOD,
  DELETE_PERIOD_FAIL,
  UPDATE_PERIOD_SUCCESS,
  ADD_PERIOD_SUCCESS,
  CLEAR_PERIODS,
  CLOSE_PERIOD,
  CLOSE_PERIOD_SUCCESS,
  CLOSE_PERIOD_FAIL,
  REOPEN_PERIOD,
  REOPEN_PERIOD_SUCCESS,
  REOPEN_PERIOD_FAIL,
  GET_TEMPLATE_BEGINNING_BALANCE,
  GET_TEMPLATE_BEGINNING_BALANCE_SUCCESS,
  GET_TEMPLATE_BEGINNING_BALANCE_FAIL,
  SUBMIT_BEGINNING_BALANCE,
  SUBMIT_BEGINNING_BALANCE_SUCCESS,
  SUBMIT_BEGINNING_BALANCE_FAIL
} from "./actionTypes";

const INIT_STATE = {
  periods: [],
  period: null,
  error: '',
  loading: false,
  loadingTemplate: false,
  loadingSubmit: false
};

const Period = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PERIODS:
      return {
        ...state,
        loading: true,
      };
    case GET_PERIODS_SUCCESS:
      return {
        ...state,
        periods: action.payload,
        loading: false,
      };
    case GET_PERIODS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_PERIOD:
      return {
        ...state,
        loading: true,
      };
    case GET_PERIOD_SUCCESS:
      return {
        ...state,
        period: action.payload,
        loading: false,
      };
    case GET_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_PERIOD:
      return {
        ...state,
        loading: true,
      };
    case ADD_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_PERIOD:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_PERIOD:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CLEAR_PERIODS:
      return {
        ...state,
        periods: [],
      };

    case CLOSE_PERIOD:
      return {
        ...state,
        loading: true,
      };
    case CLOSE_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CLOSE_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case REOPEN_PERIOD:
      return {
        ...state,
        loading: true,
      };
    case REOPEN_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REOPEN_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_TEMPLATE_BEGINNING_BALANCE:
      return {
        ...state,
        loadingTemplate: true,
      };
    case GET_TEMPLATE_BEGINNING_BALANCE_SUCCESS:
      return {
        ...state,
        loadingTemplate: false,
      };
    case GET_TEMPLATE_BEGINNING_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingTemplate: false,
      };

    case SUBMIT_BEGINNING_BALANCE:
      return {
        ...state,
        loadingSubmit: true,
      };
    case SUBMIT_BEGINNING_BALANCE_SUCCESS:
      return {
        ...state,
        loadingSubmit: false,
      };
    case SUBMIT_BEGINNING_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSubmit: false,
      };

    default:
      return state;
  }
};

export default Period;
