import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";



const SidebarContent = props => {
  const ref = useRef();

  const { access } = useSelector(state => ({
    access: state.Login.access
  }));

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/dashboard" >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            {
              access && access.length > 0 &&
              (access.includes(1) ||
                access.includes(4) ||
                access.includes(2) ||
                access.includes(3) ||
                access.includes(9) ||
                access.includes(10)) &&
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-data"></i>
                  <span>{props.t("Master Data")}</span>
                </Link>
                <ul className="sub-menu">
                  {
                    access.includes(1) &&
                    <li>
                      <Link to="/masterdata/company">{props.t("Company")}</Link>
                    </li>
                  }
                  {
                    access.includes(4) &&
                    <li>
                      <Link to="/masterdata/period">{props.t("Period")}</Link>
                    </li>
                  }
                  {
                    access.includes(2) &&
                    <li>
                      <Link to="/masterdata/account">{props.t("Account")}</Link>
                    </li>
                  }
                  {
                    access.includes(3) &&
                    <li>
                      <Link to="/masterdata/account-number">{props.t("Account Number")}</Link>
                    </li>
                  }
                  {
                    access.includes(9) &&
                    <li>
                      <Link to="/masterdata/asset-category">{props.t("Asset Category")}</Link>
                    </li>
                  }
                  {
                    access.includes(10) &&
                    <li>
                      <Link to="/masterdata/asset-type">{props.t("Asset Type")}</Link>
                    </li>
                  }
                </ul>
              </li>
            }

            {
              access && access.length > 0 &&
              access.includes(7) &&
              <li>
                <Link to="/account-transaction" >
                  <i className="bx bx-dollar-circle"></i>
                  <span>{props.t("Transaction")}</span>
                </Link>
              </li>
            }

            {
              access && access.length > 0 &&
              access.includes(8) &&
              <li>
                <Link to="/worksheet" >
                  <i className="bx bx-laptop"></i>
                  <span>{props.t("Worksheet")}</span>
                </Link>
              </li>
            }

            {
              access && access.length > 0 &&
              access.includes(12) &&
              <li>
                <Link to="/piutang" >
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Piutang")}</span>
                </Link>
              </li>
            }

            {
              access && access.length > 0 &&
              access.includes(11) &&
              <li>
                <Link to="/asset" >
                  <i className="bx bx-archive"></i>
                  <span>{props.t("Asset")}</span>
                </Link>
              </li>
            }

            {
              access && access.length > 0 &&
              (access.includes(13) ||
                access.includes(14)) &&
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-report"></i>
                  <span>{props.t("Report")}</span>
                </Link>
                <ul className="sub-menu">
                  {
                    access.includes(13) &&
                    <li>
                      <Link to="/report-neraca" >
                        <span>{props.t("Neraca")}</span>
                      </Link>
                    </li>
                  }
                  {
                    access.includes(14) &&
                    <li>
                      <Link to="/report-profitloss" >
                        <span>{props.t("Profit & Loss")}</span>
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }

            {
              access && access.length > 0 &&
              (access.includes(5) ||
                access.includes(6)) &&
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user"></i>
                  <span>{props.t("User Management")}</span>
                </Link>
                <ul className="sub-menu">
                  {
                    access.includes(5) &&
                    <li>
                      <Link to="/usermanagement/role">{props.t("Role")}</Link>
                    </li>
                  }
                  {
                    access.includes(6) &&
                    <li>
                      <Link to="/usermanagement/user">{props.t("User")}</Link>
                    </li>
                  }
                </ul>
              </li>
            }

            {
              access && access.length > 0 &&
              access.includes(15) &&
              <li>
                <Link to="/import-beginning-balance" >
                  <i className="bx bx-upload"></i>
                  <span>{props.t("Beginning Balance")}</span>
                </Link>
              </li>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
