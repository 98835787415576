import {
  GET_LIST_USER,
  GET_LIST_USER_SUCCESS,
  GET_LIST_USER_FAIL,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_LIST_USER,
  RESET_PASSWORD_USER,
  RESET_PASSWORD_USER_SUCCESS,
  RESET_PASSWORD_USER_FAIL,
  CHANGE_PASSWORD_USER,
  CHANGE_PASSWORD_USER_SUCCESS,
  CHANGE_PASSWORD_USER_FAIL,
} from "./actionTypes";

export const getListUser = filter => ({
  type: GET_LIST_USER,
  payload: filter
});
export const getListUserSuccess = listUser => ({
  type: GET_LIST_USER_SUCCESS,
  payload: listUser,
});
export const getListUserFail = error => ({
  type: GET_LIST_USER_FAIL,
  payload: error,
});

export const getUserById = (id) => ({
  type: GET_USER,
  payload: { id }
});
export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: user,
});
export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
});

export const addUser = (user, history) => ({
  type: ADD_USER,
  payload: { user, history },
});
export const addUserSuccess = () => ({
  type: ADD_USER_SUCCESS,
});
export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
});

export const updateUser = (user, history) => ({
  type: UPDATE_USER,
  payload: { user, history },
});
export const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS,
});
export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

export const deleteUser = (user, filter) => ({
  type: DELETE_USER,
  payload: { user, filter },
});
export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});
export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

export const clearListUser = () => ({
  type: CLEAR_LIST_USER,
});

export const resetPasswordUser = (param) => ({
  type: RESET_PASSWORD_USER,
  payload: { param },
});
export const resetPasswordUserSuccess = (password) => ({
  type: RESET_PASSWORD_USER_SUCCESS,
  payload: password
});
export const resetPasswordUserFail = error => ({
  type: RESET_PASSWORD_USER_FAIL,
  payload: error,
});

export const changePasswordUser = (param) => ({
  type: CHANGE_PASSWORD_USER,
  payload: { param },
});
export const changePasswordUserSuccess = () => ({
  type: CHANGE_PASSWORD_USER_SUCCESS,
});
export const changePasswordUserFail = error => ({
  type: CHANGE_PASSWORD_USER_FAIL,
  payload: error,
});