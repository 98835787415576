import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Company
import Company from "./company/reducer"

// Period
import Period from "./period/reducer"

// Account
import Account from "./account/reducer"

// Account Number
import AccountNumber from "./accountnumber/reducer"

// Asset Category
import AssetCategory from "./assetcategory/reducer"

// Asset Type
import AssetType from "./assettype/reducer"

// Asset
import Asset from "./asset/reducer"

// Piutang
import Piutang from "./piutang/reducer"

// Account Transaction
import AccountTransaction from "./accounttransaction/reducer"

// Worksheet
import Worksheet from "./worksheet/reducer"

// Role
import Role from "./role/reducer"

// User
import User from "./user/reducer"

//Report
import Report from "./report/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"

//Dashboard 
import Dashboard from "./dashboard/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,

  Company,
  Period,
  Account,
  AccountNumber,
  AssetCategory,
  AssetType,
  Asset,
  Piutang,

  AccountTransaction,
  Worksheet,
  Role,
  User,

  Report,

  Profile,
  Dashboard,
})

export default rootReducer
