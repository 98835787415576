import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";

import { Switch, BrowserRouter as Router, useLocation, } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import { useSelector, useDispatch } from "react-redux";
import { MENU } from 'constants/menuList'
import {
  getAccess as onGetAccess,
} from "./store/actions";
// Import scss
import "./assets/scss/theme.scss";

const App = props => {
  const dispatch = useDispatch()

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const { access } = useSelector(state => ({
    access: state.Login.access
  }))
  const location = useLocation()

  useEffect(() => {
    if (location && location.pathname != "/login" && location.pathname != "/" && access) {
      var find = MENU.filter(s => s.path == location.pathname);
      if (find.length > 0 && !access.includes(find[0].id)) {
        window.location.href = "/login"
      }
    }
  }, [location, access])

  useEffect(() => {
    if (!access || access == null) {
      dispatch(onGetAccess())
    }
  }, [dispatch])

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};


export default connect(mapStateToProps, null)(App);
