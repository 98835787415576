import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from 'react-router-dom';
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Alert
} from "reactstrap";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
    getAccountNumberById as onGetAccountNumber,
    updateAccountNumber as onUpdateAccountNumber,
    addAccountNumber as onAddAccountNumber,
    getCompanies as onGetCompanies,
    getListAccount as onGetAccounts
} from "../../store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const AccountNumberCreateUpdate = (props) => {

    //meta title
    document.title = "Account Number | Master Data | Des Counts";
    const id = props?.match?.params?.id;
    const companyId = props?.match?.params?.companyId;
    const isEdit = id != undefined && companyId != undefined;

    const { companies, accountNumber, loading, error, accounts, loadingAccount } = useSelector(state => ({
        companies: state.Company.companies,
        accountNumber: state.AccountNumber.accountNumber,
        loading: state.AccountNumber.loading,
        error: state.AccountNumber.error,
        accounts: state.Account.listAccount,
        loadingAccount: state.Account.loading
    }));

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e)
        validation.setFieldValue("companyId", e.value.toString(), true)
    }

    const [selectedAccount, setSelectedAccount] = useState(null);
    const [optionAccount, setOptionAccount] = useState([]);
    function handleSelectAccount(e) {
        setSelectedAccount(e)
        validation.setFieldValue("accountId", e.value.toString(), true)
    }

    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyId: isEdit && accountNumber ? accountNumber.companyId : "",
            name: isEdit && accountNumber ? accountNumber.name : "",
            number: isEdit && accountNumber ? accountNumber.number : "",
            accountId: isEdit && accountNumber ? accountNumber.accountId : "",
        },
        validationSchema: Yup.object({
            companyId: Yup.string().required("Please Select Company"),
            name: Yup.string().required("Please Enter Account Name"),
            number: Yup.string().required("Please Enter Number"),
            accountId: Yup.string().required("Please Select Associate Account"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                dispatch(onUpdateAccountNumber({ id: accountNumber.id, companyId: accountNumber.companyId, accountId: values.accountId, name: values.name, number: values.number }, props.history));
            } else {
                dispatch(onAddAccountNumber({
                    name: values.name,
                    number: values.number,
                    companyId: parseInt(values.companyId),
                    accountId: values.accountId
                }, props.history));
            }
        },
    });


    useEffect(() => {
        dispatch(onGetCompanies({}))
        if (isEdit) {
            dispatch(onGetAccountNumber({ companyId: companyId, id: id }))
        }
    }, [dispatch])

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id.toString() }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (accounts) {
            setOptionAccount(accounts.map((item) => {
                return { label: item.code + ' - ' + item.name, value: item.id.toString() }
            }))
        }
    }, [accounts])

    useEffect(() => {
        if (selectedCompany) {
            dispatch(onGetAccounts({ companyId: selectedCompany.value }))
        }
    }, [selectedCompany])

    useEffect(() => {
        if (isEdit && accountNumber && companies && accounts) {
            var company = optionCompany.filter(s => s.value == accountNumber.companyId)
            if (company.length > 0) {
                setSelectedCompany(company[0])
            }
            var account = optionAccount.filter(s => s.value == accountNumber.accountId)
            if (account.length > 0) {
                setSelectedAccount(account[0])
            }
        }
    }, [optionCompany, optionAccount, accountNumber])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Master Data" breadcrumbItem={(isEdit ? "Edit" : "Add") + " Account Number"} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {
                                        !loading &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <Form
                                                        className="form-horizontal"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <Row className="mb-3">
                                                            <Col sm={6}>
                                                                <Label>Company</Label>
                                                                <div
                                                                    style={validation.touched.companyId && validation.errors.companyId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedCompany}
                                                                        onChange={handleSelectCompany}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionCompany}
                                                                        isDisabled={isEdit}
                                                                        className="select2-selection"
                                                                        menuPosition="fixed"
                                                                    />
                                                                </div>
                                                                {validation.touched.companyId && validation.errors.companyId ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.companyId}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Label>Account</Label>
                                                                <div
                                                                    style={validation.touched.accountId && validation.errors.accountId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedAccount}
                                                                        onChange={handleSelectAccount}
                                                                        onBlur={validation.handleBlur}
                                                                        loading={loadingAccount}
                                                                        options={optionAccount}
                                                                        className="select2-selection"
                                                                        menuPosition="fixed"
                                                                    />
                                                                </div>
                                                                {validation.touched.accountId && validation.errors.accountId ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.accountId}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className="col-12 col-sm-6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label">Name</Label>
                                                                    <Input
                                                                        name="name"
                                                                        className="form-control"
                                                                        placeholder="Enter account name"
                                                                        type="text"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.name || ""}
                                                                        invalid={
                                                                            validation.touched.name && validation.errors.name ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.name && validation.errors.name ? (
                                                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                            <Col className="col-12 col-sm-6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label">Number</Label>
                                                                    <Input
                                                                        name="number"
                                                                        className="form-control"
                                                                        placeholder="Enter account number"
                                                                        type="text"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.number || ""}
                                                                        invalid={
                                                                            validation.touched.number && validation.errors.number ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.number && validation.errors.number ? (
                                                                        <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <div className="mt-3 mb-2">
                                                            <Link to="/masterdata/account-number">
                                                                <Button
                                                                    color="secondary"
                                                                    className="btn btn-secondary float-end"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                color="primary"
                                                                className="btn btn-primary float-end me-2"
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

AccountNumberCreateUpdate.propTypes = {
    history: PropTypes.object,
};

export default withRouter(AccountNumberCreateUpdate)
