/* PERIOD */
export const GET_LIST_ACCOUNT_NUMBER = "GET_LIST_ACCOUNT_NUMBER";
export const GET_LIST_ACCOUNT_NUMBER_SUCCESS = "GET_LIST_ACCOUNT_NUMBER_SUCCESS";
export const GET_LIST_ACCOUNT_NUMBER_FAIL = "GET_LIST_ACCOUNT_NUMBER_FAIL";

export const GET_ACCOUNT_NUMBER = "GET_ACCOUNT_NUMBER";
export const GET_ACCOUNT_NUMBER_SUCCESS = "GET_ACCOUNT_NUMBER_SUCCESS";
export const GET_ACCOUNT_NUMBER_FAIL = "GET_ACCOUNT_NUMBER_FAIL";

export const ADD_ACCOUNT_NUMBER = "ADD_ACCOUNT_NUMBER";
export const ADD_ACCOUNT_NUMBER_SUCCESS = "ADD_ACCOUNT_NUMBER_SUCCESS";
export const ADD_ACCOUNT_NUMBER_FAIL = "ADD_ACCOUNT_NUMBER_FAIL";

export const UPDATE_ACCOUNT_NUMBER = "UPDATE_ACCOUNT_NUMBER";
export const UPDATE_ACCOUNT_NUMBER_SUCCESS = "UPDATE_ACCOUNT_NUMBER_SUCCESS";
export const UPDATE_ACCOUNT_NUMBER_FAIL = "UPDATE_ACCOUNT_NUMBER_FAIL";

export const DELETE_ACCOUNT_NUMBER = "DELETE_ACCOUNT_NUMBER";
export const DELETE_ACCOUNT_NUMBER_FAIL = "DELETE_ACCOUNT_NUMBER_FAIL";

export const CLEAR_LIST_ACCOUNT_NUMBER = "CLEAR_LIST_ACCOUNT_NUMBER";