import {
  GET_LIST_ACCOUNT_TRANSACTION,
  GET_LIST_ACCOUNT_TRANSACTION_SUCCESS,
  GET_LIST_ACCOUNT_TRANSACTION_FAIL,
  GET_ACCOUNT_TRANSACTION,
  GET_ACCOUNT_TRANSACTION_SUCCESS,
  GET_ACCOUNT_TRANSACTION_FAIL,
  ADD_ACCOUNT_TRANSACTION,
  ADD_ACCOUNT_TRANSACTION_SUCCESS,
  ADD_ACCOUNT_TRANSACTION_FAIL,
  DELETE_ACCOUNT_TRANSACTION,
  DELETE_ACCOUNT_TRANSACTION_SUCCESS,
  DELETE_ACCOUNT_TRANSACTION_FAIL,
  CLEAR_LIST_ACCOUNT_TRANSACTION,
  ADD_BY_EXCEL_ACCOUNT_TRANSACTION,
  ADD_BY_EXCEL_ACCOUNT_TRANSACTION_SUCCESS,
  ADD_BY_EXCEL_ACCOUNT_TRANSACTION_FAIL,
  EXPORT_EXCEL_ACCOUNT_TRANSACTION,
  EXPORT_EXCEL_ACCOUNT_TRANSACTION_SUCCESS,
  EXPORT_EXCEL_ACCOUNT_TRANSACTION_FAIL,
  UPDATE_ACCOUNT_TRANSACTION,
  UPDATE_ACCOUNT_TRANSACTION_SUCCESS,
  UPDATE_ACCOUNT_TRANSACTION_FAIL
} from "./actionTypes";

const INIT_STATE = {
  listAccountTransaction: [],
  accountTransaction: null,
  error: '',
  loading: false
};

const AccountTransaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_ACCOUNT_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        listAccountTransaction: action.payload,
        loading: false,
      };
    case GET_LIST_ACCOUNT_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ACCOUNT_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        accountTransaction: action.payload,
        loading: false,
      };
    case GET_ACCOUNT_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };


    case ADD_ACCOUNT_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case ADD_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ACCOUNT_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_ACCOUNT_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ACCOUNT_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };


    case DELETE_ACCOUNT_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ACCOUNT_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CLEAR_LIST_ACCOUNT_TRANSACTION:
      return {
        ...state,
        listAccountTransaction: [],
        loading: false,
      };

    case ADD_BY_EXCEL_ACCOUNT_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case ADD_BY_EXCEL_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_BY_EXCEL_ACCOUNT_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case EXPORT_EXCEL_ACCOUNT_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_EXCEL_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EXPORT_EXCEL_ACCOUNT_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default AccountTransaction;
