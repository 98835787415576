import {
    GET_REPORT_EXCEL,
    GET_REPORT_EXCEL_SUCCESS,
    GET_REPORT_EXCEL_FAIL,
    GET_REPORT_PDF,
    GET_REPORT_PDF_SUCCESS,
    GET_REPORT_PDF_FAIL,
    GET_REPORT_PROFITLOSS_EXCEL,
    GET_REPORT_PROFITLOSS_EXCEL_SUCCESS,
    GET_REPORT_PROFITLOSS_EXCEL_FAIL,
    GET_REPORT_PROFITLOSS_PDF,
    GET_REPORT_PROFITLOSS_PDF_SUCCESS,
    GET_REPORT_PROFITLOSS_PDF_FAIL
  } from "./actionTypes";

  export const getReportExcel = (param) => ({
    type: GET_REPORT_EXCEL,
    payload: { param },
  });
  export const getReportExcelSuccess = () => ({
    type: GET_REPORT_EXCEL_SUCCESS,
  });
  export const getReportExcelFail = error => ({
    type: GET_REPORT_EXCEL_FAIL,
    payload: error,
  });

  export const getReportPdf = (param) => ({
    type: GET_REPORT_PDF,
    payload: { param },
  });
  export const getReportPdfSuccess = () => ({
    type: GET_REPORT_PDF_SUCCESS,
  });
  export const getReportPdfFail = error => ({
    type: GET_REPORT_PDF_FAIL,
    payload: error,
  });

  export const getReportProfitLossExcel = (param) => ({
    type: GET_REPORT_PROFITLOSS_EXCEL,
    payload: { param },
  });
  export const getReportProfitLossExcelSuccess = () => ({
    type: GET_REPORT_PROFITLOSS_EXCEL_SUCCESS,
  });
  export const getReportProfitLossExcelFail = error => ({
    type: GET_REPORT_PROFITLOSS_EXCEL_FAIL,
    payload: error,
  });

  export const getReportProfitLossPdf = (param) => ({
    type: GET_REPORT_PROFITLOSS_PDF,
    payload: { param },
  });
  export const getReportProfitLossPdfSuccess = () => ({
    type: GET_REPORT_PROFITLOSS_PDF_SUCCESS,
  });
  export const getReportProfitLossPdfFail = error => ({
    type: GET_REPORT_PROFITLOSS_PDF_FAIL,
    payload: error,
  });