import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from 'react-router-dom';
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormGroup,
    InputGroup,
    FormFeedback,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Alert
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import CurrencyFormat from 'react-currency-format';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
    getCompanies as onGetCompanies,
    getPeriods as onGetPeriods,
    getListAccount as onGetAccounts,
    getListAccountNumber as onGetAccountNumbers,
    addAccountTransaction as onAddAccountTransaction,
    clearPeriods as onClearPeriods,
    clearListAccount as onClearListAccount,
    clearListAccountNumber as onClearListAccountNumber,
    clearListAccountTransaction as onClearListAccountTransaction,
    getAccountTransaction as onGetAccountTransaction,
    updateAccountTransaction as onUpdateAccountTransaction
} from "../../store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const AccountTransactionCreate = (props) => {

    //meta title
    const id = props?.match?.params?.id;
    const companyId = props?.match?.params?.companyId;
    const periodId = props?.match?.params?.periodId;
    const accountId = props?.match?.params?.accountId;
    const accountNumberId = props?.match?.params?.accountNumberId;
    const isEdit = id != undefined && companyId != undefined && periodId != undefined && accountId != undefined && accountNumberId != undefined;
    const dispatch = useDispatch();
    const [nominalValue, setNominalValue] = useState(0)
    document.title = (isEdit ? "Edit" : "Add") + " Transaction | Des Counts";

    const {
        companies,
        loadingCompany,
        periods,
        loadingPeriod,
        accounts,
        loadingAccount,
        accountNumbers,
        loadingAccountNumber,
        accountTransaction,
        loading
    } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
        periods: state.Period.periods,
        loadingPeriod: state.Period.loading,
        accounts: state.Account.listAccount,
        loadingAccount: state.Account.loading,
        accountNumbers: state.AccountNumber.listAccountNumber,
        loadingAccountNumber: state.AccountNumber.loading,
        accountTransaction: state.AccountTransaction.accountTransaction,
        loading: state.AccountTransaction.loading,
    }));

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyId: isEdit && accountTransaction ? accountTransaction.companyId : null,
            periodId: isEdit && accountTransaction ? accountTransaction.periodId : null,
            accountId: isEdit && accountTransaction ? accountTransaction.accountId : null,
            accountNumberId: isEdit && accountTransaction ? accountTransaction.accountNumberId : null,
            date: isEdit && accountTransaction ? accountTransaction.dateShort : "",
            remarks: isEdit && accountTransaction ? accountTransaction.remarks : "",
            type: isEdit && accountTransaction ? accountTransaction.type : "",
            nominal: isEdit && accountTransaction ? accountTransaction.nominal : 0,
            fiscal: isEdit && accountTransaction ? accountTransaction.fiscal : null,
            adjustment: isEdit && accountTransaction ? accountTransaction.adjustment : null,
        },
        validationSchema: Yup.object({
            companyId: Yup.number().required("Please Select Company").nullable(),
            periodId: Yup.number().required("Please Select Period").nullable(),
            accountId: Yup.number().required("Please Select Account").nullable(),
            accountNumberId: Yup.number().required("Please Select Account Number").nullable(),
            date: Yup.string().required("Please Select Date"),
            remarks: Yup.string().required("Please Enter Remarks"),
            type: Yup.string().required("Please Select Type"),
            nominal: Yup.number().required("Please Enter Nominal").nullable(),
            fiscal: Yup.string().required("Please Select Fiscal").nullable(),
            adjustment: Yup.string().required("Please Select Adjustment").nullable(),
        }),
        onSubmit: (values) => {
            var data = {
                companyId: values.companyId,
                periodId: values.periodId,
                accountId: values.accountId,
                accountNumberId: values.accountNumberId,
                remarks: values.remarks,
                date: values.date,
                type: values.type,
                nominal: values.nominal,
                fiscal: values.fiscal,
                adjustment: values.adjustment,
            }
            if (isEdit) {
                data.id = accountTransaction.id;
                dispatch(onUpdateAccountTransaction(data, props.history));
            }
            else {
                dispatch(onAddAccountTransaction(data, props.history));
            }
        },
    });

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
        validation.setFieldValue("companyId", e ? e.value : null, true)
    }
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [optionPeriod, setOptionPeriod] = useState([]);
    function handleSelectPeriod(e) {
        setSelectedPeriod(e);
        validation.setFieldValue("periodId", e ? e.value : null, true)
    }
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [optionAccount, setOptionAccount] = useState([]);
    function handleSelectAccount(e) {
        setSelectedAccount(e);
        validation.setFieldValue("accountId", e ? e.value : null, true)
    }
    const [selectedAccountNumber, setSelectedAccountNumber] = useState(null);
    const [optionAccountNumber, setOptionAccountNumber] = useState([]);
    function handleSelectAccountNumber(e) {
        setSelectedAccountNumber(e);
        validation.setFieldValue("accountNumberId", e ? e.value : null, true)
    }
    const [selectedType, setSelectedType] = useState(null);
    const [optionType] = useState([
        { label: "DEBIT", value: "debit" },
        { label: "CREDIT", value: "credit" },
    ]);
    function handleSelectType(e) {
        setSelectedType(e);
        validation.setFieldValue("type", e ? e.value : "", true)
    }
    const [selectedFiscal, setSelectedFiscal] = useState(null);
    const [optionFiscal] = useState([
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]);
    function handleSelectFiscal(e) {
        setSelectedFiscal(e);
        validation.setFieldValue("fiscal", e ? e.value : null, true)
    }
    const [selectedAdjustment, setSelectedAdjustment] = useState(null);
    const [optionAdjustment] = useState([
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]);
    function handleSelectAdjustment(e) {
        setSelectedAdjustment(e);
        validation.setFieldValue("adjustment", e ? e.value : null, true)
    }

    function handleDateChange(e) {
        const dd = e[0].getDate().toString();
        const mm = e[0].getMonth() + 1;
        const yyyy = e[0].getFullYear();
        validation.setFieldValue("date", String(yyyy).padStart(2, '0') + "-" + String(mm).padStart(2, '0') + "-" + String(dd).padStart(2, '0'), true)
    }

    function handleChangeNominal(e) {
        setNominalValue(e.floatValue)
        validation.setFieldValue("nominal", e.floatValue, true)
    }

    const filterPeriod = useRef("");
    const filterAccount = useRef("");
    const filterAccountNumber = useRef("");



    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])
    useEffect(() => {
        if (periods) {
            setOptionPeriod(periods.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [periods])
    useEffect(() => {
        if (accounts) {
            setOptionAccount(accounts.map((item) => {
                return { label: item.code + ' - ' + item.name, value: item.id }
            }))
        }
    }, [accounts])
    useEffect(() => {
        if (accountNumbers) {
            setOptionAccountNumber(accountNumbers.map((item) => {
                return { label: item.name + ' - ' + item.number, value: item.id }
            }))
        }
    }, [accountNumbers])

    useEffect(() => {
        if (selectedCompany && !isEdit) {
            dispatch(onClearPeriods())
            dispatch(onClearListAccount())
            dispatch(onClearListAccountNumber())
            filterPeriod.current?.clearValue()
            filterAccount.current?.clearValue()
            filterAccountNumber.current?.clearValue()
            validation.setFieldValue("periodId", null, true)
            validation.setFieldValue("accountId", null, true)
            validation.setFieldValue("accountNumberId", null, true)
            dispatch(onGetPeriods({ companyId: selectedCompany.value }));
            dispatch(onGetAccounts({ companyId: selectedCompany.value }));
            dispatch(onGetAccountNumbers({ companyId: selectedCompany.value }));
        }
    }, [selectedCompany])

    useEffect(() => {
        dispatch(onGetCompanies({}));
        if (isEdit) {
            dispatch(onGetAccountTransaction({
                id: id,
                companyId: companyId,
                periodId: periodId,
                accountId: accountId,
                accountNumberId: accountNumberId
            }))
        }
        else {
            dispatch(onClearPeriods())
            dispatch(onClearListAccount())
            dispatch(onClearListAccountNumber())
        }
    }, [])

    useEffect(() => {
        if (isEdit && accountTransaction && companies) {
            var company = optionCompany.filter(s => s.value == accountTransaction.companyId);
            if (company.length > 0) {
                setSelectedCompany(company[0])
            }
        }
    }, [optionCompany])

    useEffect(() => {
        if (isEdit && accountTransaction && optionAccount && optionAccountNumber && optionPeriod) {
            var account = optionAccount.filter(s => s.value == accountTransaction.accountId);
            if (account.length > 0) {
                setSelectedAccount(account[0])
            }
            var accountNumber = optionAccountNumber.filter(s => s.value == accountTransaction.accountNumberId);
            if (accountNumber.length > 0) {
                setSelectedAccountNumber(accountNumber[0])
            }
            var period = optionPeriod.filter(s => s.value == accountTransaction.periodId);
            if (period.length > 0) {
                setSelectedPeriod(period[0])
            }
        }
    }, [optionAccount, optionAccountNumber, optionPeriod])

    useEffect(() => {
        if (isEdit && accountTransaction) {
            var type = optionType.filter(s => s.value == accountTransaction.type);
            if (type.length > 0) {
                setSelectedType(type[0])
            }
            var fiscal = optionFiscal.filter(s => s.value == accountTransaction.fiscal);
            if (fiscal.length > 0) {
                setSelectedFiscal(fiscal[0])
            }
            var adjustment = optionAdjustment.filter(s => s.value == accountTransaction.adjustment);
            if (adjustment.length > 0) {
                setSelectedAdjustment(adjustment[0])
            }
            setNominalValue(accountTransaction.nominal)
        }
    }, [accountTransaction])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Transaction" breadcrumbItem={(isEdit ? "Edit" : "New") + " Transaction"} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {
                                        !loading && (!isEdit || accountTransaction) &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <Form
                                                        className="form-horizontal"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col className="col-12 col-sm-3">
                                                                <Label>Company</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.companyId && validation.errors.companyId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedCompany}
                                                                        onChange={handleSelectCompany}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionCompany}
                                                                        isLoading={loadingCompany}
                                                                        isDisabled={isEdit}
                                                                        isClearable={false}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                                {validation.touched.companyId && validation.errors.companyId ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.companyId}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-3">
                                                                <Label>Period</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.periodId && validation.errors.periodId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedPeriod}
                                                                        onChange={handleSelectPeriod}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionPeriod}
                                                                        isLoading={loadingPeriod}
                                                                        isClearable={false}
                                                                        className="select2-selection"
                                                                        isDisabled={isEdit}
                                                                        ref={filterPeriod}
                                                                    />
                                                                </div>
                                                                {validation.touched.periodId && validation.errors.periodId ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.periodId}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-3">
                                                                <Label>Account</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.accountId && validation.errors.accountId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedAccount}
                                                                        onChange={handleSelectAccount}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionAccount}
                                                                        isLoading={loadingAccount}
                                                                        isClearable={false}
                                                                        className="select2-selection"
                                                                        isDisabled={isEdit}
                                                                        ref={filterAccount}
                                                                    />
                                                                </div>
                                                                {validation.touched.accountId && validation.errors.accountId ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.accountId}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-3">
                                                                <Label>Account Number</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.accountNumberId && validation.errors.accountNumberId ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedAccountNumber}
                                                                        onChange={handleSelectAccountNumber}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionAccountNumber}
                                                                        isLoading={loadingAccountNumber}
                                                                        isClearable={false}
                                                                        className="select2-selection"
                                                                        isDisabled={isEdit}
                                                                        ref={filterAccountNumber}
                                                                    />
                                                                </div>
                                                                {validation.touched.accountNumberId && validation.errors.accountNumberId ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.accountNumberId}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                        </Row>

                                                        <Row className="mt-3">
                                                            <Col className="col-12 col-sm-3">
                                                                <FormGroup>
                                                                    <Label>Date</Label><Label className="text-danger">*</Label>
                                                                    <InputGroup
                                                                        style={validation.touched.date && validation.errors.date ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                            { border: '0', borderRadius: '0.25rem' }}>
                                                                        <Flatpickr
                                                                            className={"form-control d-block" + (validation.errors.date ? "is-invalid" : "")}
                                                                            placeholder="Select Date"
                                                                            options={{
                                                                                dateFormat: "Y-m-d"
                                                                            }}
                                                                            onChange={handleDateChange}
                                                                            defaultValue={isEdit ? accountTransaction.dateShort : null}
                                                                        />
                                                                    </InputGroup>
                                                                    {validation.touched.date && validation.errors.date ? (
                                                                        <FormFeedback className="d-block" type="invalid">{validation.errors.date}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col className="col-12 col-sm-3">
                                                                <Label>Type</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.type && validation.errors.type ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedType}
                                                                        onChange={handleSelectType}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionType}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                                {validation.touched.type && validation.errors.type ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.type}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-3">
                                                                <div>
                                                                    <Label className="form-label">Remarks</Label><Label className="text-danger">*</Label>
                                                                    <Input
                                                                        name="remarks"
                                                                        className="form-control"
                                                                        placeholder="Enter remarks"
                                                                        type="text"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.remarks || ""}
                                                                        invalid={
                                                                            validation.touched.remarks && validation.errors.remarks ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.remarks && validation.errors.remarks ? (
                                                                        <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                            <Col className="col-12 col-sm-3">
                                                                <Label>Nominal</Label><Label className="text-danger">*</Label>
                                                                <CurrencyFormat thousandSeparator="." decimalSeparator="," value={nominalValue}
                                                                    customInput={Input} className={(validation.touched.nominal && validation.errors.nominal ? " is-invalid text-end" : " text-end")}
                                                                    name="nominal" onValueChange={handleChangeNominal} />
                                                                {validation.touched.nominal && validation.errors.nominal ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.nominal}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                        </Row>

                                                        <Row className="mt-3">
                                                            <Col className="col-12 col-sm-3">
                                                                <Label>Fiscal</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.fiscal && validation.errors.fiscal ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedFiscal}
                                                                        onChange={handleSelectFiscal}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionFiscal}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                                {validation.touched.fiscal && validation.errors.fiscal ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.fiscal}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col className="col-12 col-sm-3">
                                                                <Label>Adjustment</Label><Label className="text-danger">*</Label>
                                                                <div
                                                                    style={validation.touched.adjustment && validation.errors.adjustment ? { border: '1px solid #f46a6a', borderRadius: '0.25rem' } :
                                                                        { border: '0', borderRadius: '0.25rem' }}
                                                                >
                                                                    <Select
                                                                        value={selectedAdjustment}
                                                                        onChange={handleSelectAdjustment}
                                                                        onBlur={validation.handleBlur}
                                                                        options={optionAdjustment}
                                                                        className="select2-selection"
                                                                    />
                                                                </div>
                                                                {validation.touched.adjustment && validation.errors.adjustment ? (
                                                                    <FormFeedback className="d-block" type="invalid">{validation.errors.adjustment}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                        <div className="mt-3 mb-2">
                                                            <Link to="/account-transaction">
                                                                <Button
                                                                    color="secondary"
                                                                    className="btn btn-secondary float-end"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                color="primary"
                                                                className="btn btn-primary float-end me-2"
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

AccountTransactionCreate.propTypes = {
    history: PropTypes.object,
};

export default withRouter(AccountTransactionCreate)
