import React, { useState, useMemo, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Label,
    Card,
    CardBody,
    CardTitle,
    Badge
} from "reactstrap";
import Select from "react-select";
import TableContainer from '../../components/Common/TableContainer';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanies as onGetCompanies,
    getListUser as onGetUsers,
    deleteUser as onDeleteUser,
    clearListUser as onClearListUser,
    resetPasswordUser as onResetPasswordUser
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import DeleteModal from "../../components/Common/ModalDelete";
import ModalResetPassword from "./ModalResetPassword";
import { SuccessAlert } from "../../components/Common/Swall"
import { RESET_PASSWORD_USER_SUCCESS } from "constants/responseMessage";

const User = () => {

    //meta title
    document.title = "User | User Management | Des Counts";

    const dispatch = useDispatch();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isOpenReset, setIsOpenReset] = useState(false);
    const [user, setUser] = useState({});

    const filterName = useRef("");

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }

    const filterCompany = useRef("");

    const { users, loading, companies, loadingCompany, loadingResetPassword, newResetPassword } = useSelector(state => ({
        users: state.User.listUser,
        loading: state.User.loading,
        companies: state.Company.companies,
        loading: state.Company.loading,
        loadingResetPassword: state.User.loadingResetPassword,
        newResetPassword: state.User.newResetPassword
    }));

    const columns = useMemo(
        () => [
            {
                Header: 'Email',
                accessor: 'username',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Role',
                accessor: 'role',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Company',
                accessor: 'company',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value.map(item => {
                        return <Badge color={'primary'} key={'role' + item.id} className='ms-1'>
                            {item.name}
                        </Badge>
                    });
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                headerCellStyle: 'text-center',
                style: {
                    textAlign: "center",
                },
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <Link
                                to={"#"}
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    onOpenResetPasswordCommand(data)
                                }}
                                className="text-primary">
                                <i className="mdi mdi-lock-reset font-size-18" id="resettooltip" />
                                <UncontrolledTooltip placement="top" target="resettooltip">
                                    Reset Password
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to={"/usermanagement/user-create-update/" + cellProps.row.original.id}
                                className="text-success"
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    onOpenDeleteCommand(data);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    const onRefreshCommand = () => {
        if (selectedCompany) {
            dispatch(onGetUsers({
                companyId: selectedCompany ? selectedCompany.value : null,
            }));
        }
    }

    const onResetFilterCommand = () => {
        filterCompany.current.clearValue()
    }

    const onOpenDeleteCommand = (data) => {
        setUser(data)
        setIsOpenDelete(true);
    }

    const onDeleteCommand = () => {
        setIsOpenDelete(false)
        dispatch(onDeleteUser(user, {
            companyId: selectedCompany ? selectedCompany.value : null,
        }))
    }

    const onOpenResetPasswordCommand = (data) => {
        setUser(data)
        setIsOpenReset(true);
    }

    const onResetPasswordCommand = () => {
        if (user) {
            setIsOpenReset(false);
            dispatch(onResetPasswordUser({
                id: user.id,
            }))
        }
    }

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])

    useEffect(() => {
        if (!selectedCompany) {
            dispatch(onClearListUser())
        }
    }, [selectedCompany])

    useEffect(() => {
        dispatch(onGetCompanies({}));
    }, [dispatch]);

    useEffect(() => {
        if (!loadingResetPassword && newResetPassword) {
            SuccessAlert(RESET_PASSWORD_USER_SUCCESS, "New Password : " + newResetPassword)
        }
    }, [loadingResetPassword])

    return (
        <React.Fragment>
            <DeleteModal show={isOpenDelete} onDeleteClick={onDeleteCommand} onCloseClick={() => setIsOpenDelete(false)} />
            <ModalResetPassword show={isOpenReset} email={user?.username} name={user?.name} onSubmit={onResetPasswordCommand}
                onCancel={() => setIsOpenReset(false)} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="User Management" breadcrumbItem="User's List" />
                    <Row className="mb-3">
                        <Col xs="12">
                            <Card>
                                <CardBody className="pt-0">
                                    <CardTitle className="my-3">Filter</CardTitle>
                                    <Row>
                                        <Col className="col-12">
                                            <div>
                                                <Label>Company</Label>
                                                <Select
                                                    value={selectedCompany}
                                                    onChange={handleSelectCompany}
                                                    options={optionCompany}
                                                    isLoading={loadingCompany}
                                                    isClearable={true}
                                                    ref={filterCompany}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="justify-content-end">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light float-end" style={{ minWidth: '200px' }}
                                                disabled={loading}
                                                onClick={onRefreshCommand}>
                                                <i className="bx bx-filter-alt"></i>
                                            </Button>
                                            <Button color="info" className="btn btn-info waves-effect waves-light me-2 float-end" style={{ minWidth: '100px' }}
                                                disabled={loading}
                                                onClick={onResetFilterCommand}>
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Link to="/usermanagement/user-create-update">
                                            <Button color="success" className="btn btn-success waves-effect waves-light">
                                                New User
                                            </Button>
                                        </Link>
                                    </div>
                                    {
                                        !loading &&
                                        <>
                                            <TableContainer
                                                columns={columns}
                                                data={users}
                                                isGlobalFilter={false}
                                                isAddOptions={true}
                                                customPageSize={10}
                                            />
                                        </>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

User.propTypes = {
    className: PropTypes.string,
    preGlobalFilteredRows: PropTypes.any,
};

export default User
