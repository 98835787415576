import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_LIST_ASSET,
    GET_ASSET,
    ADD_ASSET,
    UPDATE_ASSET,
    DELETE_ASSET,
    EXPORT_EXCEL_ASSET,
    GET_TEMPLATE_EXCEL_ASSET,
    SUBMIT_IMPORT_EXCEL_ASSET
} from "./actionTypes"
import {
    getListAssetSuccess,
    getListAssetFail,
    getAssetByIdSuccess,
    getAssetByIdFail,
    addAssetSuccess,
    addAssetFail,
    updateAssetSuccess,
    updateAssetFail,
    deleteAssetSuccess,
    deleteAssetFail,
    exportExcelSuccess,
    exportExcelFail,
    getTemplateExcelSuccess,
    getTemplateExcelFail,
    submitImportExcelSuccess,
    submitImportExcelFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getListAsset,
    getAssetById,
    addAsset,
    updateAsset,
    deleteAsset,
    exportExcelAsset,
    getTemplateExcelAsset,
    submitImportExcelAsset
} from "../../helpers/backend_helper"
import fileDownload from 'js-file-download'

function* onFetchListAsset({ payload: filter }) {
    try {
        const response = yield call(getListAsset, filter)
        if (response.status) {
            yield put(getListAssetSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getListAssetFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getListAssetFail(error))
    }
}

function* onGetAssetById({ payload: { id, companyId } }) {
    try {
        const response = yield call(getAssetById, { id: id, companyId: companyId })
        if (response.status) {
            yield put(getAssetByIdSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getAssetByIdFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getAssetByIdFail(error))
    }
}

function* onAddAsset({ payload: { asset, history } }) {
    try {
        const response = yield call(addAsset, asset)
        if (response.status) {
            NotifHelper.success(msg.ASSET_ADD_SUCCESS)
            yield put(addAssetSuccess())
            history.push("/asset")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addAssetFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addAssetFail(error))
    }
}

function* onUpdateAsset({ payload: { asset, history } }) {
    try {
        const response = yield call(updateAsset, asset)
        if (response.status) {
            NotifHelper.success(msg.ASSET_UPDATE_SUCCESS)
            yield put(updateAssetSuccess())
            history.push("/asset")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updateAssetFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updateAssetFail(error))
    }
}

function* onDeleteAsset({ payload: { asset, filter } }) {
    try {
        const response = yield call(deleteAsset, asset)
        if (response.status) {
            NotifHelper.success(msg.ASSET_DELETE_SUCCESS)
            yield put(deleteAssetSuccess())
            const responseNewData = yield call(getListAsset, filter)
            if (responseNewData.status) {
                yield put(getListAssetSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getListAssetFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deleteAssetFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deleteAssetFail(error))
    }
}

function* onExportExcelAsset({ payload: filter }) {
    try {
        const response = yield call(exportExcelAsset, filter, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        fileDownload(response.data, filename)
        yield put(exportExcelSuccess())
    } catch (error) {
        NotifHelper.error(error)
        yield put(exportExcelFail(error))
    }
}

function* onGetTemplateExcelAsset({ payload: { filter } }) {
    try {
        const response = yield call(getTemplateExcelAsset, filter, { responseType: 'blob' })
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('=') + 1
        let endFileNameIndex = headerLine.lastIndexOf(';');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        fileDownload(response.data, filename)
        yield put(getTemplateExcelSuccess())
    } catch (error) {
        NotifHelper.error(error)
        yield put(getTemplateExcelFail(error))
    }
}

function* onSubmitImportExcelAsset({ payload: { uploadFile, companyId, history } }) {
    try {
        const response = yield call(submitImportExcelAsset, uploadFile, {
            companyId: companyId
        })
        if (response.status) {
            NotifHelper.success(msg.ASSET_IMPORT_SUCCESS)
            yield put(submitImportExcelSuccess())
            history.push("/asset")
        }
        else {
            NotifHelper.error(response.message)
            yield put(submitImportExcelFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(submitImportExcelFail(error))
    }
}

function* assetSaga() {
    yield takeEvery(GET_LIST_ASSET, onFetchListAsset)
    yield takeEvery(GET_ASSET, onGetAssetById)
    yield takeEvery(ADD_ASSET, onAddAsset)
    yield takeEvery(UPDATE_ASSET, onUpdateAsset)
    yield takeEvery(DELETE_ASSET, onDeleteAsset)
    yield takeEvery(EXPORT_EXCEL_ASSET, onExportExcelAsset)
    yield takeEvery(GET_TEMPLATE_EXCEL_ASSET, onGetTemplateExcelAsset)
    yield takeEvery(SUBMIT_IMPORT_EXCEL_ASSET, onSubmitImportExcelAsset)
}

export default assetSaga