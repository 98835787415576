//general
const CANNOT_CONNECT_SERVER = "Can't connect server!";
const TOKEN_EXPIRED = "Your login session has been expired. Please relogin!";

//company
const COMPANY_ADD_SUCCESS = "Company successfully added!"
const COMPANY_UPDATE_SUCCESS = "Company successfully updated!"
const COMPANY_DELETE_SUCCESS = "Company successfully deleted!"

const IMPORT_ACCOUNT_COMPANY_SUCCESS = "Account successfully imported!"
const UPLOAD_TEMPLATE_REPORT_SUCCESS = "Template successfully uploaded!"

//period
const PERIOD_ADD_SUCCESS = "Period successfully added!"
const PERIOD_UPDATE_SUCCESS = "Period successfully updated!"
const PERIOD_DELETE_SUCCESS = "Period successfully deleted!"
const PERIOD_CLOSED_SUCCESS = "Period successfully closed!"
const REOPEN_PERIOD_SUCCESS = "Period successfully re-open!"

const SUBMIT_BEGINNING_BALANCE_SUCCESS = "Data successfully submitted!"

//account
const ACCOUNT_ADD_SUCCESS = "Account successfully added!"
const ACCOUNT_UPDATE_SUCCESS = "Account successfully updated!"
const ACCOUNT_DELETE_SUCCESS = "Account successfully deleted!"

//account
const ACCOUNT_NUMBER_ADD_SUCCESS = "Account Number successfully added!"
const ACCOUNT_NUMBER_UPDATE_SUCCESS = "Account Number successfully updated!"
const ACCOUNT_NUMBER_DELETE_SUCCESS = "Account Number successfully deleted!"

//piutang
const PIUTANG_ADD_SUCCESS = "Piutang successfully added!"
const PIUTANG_UPDATE_SUCCESS = "Piutang successfully updated!"
const PIUTANG_DELETE_SUCCESS = "Piutang successfully deleted!"
const PIUTANG_ADD_PAYMENT_SUCCESS = "Piutang successfully added!"
const PIUTANG_DELETE_PAYMENT_SUCCESS = "Payment successfully deleted!"

//asset category
const ASSET_CATEGORY_ADD_SUCCESS = "Asset Category successfully added!"
const ASSET_CATEGORY_UPDATE_SUCCESS = "Asset Category successfully updated!"
const ASSET_CATEGORY_DELETE_SUCCESS = "Asset Category successfully deleted!"

//asset type
const ASSET_TYPE_ADD_SUCCESS = "Asset Type successfully added!"
const ASSET_TYPE_UPDATE_SUCCESS = "Asset Type successfully updated!"
const ASSET_TYPE_DELETE_SUCCESS = "Asset Type successfully deleted!"

//asset
const ASSET_ADD_SUCCESS = "Asset successfully added!"
const ASSET_UPDATE_SUCCESS = "Asset successfully updated!"
const ASSET_DELETE_SUCCESS = "Asset successfully deleted!"
const ASSET_IMPORT_SUCCESS = "Asset successfully imported!"

//account transaction
const ACCOUNT_TRANSACTION_ADD_SUCCESS = "Transaction successfully added!"
const ACCOUNT_TRANSACTION_DELETE_SUCCESS = "Transaction successfully deleted!"
const ACCOUNT_TRANSACTION_UPDATE_SUCCESS = "Transaction successfully updated!"

//role
const ROLE_ADD_SUCCESS = "Role successfully added!"
const ROLE_UPDATE_SUCCESS = "Role successfully updated!"
const ROLE_DELETE_SUCCESS = "Role successfully deleted!"

//user
const USER_ADD_SUCCESS = "User successfully added!"
const USER_UPDATE_SUCCESS = "User successfully updated!"
const USER_DELETE_SUCCESS = "User successfully deleted!"
const RESET_PASSWORD_USER_SUCCESS = "Reset Password Success!"
const CHANGE_PASSWORD_USER_SUCCESS = "Password successfully changed!"

const UPDATE_BALANCING_SUCCESS = "Update Balance successfully!"

export {
    CANNOT_CONNECT_SERVER,
    TOKEN_EXPIRED,

    COMPANY_ADD_SUCCESS,
    COMPANY_UPDATE_SUCCESS,
    COMPANY_DELETE_SUCCESS,
    
    IMPORT_ACCOUNT_COMPANY_SUCCESS,
    UPLOAD_TEMPLATE_REPORT_SUCCESS,

    PERIOD_ADD_SUCCESS,
    PERIOD_UPDATE_SUCCESS,
    PERIOD_DELETE_SUCCESS,
    PERIOD_CLOSED_SUCCESS,
    REOPEN_PERIOD_SUCCESS,

    SUBMIT_BEGINNING_BALANCE_SUCCESS,

    ACCOUNT_NUMBER_ADD_SUCCESS,
    ACCOUNT_NUMBER_UPDATE_SUCCESS,
    ACCOUNT_NUMBER_DELETE_SUCCESS,

    ACCOUNT_ADD_SUCCESS,
    ACCOUNT_UPDATE_SUCCESS,
    ACCOUNT_DELETE_SUCCESS,

    ASSET_CATEGORY_ADD_SUCCESS,
    ASSET_CATEGORY_UPDATE_SUCCESS,
    ASSET_CATEGORY_DELETE_SUCCESS,

    ASSET_TYPE_ADD_SUCCESS,
    ASSET_TYPE_UPDATE_SUCCESS,
    ASSET_TYPE_DELETE_SUCCESS,

    ASSET_ADD_SUCCESS,
    ASSET_UPDATE_SUCCESS,
    ASSET_DELETE_SUCCESS,
    ASSET_IMPORT_SUCCESS,

    PIUTANG_ADD_SUCCESS,
    PIUTANG_UPDATE_SUCCESS,
    PIUTANG_DELETE_SUCCESS,
    PIUTANG_ADD_PAYMENT_SUCCESS,
    PIUTANG_DELETE_PAYMENT_SUCCESS,

    ACCOUNT_TRANSACTION_ADD_SUCCESS,
    ACCOUNT_TRANSACTION_DELETE_SUCCESS,
    ACCOUNT_TRANSACTION_UPDATE_SUCCESS,

    ROLE_ADD_SUCCESS,
    ROLE_UPDATE_SUCCESS,
    ROLE_DELETE_SUCCESS,
    
    USER_ADD_SUCCESS,
    USER_UPDATE_SUCCESS,
    USER_DELETE_SUCCESS,
    RESET_PASSWORD_USER_SUCCESS,
    CHANGE_PASSWORD_USER_SUCCESS,

    UPDATE_BALANCING_SUCCESS
}