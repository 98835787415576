import {
    GET_PERIODS,
    GET_PERIODS_SUCCESS,
    GET_PERIODS_FAIL,
    GET_PERIOD,
    GET_PERIOD_SUCCESS,
    GET_PERIOD_FAIL,
    ADD_PERIOD,
    ADD_PERIOD_FAIL,
    UPDATE_PERIOD,
    UPDATE_PERIOD_FAIL,
    UPDATE_PERIOD_SUCCESS,
    DELETE_PERIOD,
    DELETE_PERIOD_FAIL,
    ADD_PERIOD_SUCCESS,
    CLEAR_PERIODS,
    CLOSE_PERIOD,
    CLOSE_PERIOD_SUCCESS,
    CLOSE_PERIOD_FAIL,
    REOPEN_PERIOD,
    REOPEN_PERIOD_SUCCESS,
    REOPEN_PERIOD_FAIL,
    GET_TEMPLATE_BEGINNING_BALANCE,
    GET_TEMPLATE_BEGINNING_BALANCE_SUCCESS,
    GET_TEMPLATE_BEGINNING_BALANCE_FAIL,
    SUBMIT_BEGINNING_BALANCE,
    SUBMIT_BEGINNING_BALANCE_SUCCESS,
    SUBMIT_BEGINNING_BALANCE_FAIL
  } from "./actionTypes";
  
  export const getPeriods = filter => ({
    type: GET_PERIODS,
    payload: filter
  });
  export const getPeriodsSuccess = periods => ({
    type: GET_PERIODS_SUCCESS,
    payload: periods,
  });
  export const getPeriodsFail = error => ({
    type: GET_PERIODS_FAIL,
    payload: error,
  });

  export const getPeriodById = (id, companyId) => ({
    type: GET_PERIOD,
    payload: { id: id, companyId: companyId} 
  });
  export const getPeriodSuccess = period => ({
    type: GET_PERIOD_SUCCESS,
    payload: period,
  });
  export const getPeriodFail = error => ({
    type: GET_PERIOD_FAIL,
    payload: error,
  });
  
  export const addNewPeriod = (period, history) => ({
    type: ADD_PERIOD,
    payload: { period, history },
  });
  export const addPeriodSuccess = () => ({
    type: ADD_PERIOD_SUCCESS,
  });
  export const addPeriodFail = error => ({
    type: ADD_PERIOD_FAIL,
    payload: error,
  });
  
  export const updatePeriod = (period, history) => ({
    type: UPDATE_PERIOD,
    payload: { period, history },
  });
  export const updatePeriodSuccess = () => ({
    type: UPDATE_PERIOD_SUCCESS,
  });
  export const updatePeriodFail = error => ({
    type: UPDATE_PERIOD_FAIL,
    payload: error,
  });
  
  export const deletePeriod = (period, filter) => ({
    type: DELETE_PERIOD,
    payload: { period, filter },
  });
  export const deletePeriodFail = error => ({
    type: DELETE_PERIOD_FAIL,
    payload: error,
  });

  export const clearPeriods = () => ({
    type: CLEAR_PERIODS,
  });

  export const closePeriod = (period, filter) => ({
    type: CLOSE_PERIOD,
    payload: { period, filter },
  });
  export const closePeriodSuccess = () => ({
    type: CLOSE_PERIOD_SUCCESS,
  });
  export const closePeriodFail = error => ({
    type: CLOSE_PERIOD_FAIL,
    payload: error,
  });

  export const reOpenPeriod = (period, filter) => ({
    type: REOPEN_PERIOD,
    payload: { period, filter },
  });
  export const reOpenPeriodSuccess = () => ({
    type: REOPEN_PERIOD_SUCCESS,
  });
  export const reOpenPeriodFail = error => ({
    type: REOPEN_PERIOD_FAIL,
    payload: error,
  });

  export const getTemplateBeginningBalance = filter => ({
    type: GET_TEMPLATE_BEGINNING_BALANCE,
    payload: { filter },
  });
  export const getTemplateBeginningBalanceSuccess = () => ({
    type: GET_TEMPLATE_BEGINNING_BALANCE_SUCCESS,
  });
  export const getTemplateBeginningBalanceFail = error => ({
    type: GET_TEMPLATE_BEGINNING_BALANCE_FAIL,
    payload: error,
  });

  export const submitBeginningBalance = (uploadFile, companyId, periodId, history) => ({
    type: SUBMIT_BEGINNING_BALANCE,
    payload: { uploadFile, companyId, periodId, history },
  });
  export const submitBeginningBalanceSuccess = () => ({
    type: SUBMIT_BEGINNING_BALANCE_SUCCESS,
  });
  export const submitBeginningBalanceFail = error => ({
    type: SUBMIT_BEGINNING_BALANCE_FAIL,
    payload: error,
  });