import axios from "axios";
import * as msg from "../constants/responseMessage"
import { NotifHelper } from "../components/Common/NotifHelper"

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosApi.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem("AccessToken")
  if (accessToken) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

axiosApi.interceptors.response.use(function (response) {
  return response;
},
  error => Promise.reject(error)
);

export async function get(url, params, config = {}) {
  return await axiosApi
    .get(url, { ...config, params: { ...params } })
    .then(response => response.data)
    .catch(err => {
      if (err.response.status == 401) {
        localStorage.removeItem("AccessToken")
      }
      return {
        status: false,
        message: err.response.status == 401 ? msg.TOKEN_EXPIRED : err.response.data.message
      }
    });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(err => {
      if (err.response.status == 401) {
        localStorage.removeItem("AccessToken")
      }
      return {
        status: false,
        message: err.response.status == 401 ? msg.TOKEN_EXPIRED : err.response.data.message
      }
    });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(err => {
      if (err.response.status == 401) {
        localStorage.removeItem("AccessToken")
      }
      return {
        status: false,
        message: err.response.status == 401 ? msg.TOKEN_EXPIRED : err.response.data.message
      }
    });
}

export async function del(url, params, config = {}) {
  return await axiosApi
    .delete(url, { ...config, params: params })
    .then(response => response.data)
    .catch(err => {
      if (err.response.status == 401) {
        localStorage.removeItem("AccessToken")
      }
      return {
        status: false,
        message: err.response.status == 401 ? msg.TOKEN_EXPIRED : err.response.data.message
      }
    });
}

export async function getFile(url, params, config = {}) {
  return await axiosApi
    .get(url, { ...config, params: { ...params } })
    .then(response => response)
    .catch(err => {
      if (err.response.status == 401) {
        localStorage.removeItem("AccessToken")
      }
      return {
        status: false,
        message: err.response.status == 401 ? msg.TOKEN_EXPIRED : err.response.data.message
      }
    });
}

export async function getFilePost(url, params, config = {}) {
  return await axiosApi
    .post(url, params, config)
    .then(response => response)
    .catch(err => {
      if (err.response.status == 401) {
        localStorage.removeItem("AccessToken")
      }
      console.log(err)
      return {
        status: false,
        message: err.response.status == 401 ? msg.TOKEN_EXPIRED : err.response.data.message
      }
    });
}

export async function postFile(url, data, params = {}) {
  return axiosApi
    .post(url, data, {
      params: params
    })
    .then(response => response.data)
    .catch(err => {
      if (err.response.status == 401) {
        localStorage.removeItem("AccessToken")
      }
      return {
        status: false,
        message: err.response.status == 401 ? msg.TOKEN_EXPIRED : err.response.data.message
      }
    });
}