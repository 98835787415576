import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import {
    Container,
    Button,
    Col,
    Row,
    Input,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
    getCompanies as onGetCompanies,
    getTemplateExcelAsset as onGetTemplateExcelAsset,
    submitImportExcel as onSubmitImportExcel
} from "../../store/actions";


import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const AssetImportExcel = (props) => {

    //meta title
    document.title = "Import Excel | Asset | Des Counts";
    const [fileUpload, setFileUpload] = useState(null)

    const { companies, loadingCompany, loading } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
        loading: state.Asset.loading,
    }));

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e)
    }

    const dispatch = useDispatch();

    function downloadTemplate() {
        if (selectedCompany) {
            dispatch(onGetTemplateExcelAsset({
                companyId: selectedCompany.value
            }))
        }
    }

    const uploadSubmit = () => {
        if (selectedCompany && fileUpload) {
            const data = new FormData()
            data.append('files', fileUpload)
            dispatch(onSubmitImportExcel(data, selectedCompany.value, props.history))
        }
    }

    useEffect(() => {
        dispatch(onGetCompanies({}))
    }, [dispatch])

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id.toString() }
            }))
        }
    }, [companies])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Asset" breadcrumbItem={"Import Excel Asset"} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody style={loading ? { pointerEvents: "none" } : {}}>
                                    {
                                        !loading &&
                                        <>
                                            <div className="d-flex">
                                                <div className="flex-grow-1  mx-1">
                                                    <Label>Company</Label>
                                                    <Select
                                                        value={selectedCompany}
                                                        onChange={handleSelectCompany}
                                                        options={optionCompany}
                                                        isLoading={loadingCompany}
                                                        className="select2-selection"
                                                        menuPosition="fixed"
                                                    />
                                                </div>
                                                <div className="align-self-end mx-1">
                                                    <Button color="primary" className="align-self-botttom mx-1" disabled={!selectedCompany} onClick={downloadTemplate}>
                                                        Download Template
                                                    </Button>
                                                </div>
                                            </div>
                                            <Row className="mt-3">
                                                <Col sm={12}>
                                                    <Label htmlFor="formFile" className="form-label">File Excel</Label>
                                                    <Input className="form-control" type="file" onChange={(e) => {
                                                        if (e.target.files.length > 0) {
                                                            setFileUpload(e.target.files[0])
                                                        }
                                                        else {
                                                            setFileUpload(null)
                                                        }
                                                    }} />
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col sm={12}>
                                                    <Button color="secondary" className="float-end"
                                                        disabled={loading} onClick={() => {
                                                            props.history.push("/asset")
                                                        }}>
                                                        Cancel
                                                    </Button>
                                                    <Button color="success" className="me-2 float-end"
                                                        disabled={!fileUpload || !selectedCompany} onClick={uploadSubmit}>
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

AssetImportExcel.propTypes = {
    history: PropTypes.object,
};

export default withRouter(AssetImportExcel)
