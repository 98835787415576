import React, { useState, useMemo, useEffect, useRef } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Container,
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Label,
    Card,
    CardBody,
    CardTitle,
    Badge,
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TableContainer from '../../components/Common/TableContainer';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanies as onGetCompanies,
    getPeriods as onGetPeriods,
    deletePeriod as onDeletePeriod,
    closePeriod as onClosePeriod,
    reOpenPeriod as onReOpenPeriod
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import DeleteModal from "../../components/Common/ModalDelete";
import ModalClosePeriod from "../../components/Common/ModalClosePeriod";
const animatedComponents = makeAnimated();
import ModalReOpenPeriod from "../../components/Common/ModalReOpenPeriod"

const Period = () => {

    //meta title
    document.title = "Period | Des Counts";

    const dispatch = useDispatch();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isOpenClosePeriod, setIsOpenClosePeriod] = useState(false);
    const [isOpenReOpenPeriod, setIsOpenReOpenPeriod] = useState(false);
    const [period, setPeriod] = useState({});

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [optionCompany, setOptionCompany] = useState([]);
    function handleSelectCompany(e) {
        setSelectedCompany(e);
    }

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [optionStatus, setOptionStatus] = useState([
        { label: "Open", value: true },
        { label: "Closed", value: false },
    ]);
    function handleSelectStatus(e) {
        setSelectedStatus(e);
    }

    const filterCode = useRef("");
    const filterName = useRef("");
    const filterCompany = useRef("");
    const filterStatus = useRef("");

    const { companies, loadingCompany } = useSelector(state => ({
        companies: state.Company.companies,
        loadingCompany: state.Company.loading,
    }));

    const { periods, loading } = useSelector(state => ({
        periods: state.Period.periods,
        loading: state.Period.loading,
    }));

    const columns = useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'code',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return cellProps.value;
                }
            },
            {
                Header: 'Status',
                accessor: 'isClosed',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Badge color={cellProps.value ? "danger" : "success"}>{cellProps.value ? 'CLOSED' : 'OPEN'}</Badge>;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                headerCellStyle: 'text-center',
                style: {
                    textAlign: "center",
                },
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            {
                                cellProps.row.original.isClosed &&
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-success"
                                    onClick={() => {
                                        const data = cellProps.row.original;
                                        onOpenReOpenPeriodCommand(data);
                                    }}
                                >
                                    Re-Open Period
                                </Link>
                            }
                            {
                                !cellProps.row.original.isClosed &&
                                <>
                                    <Link
                                        to={"/masterdata/period-create-update/" + cellProps.row.original.companyId + "/" + cellProps.row.original.id}
                                        className="text-success"
                                    >
                                        <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                            Edit
                                        </UncontrolledTooltip>
                                    </Link>
                                    <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() => {
                                            const data = cellProps.row.original;
                                            onOpenDeleteCommand(data);
                                        }}
                                    >
                                        <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                            Delete
                                        </UncontrolledTooltip>
                                    </Link>
                                    <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() => {
                                            const data = cellProps.row.original;
                                            onOpenClosePeriodCommand(data);
                                        }}
                                    >
                                        <i className="mdi mdi-cancel font-size-18" id="closetooltip" />
                                        <UncontrolledTooltip placement="top" target="closetooltip">
                                            Close Period
                                        </UncontrolledTooltip>
                                    </Link>
                                </>
                            }
                        </div>
                    );
                }
            },
        ],
        []
    );

    const handleOrderClicks = () => {

    }

    const onRefreshCommand = () => {
        if (selectedCompany) {
            dispatch(onGetPeriods({
                companyId: selectedCompany ? selectedCompany.value : null,
                code: filterCode.current.value,
                name: filterName.current.value,
                isOpen: selectedStatus ? selectedStatus.value : null
            }));
        }
    }

    const onResetFilterCommand = () => {
        filterCode.current.value = '';
        filterName.current.value = '';
        filterCompany.current.clearValue();
        filterStatus.current.clearValue();
    }

    const onOpenDeleteCommand = (data) => {
        setPeriod(data)
        setIsOpenDelete(true);
    }

    const onDeleteCommand = () => {
        setIsOpenDelete(false)
        dispatch(onDeletePeriod(period, {
            companyId: selectedCompany ? selectedCompany.value : null,
            code: filterCode.current.value,
            name: filterName.current.value,
            isOpen: selectedStatus ? selectedStatus.value : null
        }))
    }

    const onOpenClosePeriodCommand = (data) => {
        setPeriod(data)
        setIsOpenClosePeriod(true);
    }

    const onClosePeriodCommand = () => {
        setIsOpenClosePeriod(false);
        if (period) {
            dispatch(onClosePeriod(period, {
                companyId: selectedCompany ? selectedCompany.value : null,
                code: filterCode.current.value,
                name: filterName.current.value,
                isOpen: selectedStatus ? selectedStatus.value : null
            }))
        }
    }

    const onOpenReOpenPeriodCommand = (data) => {
        setPeriod(data)
        setIsOpenReOpenPeriod(true);
    }

    const onReOpenPeriodCommand = () => {
        setIsOpenReOpenPeriod(false);
        if (period) {
            dispatch(onReOpenPeriod(period, {
                companyId: selectedCompany ? selectedCompany.value : null,
                code: filterCode.current.value,
                name: filterName.current.value,
                isOpen: selectedStatus ? selectedStatus.value : null
            }))
        }
    }

    useEffect(() => {
        onRefreshCommand();
    }, [dispatch]);

    useEffect(() => {
        if (companies) {
            setOptionCompany(companies.map((item) => {
                return { label: item.name, value: item.id }
            }))
        }
    }, [companies])

    useEffect(() => {
        onRefreshCommand()
    }, [selectedCompany])

    useEffect(() => {
        dispatch(onGetCompanies({}));
    }, [])

    return (
        <React.Fragment>
            <ModalReOpenPeriod show={isOpenReOpenPeriod} periodName={period?.name} onSubmit={onReOpenPeriodCommand} onCancel={() => setIsOpenReOpenPeriod(false)} />
            <DeleteModal show={isOpenDelete} onDeleteClick={onDeleteCommand} onCloseClick={() => setIsOpenDelete(false)} />
            <ModalClosePeriod show={isOpenClosePeriod} title={'Close Period ' + period.name} onSubmitCommand={onClosePeriodCommand} onCloseClick={() => setIsOpenClosePeriod(false)} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Master Data" breadcrumbItem="Period" />
                    <Row className="mb-3">
                        <Col xs="12">
                            <Card>
                                <CardBody className="pt-0">
                                    <CardTitle className="my-3">Filter</CardTitle>
                                    <Row>
                                        <Col className="col-12 col-sm-6">
                                            <div>
                                                <Label>Company</Label>
                                                <Select
                                                    value={selectedCompany}
                                                    onChange={handleSelectCompany}
                                                    options={optionCompany}
                                                    isLoading={loadingCompany}
                                                    isClearable={true}
                                                    ref={filterCompany}
                                                    className="select2-selection"
                                                />
                                            </div>
                                            <div>
                                                <label className="col-md-2 col-form-label">
                                                    Code
                                                </label>
                                                <div className="col-md-12">
                                                    <input className="form-control" type="text" ref={filterCode} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-6">
                                            <div>
                                                <Label>Status</Label>
                                                <Select
                                                    value={selectedStatus}
                                                    onChange={handleSelectStatus}
                                                    options={optionStatus}
                                                    isClearable={true}
                                                    ref={filterStatus}
                                                    className="select2-selection"
                                                />
                                            </div>
                                            <div>
                                                <label className="col-md-2 col-form-label">
                                                    Name
                                                </label>
                                                <div className="col-md-12">
                                                    <input className="form-control" type="text" ref={filterName} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="justify-content-end">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light  float-end" style={{ minWidth: '200px' }}
                                                disabled={loading}
                                                onClick={onRefreshCommand}>
                                                <i className="bx bx-filter-alt"></i>
                                            </Button>
                                            <Button color="info" className="btn btn-info waves-effect waves-light me-2 float-end" style={{ minWidth: '100px' }}
                                                disabled={loading}
                                                onClick={onResetFilterCommand}>
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Link to="/masterdata/period-create-update">
                                            <Button color="success" className="btn btn-success waves-effect waves-light">
                                                Add Period
                                            </Button>
                                        </Link>
                                    </div>
                                    {
                                        !loading && selectedCompany &&
                                        <>
                                            <TableContainer
                                                columns={columns}
                                                data={periods}
                                                isGlobalFilter={false}
                                                isAddOptions={true}
                                                handleOrderClicks={handleOrderClicks}
                                                customPageSize={10}
                                            />
                                        </>
                                    }
                                    {
                                        !loading && !selectedCompany &&
                                        <div className="d-flex justify-content-center my-5">
                                            <p>Please select company.</p>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className="d-flex justify-content-center my-5">
                                            <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

Period.propTypes = {
    periods: PropTypes.array,
    className: PropTypes.string,
    onGetCompanies: PropTypes.func,
    onGetPeriods: PropTypes.func,
    onDeletePeriod: PropTypes.func,
    preGlobalFilteredRows: PropTypes.any,
};

export default Period
