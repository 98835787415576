import { takeEvery, put, call } from "redux-saga/effects"
import { NotifHelper } from "../../components/Common/NotifHelper"
import * as msg from "../../constants/responseMessage"

// Calender Redux States
import {
    GET_DAFTAR_PIUTANG,
    GET_PIUTANG,
    ADD_PIUTANG,
    UPDATE_PIUTANG,
    DELETE_PIUTANG,
    ADD_PIUTANG_PAYMENT,
    DELETE_PIUTANG_PAYMENT
} from "./actionTypes"
import {
    getDaftarPiutangSuccess,
    getDaftarPiutangFail,
    getPiutangSuccess,
    getPiutangFail,
    addPiutangFail,
    updatePiutangFail,
    deletePiutangFail,
    addPiutangPaymentFail,
    addPiutangPaymentSuccess,
    deletePiutangPaymentFail,
    deletePiutangPaymentSuccess,
    addPiutangSuccess
} from "./actions"

//Include Both Helper File with needed methods
import {
    getDaftarPiutang,
    getPiutang,
    addPiutang as addNewPiutang,
    updatePiutang,
    deletePiutang,
    addPiutangPayment as addNewPiutangPayment,
    deletePiutangPayment
} from "../../helpers/backend_helper"

function* fetchDaftarPiutang({ payload: filter }) {
    try {
        const response = yield call(getDaftarPiutang, filter)
        if (response.status) {
            yield put(getDaftarPiutangSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getDaftarPiutangFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getDaftarPiutangFail(error))
    }
}

function* getPiutangById({ payload: { id, companyId } }) {
    try {
        const response = yield call(getPiutang, { id, companyId })
        if (response.status) {
            yield put(getPiutangSuccess(response.data))
        }
        else {
            NotifHelper.error(response.message)
            yield put(getPiutangFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(getPiutangFail(error))
    }
}

function* addPiutang({ payload: { piutang, history } }) {
    try {
        const response = yield call(addNewPiutang, piutang)
        if (response.status) {
            NotifHelper.success(msg.PIUTANG_ADD_SUCCESS)
            yield put(addPiutangSuccess())
            history.push("/piutang")
        }
        else {
            NotifHelper.error(response.message)
            yield put(addPiutangFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addPiutangFail(error))
    }
}

function* onUpdatePiutang({ payload: { piutang, history } }) {
    try {
        const response = yield call(updatePiutang, piutang)
        if (response.status) {
            NotifHelper.success(msg.PIUTANG_UPDATE_SUCCESS)
            history.push("/piutang")
        }
        else {
            NotifHelper.error(response.message)
            yield put(updatePiutangFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(updatePiutangFail(error))
    }
}

function* onDeletePiutang({ payload: { piutang, filter } }) {
    try {
        const response = yield call(deletePiutang, piutang)
        if (response.status) {
            NotifHelper.success(msg.PIUTANG_DELETE_SUCCESS)
            const responseNewData = yield call(getDaftarPiutang, filter)
            if (responseNewData.status) {
                yield put(getDaftarPiutangSuccess(responseNewData.data))
            }
            else {
                NotifHelper.error(responseNewData.message)
                yield put(getDaftarPiutangFail(responseNewData.message))
            }
        }
        else {
            NotifHelper.error(response.message)
            yield put(deletePiutangFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deletePiutangFail(error))
    }
}

function* addPiutangPayment({ payload: { piutangPayment, history } }) {
    try {
        const response = yield call(addNewPiutangPayment, piutangPayment)
        if (response.status) {
            NotifHelper.success(msg.PIUTANG_ADD_PAYMENT_SUCCESS)
            yield put(addPiutangPaymentSuccess())
        }
        else {
            NotifHelper.error(response.message)
            yield put(addPiutangPaymentFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(addPiutangPaymentFail(error))
    }
}

function* onDeletePiutangPayment({ payload: { param } }) {
    try {
        const response = yield call(deletePiutangPayment, param)
        if (response.status) {
            NotifHelper.success(msg.PIUTANG_DELETE_PAYMENT_SUCCESS)
            yield put(deletePiutangPaymentSuccess())
        }
        else {
            NotifHelper.error(response.message)
            yield put(deletePiutangPaymentFail(response.message))
        }
    } catch (error) {
        NotifHelper.error(error)
        yield put(deletePiutangPaymentFail(error))
    }
}

function* piutangSaga() {
    yield takeEvery(GET_DAFTAR_PIUTANG, fetchDaftarPiutang)
    yield takeEvery(GET_PIUTANG, getPiutangById)
    yield takeEvery(ADD_PIUTANG, addPiutang)
    yield takeEvery(UPDATE_PIUTANG, onUpdatePiutang)
    yield takeEvery(DELETE_PIUTANG, onDeletePiutang)
    yield takeEvery(ADD_PIUTANG_PAYMENT, addPiutangPayment)
    yield takeEvery(DELETE_PIUTANG_PAYMENT, onDeletePiutangPayment)
}

export default piutangSaga