import {
  GET_LIST_ROLE,
  GET_LIST_ROLE_SUCCESS,
  GET_LIST_ROLE_FAIL,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
} from "./actionTypes";

export const getListRole = filter => ({
  type: GET_LIST_ROLE,
  payload: filter
});
export const getListRoleSuccess = listRole => ({
  type: GET_LIST_ROLE_SUCCESS,
  payload: listRole,
});
export const getListRoleFail = error => ({
  type: GET_LIST_ROLE_FAIL,
  payload: error,
});

export const getRoleById = (id) => ({
  type: GET_ROLE,
  payload: { id }
});
export const getRoleSuccess = role => ({
  type: GET_ROLE_SUCCESS,
  payload: role,
});
export const getRoleFail = error => ({
  type: GET_ROLE_FAIL,
  payload: error,
});

export const addRole = (role, history) => ({
  type: ADD_ROLE,
  payload: { role, history },
});
export const addRoleSuccess = () => ({
  type: ADD_ROLE_SUCCESS,
});
export const addRoleFail = error => ({
  type: ADD_ROLE_FAIL,
  payload: error,
});

export const updateRole = (role, history) => ({
  type: UPDATE_ROLE,
  payload: { role, history },
});
export const updateRoleSuccess = () => ({
  type: UPDATE_ROLE_SUCCESS,
});
export const updateRoleFail = error => ({
  type: UPDATE_ROLE_FAIL,
  payload: error,
});

export const deleteRole = (role, filter) => ({
  type: DELETE_ROLE,
  payload: { role, filter },
});
export const deleteRoleSuccess = () => ({
  type: DELETE_ROLE_SUCCESS,
});
export const deleteRoleFail = error => ({
  type: DELETE_ROLE_FAIL,
  payload: error,
});