/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";

export const GET_ACCOUNT_NUMBER_DASHBOARD = "GET_ACCOUNT_NUMBER_DASHBOARD";
export const GET_ACCOUNT_NUMBER_DASHBOARD_SUCCESS = "GET_ACCOUNT_NUMBER_DASHBOARD_SUCCESS";
export const GET_ACCOUNT_NUMBER_DASHBOARD_FAILED = "GET_ACCOUNT_NUMBER_DASHBOARD_FAILED";


export const GET_DATA_DASHBOARD = "GET_DATA_DASHBOARD";
export const GET_DATA_DASHBOARD_SUCCESS = "GET_DATA_DASHBOARD_SUCCESS";
export const GET_DATA_DASHBOARD_FAILED = "GET_DATA_DASHBOARD_FAILED";