import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import { useSelector } from "react-redux";

const Navbar = props => {

  const [ui, setui] = useState(false);
  const { access } = useSelector(state => ({
    access: state.Login.access
  }));

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/dashboard"
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Dashboard")}
                  </Link>
                </li>

                {
                  access && access.length > 0 &&
                  (access.includes(1) ||
                    access.includes(4) ||
                    access.includes(2) ||
                    access.includes(3) ||
                    access.includes(9) ||
                    access.includes(10)) &&
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        setui(!ui);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-data me-2"></i>
                      {props.t("Master Data")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname(
                        "dropdown-menu",
                        { show: ui }
                      )}
                    >
                      {
                        access.includes(1) &&
                        <Link to="/masterdata/company" className="dropdown-item">{props.t("Company")}</Link>
                      }
                      {
                        access.includes(4) &&
                        <Link to="/masterdata/period" className="dropdown-item">{props.t("Period")}</Link>
                      }
                      {
                        access.includes(2) &&
                        <Link to="/masterdata/account" className="dropdown-item">{props.t("Account")}</Link>
                      }
                      {
                        access.includes(3) &&
                        <Link to="/masterdata/account-number" className="dropdown-item">{props.t("Account Number")}</Link>
                      }
                      {
                        access.includes(9) &&
                        <Link to="/masterdata/asset-category" className="dropdown-item">{props.t("Asset Category")}</Link>
                      }
                      {
                        access.includes(10) &&
                        <Link to="/masterdata/asset-type" className="dropdown-item">{props.t("Asset Type")}</Link>
                      }
                    </div>
                  </li>
                }

                {
                  access && access.length > 0 &&
                  access.includes(7) &&
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/account-transaction"
                    >
                      <i className="bx bx-dollar-circle me-2"></i>
                      {props.t("Transaction")}
                    </Link>
                  </li>
                }

                {
                  access && access.length > 0 &&
                  access.includes(8) &&
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/worksheet"
                    >
                      <i className="bx bx-laptop me-2"></i>
                      {props.t("Worksheet")}
                    </Link>
                  </li>
                }

                {
                  access && access.length > 0 &&
                  access.includes(12) &&
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/piutang"
                    >
                      <i className="bx bx-home-circle me-2"></i>
                      {props.t("Piutang")}
                    </Link>
                  </li>
                }

                {
                  access && access.length > 0 &&
                  access.includes(11) &&
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/asset"
                    >
                      <i className="bx bx-home-circle me-2"></i>
                      {props.t("Asset")}
                    </Link>
                  </li>
                }

                {
                  access && access.length > 0 &&
                  (access.includes(13) ||
                    access.includes(14)) &&
                  <li className="nav-item dropdown">
                    <Link to="/#" className="nav-link dropdown-toggle arrow-none">
                      <i className="bx bxs-report me-2"></i>
                      {props.t("Report")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname(
                        "dropdown-menu",
                        { show: ui }
                      )}>
                      {
                        access.includes(13) &&
                        <Link to="/report-neraca" className="dropdown-item">{props.t("Neraca")}</Link>
                      }
                      {
                        access.includes(14) &&
                        <Link to="/report-profitloss" className="dropdown-item">{props.t("Profit & Loss")}</Link>
                      }
                    </div>
                  </li>
                }

                {
                  access && access.length > 0 &&
                  (access.includes(5) ||
                    access.includes(6)) &&
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-user me-2"></i>
                      {props.t("User Management")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname(
                        "dropdown-menu",
                        { show: ui }
                      )}
                    >
                      {
                        access.includes(5) &&
                        <Link to="/usermanagement/role" className="dropdown-item">{props.t("Role")}</Link>
                      }
                      {
                        access.includes(6) &&
                        <Link to="/usermanagement/user" className="dropdown-item">{props.t("User")}</Link>
                      }
                    </div>
                  </li>
                }

                {
                  access && access.length > 0 &&
                  access.includes(15) &&
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/import-beginning-balance"
                    >
                      <i className="bx bx-upload me-2"></i>
                      {props.t("Beginning Balance")}
                    </Link>
                  </li>
                }
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
