import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from 'react-router-dom';
import {
  Container,
  Button,
  Col,
  Row,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  getCompanyById as onGetCompany,
  updateCompany as onUpdateCompany,
  addNewCompany as onAddCompany,
} from "../../store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const CompanyCreateUpdate = (props) => {

  //meta title
  document.title = "Company | Des Counts";
  const id = props?.match?.params?.id;
  const isEdit = id != undefined;

  const { company, loading, error } = useSelector(state => ({
    company: state.Company.company,
    loading: state.Company.loading,
    error: state.Company.error,
  }));

  const dispatch = useDispatch();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: isEdit && company ? company.name : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Company Name"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        dispatch(onUpdateCompany({ id: company.id, name: values.name }, props.history));
      } else {
        dispatch(onAddCompany({ name: values.name }, props.history));
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      dispatch(onGetCompany({ id: id }))
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Master Data" breadcrumbItem={(isEdit ? "Edit" : "Add") + " Company"} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    !loading &&
                    <>
                      <Row>
                        <Col>
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                className="form-control"
                                placeholder="Enter company name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name && validation.errors.name ? true : false
                                }
                              />
                              {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-3 mb-2">
                              <Link to="/masterdata/company">
                                <Button
                                  color="secondary"
                                  className="btn btn-secondary float-end"
                                >
                                  Cancel
                                </Button>
                              </Link>
                              <Button
                                color="primary"
                                className="btn btn-primary float-end me-2"
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </>
                  }
                  {
                    loading &&
                    <div className="d-flex justify-content-center my-5">
                      <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CompanyCreateUpdate.propTypes = {
  history: PropTypes.object,
};

export default withRouter(CompanyCreateUpdate)
