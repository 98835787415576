import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Label,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardHeader
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LineApexChart from "./chart"
import Select from "react-select";
import PulseLoader from "react-spinners/PulseLoader";
import CurrencyFormat from 'react-currency-format';

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 3000,
  extendedTimeOut: 2000,
  closeButton: true,
  debug: false,
  progressBar: true,
  preventDuplicates: false,
  newestOnTop: true,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000
};
import {
  getAccountNumberDashboard as onGetAccountNumberDashboard,
  getCompanies as onGetCompanies,
  getDataDashboard as OnGetDataDashboard
} from '../../store/actions'

const Dashboard = props => {
  const dispatch = useDispatch();
  //meta title
  document.title = "Dashboard | Des Counts";

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [optionCompany, setOptionCompany] = useState([]);
  function handleSelectCompany(e) {
    setSelectedCompany(e);
  }

  const { companies, loadingCompany, data, loading } = useSelector(state => ({
    companies: state.Company.companies,
    loadingCompany: state.Company.loading,
    data: state.Dashboard.data,
    loading: state.Dashboard.loading,
  }));

  useEffect(() => {
    if (companies) {
      setOptionCompany(companies.map((item) => {
        return { label: item.name, value: item.id }
      }))
    }
  }, [companies])

  const onRefreshCommand = () => {
    if (selectedCompany) {
      dispatch(OnGetDataDashboard({ companyId: selectedCompany.value }));
    }
  }

  const onLihatPiutangCommand = () => { 
    props.history.push('/piutang')
  }

  useEffect(() => {
    if (dispatch) {
      dispatch(onGetCompanies({}));
    }
  }, [dispatch])

  useEffect(() => {
    onRefreshCommand()
  }, [selectedCompany])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Card>
            <CardBody>
              <CardTitle>Company</CardTitle>
              <Row>
                <Col className="col-12 col-sm-10">
                  <div>
                    <Select
                      value={selectedCompany}
                      onChange={handleSelectCompany}
                      options={optionCompany}
                      isLoading={loadingCompany}
                      isClearable={false}
                      className="select2-selection"
                      menuPosition="fixed"
                    />
                  </div>
                </Col>
                <Col className="col-12 col-sm-2">
                  <Button className="btn btn-primary align-self-bottom" color="primary" block={true} onClick={onRefreshCommand}>
                    <i className="bx bx-filter-alt"></i>
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {
            selectedCompany && !loading &&
            <>
              <Row>
                <Col sm={4}>
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Transaction</p>
                          <h4 className="mb-0">
                            <CurrencyFormat value={data?.totalTransaction ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                              displayType={'text'} />
                          </h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="bx bx-transfer font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={4}>
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Debit</p>
                          <h4 className="mb-0">
                            <CurrencyFormat value={data?.totalDebit ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                              displayType={'text'} />
                          </h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="bx bx-dollar font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={4}>
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Credit</p>
                          <h4 className="mb-0">
                            <CurrencyFormat value={data?.totalCredit ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                              displayType={'text'} />
                          </h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="bx bx-dollar font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  {
                    data && data.dataAccountNumbers && data.dataAccountNumbers.length > 0 && data.dataAccountNumbers.map(item => {
                      return <Card key={'accNum' + item.id}>
                        <CardBody>
                          <div className="d-flex mb-3">
                            <div className="flex-grow-1">
                              <CardTitle className="mb-0">{item.name}</CardTitle>
                              <small>{item.number}</small>
                            </div>
                            <div>
                              <small>Balance</small>
                              <CardTitle className="text-end">
                                <CurrencyFormat value={item.balance ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                                  displayType={'text'} />
                              </CardTitle>
                            </div>
                          </div>
                          <LineApexChart dataColors='["--bs-primary", "--bs-success"]' data={item} />
                        </CardBody>
                      </Card>
                    })
                  }

                </Col>
                <Col lg={6}>
                  {
                    data && data.dataPiutang &&
                    <Card>
                      <CardBody>
                        <div>
                          <CardTitle>Piutang</CardTitle>
                        </div>
                        <div className="table mt-3">
                          <table style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <td>Total</td>
                                <td className="text-end pt-2">
                                  <h4>
                                    <CurrencyFormat value={data.dataPiutang?.totalPiutang ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                                      displayType={'text'} />
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                <td>Nominal</td>
                                <td className="text-end  pt-2">
                                  <h4>
                                    <CurrencyFormat value={data.dataPiutang?.totalNominalPiutang ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                                      displayType={'text'} />
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                <td>Total Belum Lunas</td>
                                <td className="text-end pt-2">
                                  <h4>
                                    <CurrencyFormat value={data.dataPiutang?.totalPiutangBelumLunas ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                                      displayType={'text'} />
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                <td>Nominal Belum Dibayarkan</td>
                                <td className="text-end pt-2">
                                  <h4>
                                    <CurrencyFormat value={data.dataPiutang?.totalNominalPiutangBelumLunas ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                                      displayType={'text'} />
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                <td>Overdue</td>
                                <td className="text-end pt-2">
                                  <h4>
                                    <CurrencyFormat value={data.dataPiutang?.dataPiutangOverdue?.length ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                                      displayType={'text'} />
                                  </h4>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {
                          data.dataPiutang && data.dataPiutang.dataPiutangOverdue && data.dataPiutang.dataPiutangOverdue.length > 0 &&
                          <>
                            <CardTitle className="mb-2 mt-5">Piutang Overdue</CardTitle>
                            <div className="table mt-3">
                              <table style={{ width: '100%' }}>
                                <thead>
                                  <tr>
                                    <th>Reminder Date</th>
                                    <th>Remarks</th>
                                    <th>Nominal</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    data.dataPiutang.dataPiutangOverdue.map(piutang => {
                                      return <tr key={'dataPiutang' + piutang.id}>
                                        <td>{piutang.dateLong}</td>
                                        <td>{piutang.remarks}</td>
                                        <td>
                                          <CurrencyFormat value={piutang.nominal ?? 0} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0}
                                            displayType={'text'} />
                                        </td>
                                      </tr>
                                    })
                                  }
                                </tbody>
                              </table>
                            </div>
                            <div className="d-flex justify-content-center">
                              <Button color="primary" className="btn btn-primary" onClick={onLihatPiutangCommand}>
                                Lihat Selengkapnya
                              </Button>
                            </div>
                          </>
                        }
                      </CardBody>
                    </Card>
                  }
                </Col>
              </Row>
            </>
          }
          {
            !selectedCompany && !loading &&
            <Row className="mt-5">
              <Col className="text-center">
                Please select company.
              </Col>
            </Row>
          }
          {
            loading &&
            <div className="d-flex justify-content-center my-5">
              <PulseLoader loading={loading} color={'#FFF'} size={12} className="align-self-center" />
            </div>
          }
          {/* {
            !loadingAccountNumbers && dataAccountNumbers && selectedCompany &&
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <LineApexChart dataColors='["--bs-primary", "--bs-success"]' data={dataAccountNumbers} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          } */}
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Dashboard);
