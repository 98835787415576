import {
    GET_LIST_ACCOUNT_NUMBER,
    GET_LIST_ACCOUNT_NUMBER_SUCCESS,
    GET_LIST_ACCOUNT_NUMBER_FAIL,
    GET_ACCOUNT_NUMBER,
    GET_ACCOUNT_NUMBER_SUCCESS,
    GET_ACCOUNT_NUMBER_FAIL,
    ADD_ACCOUNT_NUMBER,
    ADD_ACCOUNT_NUMBER_SUCCESS,
    ADD_ACCOUNT_NUMBER_FAIL,
    UPDATE_ACCOUNT_NUMBER,
    UPDATE_ACCOUNT_NUMBER_FAIL,
    DELETE_ACCOUNT_NUMBER,
    DELETE_ACCOUNT_NUMBER_FAIL,
    UPDATE_ACCOUNT_NUMBER_SUCCESS,
    CLEAR_LIST_ACCOUNT_NUMBER,
  } from "./actionTypes";
  
  export const getListAccountNumber = filter => ({
    type: GET_LIST_ACCOUNT_NUMBER,
    payload: filter
  });
  export const getListAccountNumberSuccess = account_numbers => ({
    type: GET_LIST_ACCOUNT_NUMBER_SUCCESS,
    payload: account_numbers,
  });
  export const getListAccountNumberFail = error => ({
    type: GET_LIST_ACCOUNT_NUMBER_FAIL,
    payload: error,
  });

  export const getAccountNumberById = param => ({
    type: GET_ACCOUNT_NUMBER,
    payload: param
  });
  export const getAccountNumberByIdSuccess = account_number => ({
    type: GET_ACCOUNT_NUMBER_SUCCESS,
    payload: account_number,
  });
  export const getAccountNumberByIdFail = error => ({
    type: GET_ACCOUNT_NUMBER_FAIL,
    payload: error,
  });
  
  export const addAccountNumber = (account_number, history) => ({
    type: ADD_ACCOUNT_NUMBER,
    payload: { account_number, history },
  });
  export const addAccountNumberSuccess = (account_number) => ({
    type: ADD_ACCOUNT_NUMBER_SUCCESS,
    payload: account_number,
  });
  export const addAccountNumberFail = error => ({
    type: ADD_ACCOUNT_NUMBER_FAIL,
    payload: error,
  });
  
  export const updateAccountNumber = (account_number, history) => ({
    type: UPDATE_ACCOUNT_NUMBER,
    payload: { account_number, history },
  });
  export const updateAccountNumberSuccess = () => ({
    type: UPDATE_ACCOUNT_NUMBER_SUCCESS
  });
  export const updateAccountNumberFail = error => ({
    type: UPDATE_ACCOUNT_NUMBER_FAIL,
    payload: error,
  });
  
  export const deleteAccountNumber = (account_number, filter) => ({
    type: DELETE_ACCOUNT_NUMBER,
    payload: { account_number, filter },
  });
  export const deleteAccountNumberFail = error => ({
    type: DELETE_ACCOUNT_NUMBER_FAIL,
    payload: error,
  });

  export const clearListAccountNumber = () => ({
    type: CLEAR_LIST_ACCOUNT_NUMBER,
  });