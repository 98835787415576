import {
  GET_LIST_WORKSHEET,
  GET_LIST_WORKSHEET_SUCCESS,
  GET_LIST_WORKSHEET_FAIL,
  EXPORT_EXCEL_WORKSHEET,
  EXPORT_EXCEL_WORKSHEET_SUCCESS,
  EXPORT_EXCEL_WORKSHEET_FAIL,
  CLEAR_LIST_WORKSHEET,
  BALANCING_WORKSHEET,
  BALANCING_WORKSHEET_SUCCESS,
  BALANCING_WORKSHEET_FAIL
} from "./actionTypes";

export const getListWorksheet = filter => ({
  type: GET_LIST_WORKSHEET,
  payload: filter
});
export const getListWorksheetSuccess = worksheets => ({
  type: GET_LIST_WORKSHEET_SUCCESS,
  payload: worksheets,
});
export const getListWorksheetFail = error => ({
  type: GET_LIST_WORKSHEET_FAIL,
  payload: error,
});

export const exportExcelWorksheet = filter => ({
  type: EXPORT_EXCEL_WORKSHEET,
  payload: filter
});
export const exportExcelWorksheetSuccess = () => ({
  type: EXPORT_EXCEL_WORKSHEET_SUCCESS,
});
export const exportExcelWorksheetFail = error => ({
  type: EXPORT_EXCEL_WORKSHEET_FAIL,
  payload: error,
});

export const balancingWorksheet = filter => ({
  type: BALANCING_WORKSHEET,
  payload: filter
});
export const balancingWorksheetSuccess = () => ({
  type: BALANCING_WORKSHEET_SUCCESS,
});
export const balancingWorksheetFail = error => ({
  type: BALANCING_WORKSHEET_FAIL,
  payload: error,
});

export const clearListWorksheet = () => ({
  type: CLEAR_LIST_WORKSHEET,
});